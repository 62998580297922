import React, { useState, useEffect } from "react";
import NumberFormat from 'react-number-format';
import {
  TextField,
} from '@mui/material';
import { authenticationService } from "auth/authenticationService";

export const countriesService = {
  countries: JSON.parse(localStorage.getItem('currentUser')),
  getCountries,
  validatePhone,
}
function getCountries() {
  // return new Promise ( (resolve, reject) =>{
  let stored_countries = JSON.parse(localStorage.getItem('storedCountries'));
  let fetch_countries = true;
  var currDate = new Date();
  let countries = []

  if (stored_countries) {
    if ('expired_date' in stored_countries && new Date(stored_countries['expired_date']) < currDate) {
      fetch_countries = true;
    }
    else {
      countries = stored_countries.countries
      fetch_countries = false;
    }

  }

  if (fetch_countries) {
    authenticationService.fetchApi({
      url: '/country/',
      method: 'GET',
    }).then(result => {
      var minutes = 10;
      var tomorrDate = new Date(currDate.getTime() + minutes * 60000)
      localStorage.setItem('storedCountries', JSON.stringify({ expired_date: tomorrDate, countries: result.countries }));
      countries = result.countries;
      return (countries);
    });
  }
  // console.log('countries', countries)
  return (countries);

  // })
}
function validatePhone(value_string) {
  let ok = false;
  let countries = countriesService.getCountries();
  let number_length = 0;

  if (!isNaN(parseFloat(value_string)) && isFinite(value_string)) {
    value_string = value_string.toString()
  }

  if (countries && countries.length > 0) {
    let countrycodes = countries.map(({ code }) => code);
    let code = null;

    if (value_string && value_string !== '') {
      if (countrycodes.includes(parseInt(value_string.substring(0, 1)))) {
        code = value_string.substring(0, 1);
      }
      else if (countrycodes.includes(parseInt(value_string.substring(0, 2)))) {
        code = value_string.substring(0, 2);
      }
      else if (countrycodes.includes(parseInt(value_string.substring(0, 3)))) {
        code = parseInt(value_string.substring(0, 3));
      }

      if (code) {
        for (var i in countries) {
          if (countries[i].code.toString() === code.toString()) {
            number_length = countries[i].number_length;
            // console.log("value length",value_string.length, "number_length",number_length)
            if (value_string.length !== number_length) {
              ok = false;
              // console.log("entra false");
            }
            else {
              ok = true;
              // console.log("entra true");
            }
          }
        }

      }
    }
  }
  return (ok);
}

// deprecated version, must be replaced
export function NumberFormatPhone_old(props) {

  let { inputRef, onChange, ...other } = props;
  const value_string = props.value ? props.value.toString() : '';
  const [countries, setCountries] = useState([]);
  let [format, setFormat] = useState("+(#) ### #### ####")


  useEffect(() => {
    setCountries(countriesService.getCountries());
  }, []);

  useEffect(() => {
    if (countries && countries.length > 0) {
      let countrycodes = countries.map(({ code }) => code);
      let code = null;
      let ok = false;
      let temp_format = '+(#) ### #### ####';


      if (value_string && value_string !== '') {
        if (countrycodes.includes(parseInt(value_string.substring(0, 1)))) {
          code = value_string.substring(0, 1);
        }
        else if (countrycodes.includes(parseInt(value_string.substring(0, 2)))) {
          code = value_string.substring(0, 2);
        }
        else if (countrycodes.includes(parseInt(value_string.substring(0, 3)))) {
          code = parseInt(value_string.substring(0, 3));
        }

        if (code) {
          for (var i in countries) {
            if (countries[i].code.toString() === code.toString()) {
              temp_format = countries[i].number_format;
              ok = true;
            }
          }

        }
        if (!ok) {
          temp_format = '+(#) ### #### ####'
        }
      }
      setFormat(temp_format);
    }
  }, [countries, value_string, props]);



  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        // console.log("source", sourceInfo)
        if (typeof onChange === 'function') {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }
      }}
      isNumericString
      format={format}
    />
  );
}

const NumberFormatPhone = React.forwardRef((props, ref) => {
  const { inputRef, onChange, ...other } = props
  const value_string = props.value ? props.value.toString() : '';
  const [countries, setCountries] = useState([]);
  let [format, setFormat] = useState("+(#) ### #### ####")


  useEffect(() => {
    setCountries(countriesService.getCountries());
  }, []);

  useEffect(() => {
    if (countries && countries.length > 0) {
      let countrycodes = countries.map(({ code }) => code);
      let code = null;
      let ok = false;
      let temp_format = '+(#) ### #### ####';


      if (value_string && value_string !== '') {
        if (countrycodes.includes(parseInt(value_string.substring(0, 1)))) {
          code = value_string.substring(0, 1);
        }
        else if (countrycodes.includes(parseInt(value_string.substring(0, 2)))) {
          code = value_string.substring(0, 2);
        }
        else if (countrycodes.includes(parseInt(value_string.substring(0, 3)))) {
          code = parseInt(value_string.substring(0, 3));
        }

        if (code) {
          for (var i in countries) {
            if (countries[i].code.toString() === code.toString()) {
              temp_format = countries[i].number_format;
              ok = true;
            }
          }

        }
        if (!ok) {
          temp_format = '+(#) ### #### ####'
        }
        if (!ok && value_string.length < 7) { // As long as there is no collision with country code, this works (43 is Austria)
          temp_format = '############'
        }
      }
      setFormat(temp_format);
    }
  }, [countries, value_string, props]);



  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        // console.log("source", sourceInfo)
        if (typeof onChange === 'function') {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }
      }}
      isNumericString
      format={format}
    />
  );
});



export function PhoneNumberField(props) {
  const { ...other } = props;


  return (
    <TextField  {...other}
      InputProps={{
        inputComponent: NumberFormatPhone
      }}
      InputLabelProps={{ shrink: true }}
      inputProps={{ maxLength: 20 }} />
  )
}
export function PhoneNumber(props) {
  return (
    <NumberFormatPhone value={props.value ? props.value : ''} displayType={"text"} />
  )
}