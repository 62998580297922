import React from 'react';
import Layout from '../../layout/index.js'
import { authenticationService } from 'auth/authenticationService'
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';



import {
  Paper, Box,
  Typography, TextField, Tooltip,
  Card, CardContent, Grid, IconButton,
  Autocomplete, Button, Alert
} from '@mui/material';
import { PhoneNumber } from 'adds/PhoneNumber.js'

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import HelpIcon from '@mui/icons-material/Help';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

// import { styled } from '@mui/material/styles';

const countries = [
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brasil', phone: '55' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'ES', label: 'España', phone: '34' },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'VE', label: 'Venezuela', phone: '58' },
];



// For query delay on autocomplete
const wait_interval = 500 // in ms
let timer_id


class ElasticNumbers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        numbers_display: false,
        show_allocate: false,
        show_too_many_allocated_error: false,
        show_allocated_error: false,
      },
      allocated_elastic_numbers: [],
      possible_elastic_numbers: [],
      country: null,
      search: '',
      selected_number: null,
      allocated_number: null,
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.loadNumbers = this.getNumbers.bind(this);
    this.getPossibleElasticNumbers = this.getPossibleElasticNumbers.bind(this);
    this.setElasticNumber = this.setElasticNumber.bind(this);
    this.allocateNumber = this.allocateNumber.bind(this);
    this.setDefaultNumber = this.setDefaultNumber.bind(this)
    this.releaseNumber = this.releaseNumber.bind(this)
  }

  getNumbers() {
    authenticationService.fetchApi({
      url: '/number/',
      method: 'GET',
      api: true,
    })
      .then((result) => {
        this.setState({ allocated_elastic_numbers: result.data });
      });
  }


  getPossibleElasticNumbers(event, search) {
    // this.state.search = search; // not ok

    if (search !== '') {
      this.setState({ search: search });
      clearTimeout(timer_id)
      timer_id = setTimeout(() => {
        let country_code = null;
        if (this.state.country !== null) {
          country_code = this.state.country.code;
        };

        authenticationService.fetchApi({
          url: '/number/check_available/' + country_code + '/' + search,
          method: 'GET',
          api: true,
          no_error_popup: true,
        })
          .then((result) => {
            this.setState({ possible_elastic_numbers: result.data });

            // this.setState({ possible_elastic_numbers: result.data }, () => {
            //     this.setState(prevState => ({ html: { ...prevState.html, show_allocate: false } }), () => { console.log(this.state.html) })
            // });
            // this.state.html.show_allocate = false; // not ok
          });
      }, wait_interval)
    }
  }

  setElasticNumber(event, new_value) {
    // this.state.selected_number = new_value;
    // this.state.html.show_allocate = true;
    this.setState((prevState) => (
      {
        html: { ...prevState.html, show_allocate: true },
        selected_number: new_value,
      }
    ), () => {
      this.setState((prevState) => ({ html: { ...prevState.html, show_allocated_error: false } }));
    });
  }

  allocateNumber() {
    authenticationService.fetchApi({
      url: '/number/allocate/',
      method: 'POST',
      api: true,
      no_error_popup: true,
      body: JSON.stringify({ elastic_number: this.state.selected_number.number }),
    })
      .then((result) => {
        // this.state.html.show_allocate = false;
        this.getNumbers();
        this.setState(prevState => ({
          search: '',
          country: null,
          selected_number: null,
          possible_elastic_numbers: []
        }));
      }).catch((err) => {
        if (err.status === 403) { // Too many numbers allocated
          this.setState((prevState) => ({ html: { ...prevState.html, show_allocated_error: true } }), () => {
          })
        }
        if (err.status === 412) { // Not enough balance to allocate
          this.setState((prevState) => ({ html: { ...prevState.html, show_balance_error: true } }), () => {
          })
        }
      });
  }

  releaseNumber(elastic_number) {
    authenticationService.fetchApi({
      url: '/number/release/',
      method: 'POST',
      api: true,
      no_error_popup: true,
      body: JSON.stringify({ elastic_number: elastic_number.number }),
    }).then((result) => {
      this.getNumbers();
    });
  }

  setDefaultNumber(elastic_number) {
    authenticationService.fetchApi({
      url: '/number/set_default/',
      method: 'POST',
      api: true,
      no_error_popup: true,
      body: JSON.stringify({ elastic_number: elastic_number.number }),
    }).then((result) => {
      this.getNumbers();
    });
  }

  handleLoad() {
    this.getNumbers();
  }

  componentDidMount() {
    this.handleLoad();
  }


  render() {
    return (
      <Layout title='Números de salida'>
        <Paper>
          <Box p={2}>
            <Grid container>
              <Grid item xs={2} sm={1}>
                <IconButton component={Link} 
                          aria-label="help" 
                          to={{ pathname: 'https://www.axonio.dev/numeros-de-salida/'}}
                          target='_blank'>
                  <HelpIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10} sm={11}>
                <Typography variant="caption" sx={{color:'#AAAAAA'}}>
                  Administra tus números de teléfono propios. 
                  Con ello podrás seleccionar el número con que mandas (que será exclusivo para tu cuenta), 
                  además de posibilitar la recepción de mensajes.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          
          <Grid container
                direction="row"
                justifyContent="center"
                alignItems="flex-start">
            <Grid item md={6} sm={12}>
              <Box p={1}  sm={{ width: 200 }} md={{ width: '200px' }} align='center'>
                
                <Box>
                  <Typography variant="h6">
                    Números de salida asignados
                  </Typography>
                </Box>
                {this.state.allocated_elastic_numbers && this.state.allocated_elastic_numbers.length > 0 &&
                  <div>
                    {this.state.allocated_elastic_numbers.map((allocated_elastic_number, index) => {
                      return (
                        <Box key={index} p={1}>
                          <Card variant="outlined">
                            <CardContent>

                              <Box>
                                <Typography gutterBottom variant="caption" color="textSecondary">
                                  Número:
                                </Typography>
                                <Box>
                                  <Typography variant="subtitle1">
                                    {allocated_elastic_number.country.flag_emoji} <PhoneNumber value={allocated_elastic_number.number} /> {'\u00a0'}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography gutterBottom variant="caption" color="textSecondary">
                                  Tipo de número:
                                </Typography>
                                <Box>
                                  <Typography variant="body2" gutterBottom>
                                    <span>{allocated_elastic_number.type_name}</span>
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography gutterBottom variant="caption" color="textSecondary">
                                  Asignado el:
                                </Typography>
                                <Box>
                                  <Typography variant="body2" gutterBottom>
                                    <span>{ }
                                      {(new Date(allocated_elastic_number.elastic_number_in_company.allocated_on)).toLocaleString('es-CL')}
                                    </span>
                                  </Typography>
                                </Box>
                              </Box>
                              {allocated_elastic_number.elastic_number_in_company.released_on &&
                                <Box>
                                  <Typography gutterBottom variant="caption" color="textSecondary">
                                    Liberado el:
                                  </Typography>
                                  <Box>
                                    <Typography variant="body2" gutterBottom>
                                      <span>{ }
                                        {(new Date(allocated_elastic_number.elastic_number_in_company.released_on)).toLocaleString('es-CL')}
                                      </span>
                                    </Typography>
                                  </Box>
                                </Box>
                              }
                              <Box>
                                <Typography gutterBottom variant="caption" color="textSecondary">
                                  Precio diario:
                                </Typography>
                                <Box>
                                  <Typography variant="body2" gutterBottom>
                                    <NumberFormat displayType={'text'}
                                      value={allocated_elastic_number.daily_price}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      prefix={'$'} />{'\u00a0'}
                                    (<NumberFormat displayType={'text'}
                                      value={allocated_elastic_number.daily_price * 30.5}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      prefix={'$'} /> mensual)
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Box>
                                  <Button onClick={() => {
                                            this.setDefaultNumber(allocated_elastic_number)
                                          }}
                                          aria-label="por_defecto"
                                          // disabled={allocated_elastic_number.elastic_number_in_company.is_default}
                                          // sx={{'&:disabled':{color:'#9FDD9F'}}}
                                          color={allocated_elastic_number.elastic_number_in_company.is_default?'secondary':'primary'}
                                          startIcon={ allocated_elastic_number.elastic_number_in_company.is_default?
                                                      <RemoveCircleIcon fontSize="small" />:<FavoriteBorderIcon fontSize="small" />
                                                    }
                                          >
                                    {allocated_elastic_number.elastic_number_in_company.is_default?"Quitar por defecto":"Fijar por defecto"}
                                  </Button>
                                  <Box sx={{display:'inline'}}>
                                    <Tooltip title={  allocated_elastic_number.elastic_number_in_company.is_default?
                                                      "Número por defecto asignado para este país":
                                                      "Asignar como número por defecto para este país"}>
                                      <HelpIcon sx={{color:'#AAAAAA', fontSize:'0.9em'}}/>
                                    </Tooltip>
                                  </Box>
                                </Box>
                                <Box>
                                  <Button onClick={() => {
                                            this.releaseNumber(allocated_elastic_number)
                                          }}
                                          aria-label="por_defecto"
                                          startIcon={ allocated_elastic_number.elastic_number_in_company.released_on?
                                                      <AutorenewIcon fontSize="small" />:<DeleteIcon fontSize="small" />
                                                    }
                                          >
                                    {allocated_elastic_number.elastic_number_in_company.released_on?'Cancelar liberar':'Liberar'}
                                  </Button>
                                  <Box sx={{display:'inline'}}>
                                    <Tooltip title={allocated_elastic_number.elastic_number_in_company.released_on?
                                                    "Cancelar liberar número (el número se restablecerá)":
                                                    "Liberar número: (El número seguirá vigente hasta final del mes actual y luego será eliminado)"}>
                                      <HelpIcon sx={{color:'#AAAAAA',fontSize:'0.9em'}}/>
                                    </Tooltip>
                                  </Box>
                                </Box>

                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                      )
                    })
                    }
                  </div>
                }
                {this.state.allocated_elastic_numbers && this.state.allocated_elastic_numbers.length === 0 &&
                  <Box pt={1}>
                    <Typography variant="body2" gutterBottom>
                      <span>Aún no tienes números asignados</span>
                    </Typography>
                  </Box>
                }
              </Box>
            </Grid>
            <Grid item  md={6} sm={12}>
              <Box p={1}>
                <Typography variant="h6">
                  Asignar nuevo número de salida
                </Typography>
                <Box pt={1}>

                      <Card variant="outlined">
                        <CardContent>

                          <Autocomplete
                            id="country"
                            name="country"
                            value={this.state.country}
                            onChange={(event, new_value) => this.setState({
                              country: new_value,
                            }, () => {
                              if (this.state.country != null) {
                                this.getPossibleElasticNumbers(event, '');
                              }
                            },
                            )}
                            
                            options={countries}
                            autoHighlight
                            getOptionLabel={(option) => option.label ? option.label : ''}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                                {option.label} ({option.code}) +{option.phone}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Selecciona un país"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: '', // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          {this.state.country &&
                            <Box pt={2}>
                              <Autocomplete
                                id="selected_number"
                                name="selected_number"
                                noOptionsText={'No hay números de salida disponibles para esta búsqueda'}
                                filterOptions={(x) => x}
                                inputValue={this.state.search}
                                onInputChange={
                                  (event, new_value) => this.setState({
                                    search: new_value,
                                  }, () => {
                                    this.getPossibleElasticNumbers(event, new_value)
                                  },
                                  )
                                }
                                value={this.state.selected_number}
                                onChange={(event, new_value) => this.setElasticNumber(event, new_value)}
                                
                                options={this.state.possible_elastic_numbers}
                                autoHighlight
                                getOptionLabel={(option) => option.number ? option.number : ''}
                                isOptionEqualToValue={(option, value) => option = value}
                                renderOption={(props, option) => (
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.number} - {option.type_name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Búsca un número elástico"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: '', // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                            </Box>
                          }

                          {this.state.html.show_allocate && this.state.selected_number &&
                            <Box pt={2}>
                              <Box>
                                <Typography gutterBottom variant="caption" color="textSecondary">
                                  Número:
                                </Typography>
                                <Box>
                                  <Typography variant="subtitle1">
                                    {this.state.selected_number.country.flag_emoji} <PhoneNumber value={this.state.selected_number.number} />
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography gutterBottom variant="caption" color="textSecondary">
                                  Tipo de número:
                                </Typography>
                                <Box>
                                  <Typography variant="body2" gutterBottom>
                                    <span>{this.state.selected_number.type_name}</span>
                                  </Typography>
                                </Box>
                              </Box>


                              <Box>
                                <Typography gutterBottom variant="caption" color="textSecondary">
                                  Precio diario:
                                </Typography>
                                <Box>
                                  <Typography variant="body2" gutterBottom>
                                    <NumberFormat displayType={'text'}
                                      value={this.state.selected_number.daily_price}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      prefix={'$'} />{'\u00a0'}
                                    (<NumberFormat displayType={'text'}
                                      value={this.state.selected_number.daily_price * 30.5}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      prefix={'$'} /> mensual)
                                  </Typography>
                                </Box>
                              </Box>
                              <Box pt={2}>
                                <Button startIcon={<ShoppingCartIcon />} onClick={this.allocateNumber} color='primary' variant='contained' >Asignar número</Button>
                                {this.state.html.show_allocated_error &&
                                  <Alert sererity="error">
                                    <Box mt={1}>Has superado los 10 números de salida asignados para este país.</Box>
                                    <Box mt={0}>Si deseas utilizar más por favor contáctenos:</Box>
                                    <Box>
                                      <Tooltip title="Enviar correo" placement="top">
                                        <IconButton href="mailto:contacto@axonio.dev" target="_top" aria-label="enviar">
                                          <EmailIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Enviar Whatsapp" placement="top">
                                        <IconButton href="https://wa.me/56982295321" target="_top" aria-label="whatsappear">
                                          <WhatsAppIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Llamar a soporte" placement="top">
                                        <IconButton href="tel:+56982295321" target="_top" aria-label="llamar">
                                          <PhoneIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </Alert>
                                }
                                {this.state.html.show_balance_error &&
                                  <Alert sererity="error">
                                    <Box mt={1}>No tienes el saldo suficiente para asignar un número de salida</Box>
                                    <Box mt={2}>
                                      <Button startIcon={<ShoppingCartIcon />} component={Link} to='/topup' color='primary' variant='contained' >Cargar crédito</Button>
                                    </Box>
                                  </Alert>
                                }
                              </Box>
                            </Box>
                          }
                        </CardContent>
                      </Card>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Layout>
    );
  }
}
export default ElasticNumbers;


