import React from 'react';
import Layout from '../../layout/index.js';
import { withRouter } from 'react-router-dom';
import { authenticationService } from 'auth/authenticationService'
import NumberFormat from 'react-number-format';
import ReactToPrint from 'react-to-print';
import {
  Paper, Grid, Box, Typography, Button, Divider, Tooltip, Link,
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PrintIcon from '@mui/icons-material/Print';
import MouseIcon from '@mui/icons-material/Mouse';


function encodeQueryData(data) {
  const ret = [];
  for (const d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  }
  return ret.join('&');
}

class Invoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        tab_index: 0,
      },
      detail: null,
      payment: null,
    };

    this.loadTopUp = this.loadTopUp.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  handleLoad() {
    this.setState((prevState) => ({
      html: {},
    }));

    this.loadTopUp()
  }

  componentDidMount() {
    this.handleLoad();
  }

  loadTopUp() {
    const params = {};
    if (this.props.match.params.top_up_uuid) {
      params['top_up_uuid'] = this.props.match.params.top_up_uuid;
    }
    const query = encodeQueryData(params)
    authenticationService.fetchApi({
      url: '/topup/?' + query,
      method: 'GET',
    })
      .then((result) => {
        this.setState({ topup: result.data[0] });
        let payment = null;
        result.data[0].payments.forEach(function (arrayItem) {
          if (arrayItem.state === 'SUCCESS') {
            payment = arrayItem;
          }
        });
        this.setState({ payment: payment });
      }).then(() => {
        authenticationService.fetchApi({
          url: '/topup/payment/' + this.state.payment.payment_uuid,
          method: 'GET',
        }).then((result) => {
          this.setState({ detail: result });
        });
      });
  }

  render() {
    return (
      <div>
        {
          this.state.topup && this.state.payment &&
          <Layout title={'Recibo para carga ' + this.state.topup.top_up_uuid}>
            <Paper>
              <PrintInvoice topup={this.state.topup} payment={this.state.payment} detail={this.state.detail} ref={(el) => (this.componentRef = el)} />
            </Paper>

            <Grid container spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center">
              <Box pt={2}>
                <Typography gutterBottom variant="caption" color="textSecondary">
                  Este recibo es un comprobante interno del pago realizado, no constituye documento tributario
                </Typography>
              </Box>
              <Box pt={2}>
                <ReactToPrint
                  trigger={() => <Button color='primary' variant="contained" startIcon={<PrintIcon />}>Imprimir</Button>}
                  content={() => this.componentRef}
                />
              </Box>
            </Grid>
          </Layout>
        }
      </div >
    );
  }
}

export default withRouter(Invoice);


class PrintInvoice extends React.Component {
  render() {
    return (
      <Box p={8}>
        <Grid container spacing={1} p={1} >
          <Grid item xs={8}>
            {/* <Box pb={4}>
              <Typography variant="body2" color="textSecondary" component="p">

              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">

                Esto es un recibo por el pago de carga de saldo por el monto de { }
                <NumberFormat displayType={'text'}
                  value={this.props.topup.amount}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={''}
                  suffix={''}
                /> (CLP) a nombre de AxonIO.
              </Typography>
            </Box> */}
          </Grid>
          <Grid item xs={4}>
          </Grid>

          <Grid item xs={6} sm={6}>
            <Box pb={4}>
              <Typography gutterBottom variant="caption" color="textSecondary" component="p">
                Carga Nro.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {this.props.topup.top_up_uuid}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box pb={4}>
              <Grid container justifyContent="flex-end">
                <Typography gutterBottom variant="caption" color="textSecondary" component="p">
                  Fecha de pago.
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end">
              
                <Typography variant="body2" color="textSecondary" component="p" align="right">
                  {(new Date(this.props.payment.created_at)).toLocaleString('es-CL')}<br></br>
                  <span>
                    {this.props && this.props.payment.type === 'ONECLICK' && this.props.detail &&
                      <Tooltip title="Pagado con OneClick">
                        <MouseIcon fontSize="small" />
                      </Tooltip>
                    }
                    {this.props && this.props.payment.type === 'WEBPAY' && this.props.detail &&
                      <Tooltip title="Pagado con Webpay">
                        <CreditCardIcon fontSize="small" />
                      </Tooltip>
                    }
                    {this.props && this.props.payment.type === 'MANUAL' &&
                      <Tooltip title="Pagado con transferencia bancaria" placement="top-start">
                        <AccountBalanceIcon fontSize="small" />
                      </Tooltip>
                    }
                  </span>
                </Typography>

              </Grid>
              <Grid container justifyContent="flex-end">
                <Typography variant="body2" color="textSecondary" component="p" align="right" mt={0} pt={0}>
                  <span><br></br>
                    {this.props && this.props.payment.type === 'ONECLICK' && this.props.detail &&
                      <span>Tarjeta terminada en <b>**** {this.props.detail.card_number}</b></span>
                    }

                    {this.props && this.props.payment.type === 'WEBPAY' && this.props.detail &&
                      <Tooltip title="Pagado con Webpay">
                        <span>
                          <CreditCardIcon fontSize="small" />
                          <p>Tarjeta terminada en <b>**** {this.props.detail.card_number}</b></p>
                        </span>
                      </Tooltip>
                    }
                  </span>
                </Typography>

              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} p={1} >
          <Grid item xs={6} sm={6}>
            <Box pb={4} pt={4}>
              <Typography gutterBottom variant="caption" color="textSecondary" component="p">
                Cliente
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {this.props.topup.company.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {this.props.topup.company.address}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box pb={4} pt={4}>
              <Grid container justifyContent="flex-end">
                <Typography gutterBottom variant="caption" color="textSecondary" component="p">
                  Pagado a.
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Typography variant="body2" color="textSecondary" display="block" align="right">
                  AxonRT SpA.<br></br>
                  RUT 77.131.642-5<br></br>
                  Dr. Manuel Barros Borgoño 71, Of 1105.<br></br>
                  Providencia<br></br>
                  Chile<br></br>
                  <Link href="mailto:contacto@axonio.dev" target="_top" aria-label="enviar">
                    contacto@axonio.dev
                  </Link>
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1} p={1} >
          <Grid item xs={6} sm={6}>
            <Box pt={4}>
              <Typography gutterBottom variant="caption" color="textSecondary" component="p">
                Descripción
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box pt={4}>
              <Grid container justifyContent="flex-end">
                <Typography gutterBottom variant="caption" color="textSecondary" component="p">
                  Monto
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} p={1} >
          <Grid item xs={6} sm={6}>
            <Box pb={1} pt={1}>
              <Typography variant="body2" color="textSecondary" component="p">
                Carga de créditos
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box pb={1} pt={1}>
              <Grid container justifyContent="flex-end">
              </Grid>
              <Grid container justifyContent="flex-end">
                <Typography variant="body2" color="textSecondary" display="block" align="right">
                  <NumberFormat displayType={'text'}
                    value={this.props.topup.amount}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={''}
                    suffix={' CLP'}
                  />
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} p={1} >
          <Grid item xs={7} sm={6}>
          </Grid>
          <Grid item xs={2} sm={3}>
            <Box pb={1} pt={1}>
              <Grid container justifyContent="flex-end">
                <Typography variant="body2" color="textSecondary" display="block" align="right">
                  Impuestos
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Box pb={1} pt={1}>
              <Grid container justifyContent="flex-end">
                <Typography variant="body2" color="textSecondary" display="block" align="right">
                  19%
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} p={1} >
          <Grid item xs={7} sm={6}>
          </Grid>
          <Grid item xs={2} sm={3}>
            <Box pb={1} pt={1}>
              <Grid container justifyContent="flex-end">
                <Typography variant="body2" color="textSecondary" display="block" align="right">
                  Total
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={3} sm={3}>
            <Box pb={1} pt={1}>
              <Grid container justifyContent="flex-end">
                <Typography variant="body2" color="textSecondary" display="block" align="right">
                  <NumberFormat displayType={'text'}
                    value={this.props.payment.amount_with_tax}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={''}
                    suffix={' CLP'}
                  />
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Divider />
      </Box>
    );
  }
}
