import React from 'react';
import { authenticationService } from 'auth/authenticationService'
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import {
  Box, Tooltip, Chip, Alert,
  Table, TableRow, TableHead, TableBody, TableCell,
  IconButton,
} from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import jsonData from './topup_status.json';
import { red } from '@mui/material/colors';
const topup_status = jsonData;


class TopUpUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topups: [],
      expanded: false,
      html: {
        page: 1,
        dialog: false,
      },
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleExpanded = this.handleExpanded.bind(this);
  }

  handleLoad() {
    this.setState((prevState) => ({
      html: {
        ...prevState.html,
        object_display: 'display-none',
        list_display: 'display-inline',
        return: 'display-none',
      },
    }));

    authenticationService.fetchApi({
      url: '/topup/',
      method: 'GET',
    })
      .then((result) => {
        this.setState({ topups: result.data });
      })
  }

  handleExpanded(event, isExpanded, panel) {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  componentDidMount() {
    this.handleLoad();
  }

  render() {
    return (
      <Box p={1} m={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Fecha
              </TableCell>
              <TableCell>
                Monto de carga
              </TableCell>
              <TableCell>
                Estado
              </TableCell>
              <TableCell>

              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.topups && this.state.topups.map((topup, index) => {
              return (
                <TableRow key={topup.top_up_uuid}>
                  <TableCell>
                    {(new Date(topup.updated_at)).toLocaleString('es-CL')}

                  </TableCell>
                  <TableCell>
                    <NumberFormat displayType={'text'}
                      value={topup.amount}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={''}
                      suffix={' CLP'}
                    />
                  </TableCell>
                  <TableCell>
                    {topup.state &&
                      <Tooltip title={topup_status[0].statuses[topup.state].description} placement="top-start">
                        <Box>
                          {topup_status[0].statuses[topup.state].type === 0 &&
                            <Chip label={(topup_status[0].statuses[topup.state].spanish)} style={{ backgroundColor: 'orange', color: 'white' }} />
                          }
                          {topup_status[0].statuses[topup.state].type === 1 &&
                            <Chip label={(topup_status[0].statuses[topup.state].spanish)} style={{ backgroundColor: red[300], color: 'white' }} />
                          }
                          {topup_status[0].statuses[topup.state].type === 2 &&
                            <Chip label={(topup_status[0].statuses[topup.state].spanish)} style={{ backgroundColor: 'lightgreen', color: 'white' }} />
                          }
                        </Box>
                      </Tooltip>
                    }
                  </TableCell>
                  <TableCell>
                    {topup.state && topup.payments &&
                      <Box>
                        {topup_status[0].statuses[topup.state].type === 2 &&
                          <Box>
                            {topup.payments.map((payment, index) => {
                              return (
                                <div key={payment.payment_uuid}>
                                  {
                                    payment.state === "SUCCESS" &&
                                    <div>
                                      <Tooltip title="Detalle" placement="top-start">
                                        <IconButton
                                          component={Link}
                                          to={`/invoice/${topup.top_up_uuid}`}
                                        >
                                          <ZoomInIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      {
                                        payment.invoice &&
                                        <Tooltip title="Factura" placement="top-start">
                                          <IconButton
                                            href={payment.invoice}
                                            target="_blank"
                                            aria-label="factura"
                                          >
                                            <ReceiptIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>
                                      }
                                    </div>

                                  }
                                </div>
                              );
                            })}

                          </Box>
                        }
                      </Box>
                    }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {
          this.state.topups.length === 0 &&
          <Alert severity="warning">
            No se encontraron cargas de crédito
          </Alert>
        }
      </Box >
    );
  }
}

export default TopUpUser;
