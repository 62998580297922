import React from 'react';
import {
  Route,
  Redirect
} from "react-router-dom";

import { authenticationService } from 'auth/authenticationService'


// Auth Components
export default function PrivateRoute({ children, ...rest }) {
  authenticationService.getCurrentUser();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        ( authenticationService.isAuthenticated && 
          'currentUser' in authenticationService &&
          'groups' in authenticationService.currentUser &&
          ['agent', 'company', 'admin'].filter(x => authenticationService.currentUser.groups.includes(x)).length > 0)
         ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
