import React from 'react';
import {  
  Typography, Paper, Box,
} from '@mui/material';


class AgentDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <Paper>
        <Box p={2} m={1}>
          <Typography variant='h5'>AgentDashboard</Typography>
          <Box>Contenido del gráfico</Box>
        </Box>
      </Paper>
    );
  }
}

export default AgentDashboard;