import React from "react";
import Layout from '../../layout/index.js'
import Ivrs from "./ivr/Ivrs.js";
import Teams from "./team/Teams.js";
import Agents from "./agent/Agents.js";

import {
  AppBar, Paper,
  Tabs, Tab,
} from '@mui/material';


class VoiceConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        tab_index: 0,
      },
      data: {},
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  handleLoad() {

  }

  componentDidMount() {
    this.handleLoad();
  }

  handleChangeTab(event, newValue) {
    event.preventDefault();

    this.setState(prevState => ({
      html: {
        ...prevState.html,
        tab_index: newValue
      }
    }));
  }

  render() {
    return (
      <Layout title='Configuración API Voz'>
        <Paper>
          <AppBar position="static">
            <Tabs value={this.state.html.tab_index}
              onChange={this.handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Tabmenu"
            >
              <Tab label="IVR's" id="nav-tab-0" aria-controls="nav-tabpanel-0" />
              <Tab label="Equipos" id="nav-tab-1" aria-controls="nav-tabpanel-1" />
              <Tab label="Agentes" id="nav-tab-2" aria-controls="nav-tabpanel-2" />
            </Tabs>
          </AppBar>
          <div role="tabpanel" hidden={this.state.html.tab_index !== 0} id="simple-tabpanel-0" aria-labelledby="simple-tab-0">
            <Ivrs />
          </div>
          <div role="tabpanel" hidden={this.state.html.tab_index !== 1} id="simple-tabpanel-1" aria-labelledby="simple-tab-1">
            <Teams />
          </div>
          <div role="tabpanel" hidden={this.state.html.tab_index !== 2} id="simple-tabpanel-2" aria-labelledby="simple-tab-2">
            <Agents />
          </div>
        </Paper>
      </Layout>
    );
  }
}

export default VoiceConfig;
