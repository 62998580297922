import React from "react";
import Layout from '../../layout/index.js'
import { authenticationService } from 'auth/authenticationService'
import SmsAdmin from './SmsAdmin.js'
import {
  Paper, Box, Grid, Typography, IconButton
} from '@mui/material';
import { Link } from 'react-router-dom';

import HelpIcon from '@mui/icons-material/Help';

class SmsConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        form: 'display-none',
        view: 'display-none',
        btn_edit: 'btn btn-edit',
        btn_submit: 'display-none',
      },
      data: {
        company_uuid: '',
        name: '',
        description: '',
        tax_id: '',
        address: '',
        business_type: '',
        invoice_contact_mail: '',
        invoice_contact_name: '',
        invoice_contact_phone: '',
      },
    };

    this.handleLoad = this.handleLoad.bind(this);
  }

  handleLoad() {
    return (
      authenticationService.fetchApi({
        url: '/company/' + authenticationService.currentUser.person_object.company_object.company_uuid,
        method: 'GET',
      })
        .then(result => {
          this.setState(prevState => ({
            data: result,
            html: {
              ...prevState.html,
              view: true,
              form: false,
            }
          }));
          return (true);
        })
    );
  }

  componentDidMount() {
    this.handleLoad();
  }

  render() {
    return (
      <Layout title='Configuración API SMS'>
        <Paper>
          <Box p={2}>
            <Grid container>
              <Grid item xs={2} sm={1}>
                <IconButton component={Link} 
                          aria-label="help" 
                          to={{ pathname: 'https://www.axonio.dev/configuracion-sms/'}}
                          target='_blank'>
                  <HelpIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10} sm={11}>
                <Typography variant="caption" sx={{color:'#AAAAAA'}}>
                Esta sección permite configurar los parámetros generales del envío SMS.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {this.state.data.company_uuid &&
            <SmsAdmin company_uuid={this.state.data.company_uuid} />
          }
        </Paper>
      </Layout>
    );
  }
}

export default SmsConfig;
