import React from 'react';
import './style.css'
import './highlight.css'
import './phone_input.css'
import Logo from './logo_white.png';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


import InboxIcon from '@mui/icons-material/Inbox';
import SendIcon from '@mui/icons-material/Send';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SecurityIcon from '@mui/icons-material/Security';
import TextsmsIcon from '@mui/icons-material/Textsms';
import BusinessIcon from '@mui/icons-material/Business';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PhoneIcon from '@mui/icons-material/Phone';
import HistoryIcon from '@mui/icons-material/History';
import Settings from '@mui/icons-material/Settings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Dialpad from '@mui/icons-material/Dialpad';
import BuildIcon from '@mui/icons-material/Build';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CodeIcon from '@mui/icons-material/Code';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PeopleIcon from '@mui/icons-material/People';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { Link, withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';

import PrivateComponent from 'auth/PrivateComponent.js'

import { authenticationService } from 'auth/authenticationService'
// import Webphone from "pages/dashboard/Webphone";

const drawerWidth = 250;

const PREFIX = 'Layout';
const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcon: `${PREFIX}-toolbarIcon`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonHidden: `${PREFIX}-menuButtonHidden`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  title: `${PREFIX}-title`,
  logo: `${PREFIX}-logo`,
  logo_footer: `${PREFIX}-logo_footer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
  appBarSpacer: `${PREFIX}-appBarSpacer`,
  content: `${PREFIX}-content`,
  container: `${PREFIX}-container`,
  nested: `${PREFIX}-nested`,
  py_0: `${PREFIX}-py_0`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
  },
  [`& .${classes.toolbar}`]: {
    paddingRight: 24, // keep right padding when drawer closed
    color: '#565656',
  },
  [`& .${classes.toolbarIcon}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    color: '#565656',
    ...theme.mixins.toolbar,
  },
  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: '#565656',
    backgroundColor: '#FFFFFF',
  },
  [`& .${classes.appBarShift}`]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${classes.menuButton}`]: {
    marginRight: 16,
  },
  [`& .${classes.menuButtonHidden}`]: {
    display: 'none',
  },
  [`& .${classes.listItemIcon}`]: {
    color: '#565656',
  },
  [`& .${classes.title}`]: {
    flexGrow: 1,
  },
  [`& .${classes.logo}`]: {
    height: 40,
  },
  [`& .${classes.drawerPaper}`]: {
    'color': '#FFFFFF',
    'background': 'linear-gradient(to right bottom, #0066FF, #0096D6)',
    'position': 'relative',
    'whiteSpace': 'nowrap',
    'width': drawerWidth,
    'transition': theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& .MuiListItemIcon-root': {
      color: '#FFFFFF',
    },
  },
  [`& .${classes.drawerPaperClose}`]: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  [`& .${classes.appBarSpacer}`]: theme.mixins.toolbar,
  [`& .${classes.content}`]: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  [`& .${classes.container}`]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [`& .${classes.nested}`]: {
    paddingLeft: theme.spacing(4),
  },
  [`& .${classes.py_0}`]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));


class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      window: props.window,
      mobileOpen: false,
      open: true,
      container: props.window !== undefined ? () => window().document.body : undefined,
      menu: {
        sms_api: false,
        voice_api: false,
        verify_api: false,
        purchases: false,
        start: null,
      },
      topbar: {
        menuOpen: false,
        menuRef: React.createRef(),
      },
      username: null,
      company_object: {
        company_uuid: '',
        name: '',
      },
      balance: 0,
    };

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleDropdown = this.handleDropdown.bind(this);
    this.handleTopbarDropdown = this.handleTopbarDropdown.bind(this);

    // if('title' in this.props){
    //   document.title = "Axonio - " + this.props.title
    // }
  }

  handleDrawerOpen() {
    this.setState({ open: true });
    authenticationService.setSettings('drawerOpen', true);
  };

  handleDrawerClose() {
    this.setState({ open: false });
    authenticationService.setSettings('drawerOpen', false);
  };

  handleLogout() {
    authenticationService.logout();
    this.props.history.push('/login');
  }

  handleDropdown(witch) {
    const menu = this.state.menu;
    menu[witch] = !menu[witch];
    menu['start'] = null;
    this.setState({ menu: menu });
  };

  handleTopbarDropdown() {
    this.setState((prevState) => ({
      topbar: {
        ...prevState.topbar,
        menuOpen: !this.state.topbar.menuOpen,
      },
    }));
  }

  updateDimensions = () => {
    const settings = authenticationService.getSettings();

    if (window.innerWidth < 800) {
      this.setState({ open: false });
    } else if (!settings || settings['drawerOpen'] === '') {
      this.setState({ open: true });
    }

    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  // container = window !== undefined ? () => window().document.body : undefined;

  componentDidMount() {
    const groups = authenticationService.currentUser.groups;

    if (['admin'].filter((x) => groups.includes(x)).length > 0) {
      this.setState({ topup: 'display-none' });
    } else {
      this.setState({ topup: 'display-inline-block' });
    }

    window.addEventListener('resize', this.updateDimensions);

    this.unlisten = this.props.history.listen((location, action) => {
      this.updateDimensions()
    });

    this.updateDimensions()

    // Keep drawer state for current session
    const settings = authenticationService.getSettings();

    if (settings && settings['drawerOpen'] !== '') {
      this.setState({ open: settings['drawerOpen'] });
    }

    if (settings && settings['currentBalance'] !== '') {
      this.setState({ balance: settings['currentBalance'] });
    }

    // Menu
    const path = this.props.location.pathname;

    if (path.indexOf('/company') === 0) {
      this.setState((prevState) => ({
        menu: {
          ...prevState.menu,
          company: true,
          start: 'company',
        },
      }));
    }

    if (path.indexOf('/sms') === 0) {
      this.setState((prevState) => ({
        menu: {
          ...prevState.menu,
          sms_api: true,
          start: 'sms_api',
        },
      }));
    }

    if (path.indexOf('/voip') === 0) {
      this.setState((prevState) => ({
        menu: {
          ...prevState.menu,
          voice_api: true,
          start: 'voice_api',
        },
      }));
    }

    if (path.indexOf('/verify') === 0) {
      this.setState((prevState) => ({
        menu: {
          ...prevState.menu,
          verify_api: true,
          start: 'verify',
        },
      }));
    }

    if (path.indexOf('/topup') === 0) {
      this.setState((prevState) => ({
        menu: {
          ...prevState.menu,
          purchases: true,
          start: 'purchases',
        },
      }));
    }

    this.setState({
      username: authenticationService.currentUser.username,
      company_object: ['admin'].filter((x) => groups.includes(x)).length > 0 ? null : authenticationService.currentUser.person_object.company_object,
    }, () => {
      // Load balance
      if (this.state.company_object) {
        authenticationService.fetchApi({
          url: '/money_transaction/?grouped=true&page=1&rows_per_page=1&company_uuid=' + this.state.company_object.company_uuid,
          method: 'GET',
        })
          .then((result) => {
            // Keep balance for current session
            this.setState({ balance: (result.totals['debit'] - result.totals['credit']) }, () => {
              authenticationService.setSettings('currentBalance', this.state.balance);
            });
          })
          .catch((e) => { })
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    this.unlisten();
  }

  render() {
    const { location: { pathname } } = this.props;

    return (
      <Root className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" elevation={10} className={clsx(classes.appBar, this.state.open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              className={clsx(classes.menuButton, this.state.open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              {this.props.leftIcon &&
                <Box display='inline' pr={2}>{this.props.leftIcon}</Box>
              }
              <Box display='inline'>{this.props.title}</Box>

            </Typography>
            <PrivateComponent roles={['company']}>
              <Tooltip title="Saldo actual">
                <Button variant="contained"
                  color="primary"
                  component={Link}
                  to='/topup'
                  style={{ borderRadius: '22px' }}
                >
                  <Chip variant="outlined" size="small" label={'$'} sx={{ backgroundColor: 'lime', fontWeight: 'bold', cursor: 'pointer', color: 'inherit', mr: 0.6 }} />
                  <Typography variant="caption" color="inherit" noWrap>
                    <NumberFormat displayType={'text'}
                        value={this.state.balance}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={''}
                        suffix={' CLP'}
                        style={{ fontWeight: 'bold' }}
                      />
                  </Typography>

                </Button>
              </Tooltip>
                
            </PrivateComponent>
            {/* Lo deshabilité por conflicto con webpack5 */}
            {/* <PrivateComponent roles={['agent',]}>
              <Webphone />
            </PrivateComponent> */}
            <PrivateComponent roles={['company', 'agent', 'admin']}>
              <List aria-label="Account" dense={true} className={classes.py_0}>
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="menu-appbar"
                  aria-label="when device is locked"
                  ref={this.state.topbar.menuRef}
                  onClick={this.handleTopbarDropdown}
                >
                  <Hidden mdDown>
                    <ListItemText primary={this.state.username} />
                  </Hidden>
                  <Hidden mdUp>
                    <AccountCircleRoundedIcon />
                  </Hidden>
                  <ArrowDropDownIcon />
                </ListItem>
              </List>
              <Menu id="menu-appbar"
                anchorEl={this.state.topbar.menuRef.current}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={this.state.topbar.menuOpen}
                onClose={this.handleTopbarDropdown}
              >

                {['admin'].filter((x) => authenticationService.currentUser.groups.includes(x)).length > 0 &&
                  <MenuItem>Empresa: {this.state.company_object ? this.state.company_object.name : null}</MenuItem>
                }
                <Divider />
                <MenuItem component={Link} to="/security">
                  <ListItemIcon className={classes.listItemIcon} >
                    <SecurityIcon fontSize="small" />
                  </ListItemIcon>
                  Seguridad de tu cuenta
                </MenuItem>
                <MenuItem onClick={this.handleLogout}>
                  <ListItemIcon className={classes.listItemIcon} >
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  Cerrar sesión
                </MenuItem>
              </Menu>
            </PrivateComponent>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>

            <img src={Logo} alt='Axon' className={classes.logo} />

            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon style={{ color: 'white' }} />
            </IconButton>
          </div>
          <Divider />
          <div>

            <PrivateComponent roles={['company']}>

              <Divider />
              <List>
                <ListItem button key='Dashboard' component={Link} to='/'>
                  <ListItemIcon><DashboardIcon /></ListItemIcon>
                  <ListItemText primary='Dashboard' />
                </ListItem>
                <ListItem button key='mi_empresa' onClick={() => this.handleDropdown('company')}>
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary='Mi Empresa' />
                  {this.state.menu.company ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.menu.company} timeout={this.state.menu.start === 'company' ? 0 : 'auto'} unmountOnExit>
                  <List component="div" disablePadding>

                    <ListItem button key='company' component={Link} to='/company' className={classes.nested} selected={pathname === '/company'}>
                      <ListItemIcon><Settings /></ListItemIcon>
                      <ListItemText primary='Configuración' />
                    </ListItem>

                    <ListItem button key='credential' component={Link} to='/company/credentials' className={classes.nested} selected={pathname === '/company/credentials'}>
                      <ListItemIcon><VpnKeyIcon /></ListItemIcon>
                      <ListItemText primary='Credenciales API' />
                    </ListItem>

                    <ListItem button key='elastic_numbers' component={Link} to='/company/elastic_numbers' className={classes.nested} selected={pathname === '/company/elastic_numbers'}>
                      <ListItemIcon><Dialpad /></ListItemIcon>
                      <ListItemText primary='Números de salida' />
                    </ListItem>
                  </List>
                </Collapse>

                <ListItem button onClick={() => this.handleDropdown('sms_api')}>
                  <ListItemIcon>
                    <TextsmsIcon />
                  </ListItemIcon>
                  <ListItemText primary="SMS" />
                  {this.state.menu.sms_api ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.menu.sms_api} timeout={this.state.menu.start === 'sms_api' ? 0 : 'auto'} unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button key='sms_config' component={Link} to='/sms/config' className={classes.nested} selected={pathname === '/sms/config'}>
                      <ListItemIcon><Settings /></ListItemIcon>
                      <ListItemText primary='Configuración' />
                    </ListItem>

                    <ListItem button key='sms_routes' component={Link} to='/sms/routes' className={classes.nested} selected={pathname === '/sms/routes'}>
                      <ListItemIcon><PriceChangeIcon /></ListItemIcon>
                      <ListItemText primary='Rutas y Precios' />
                    </ListItem>

                    <ListItem button key='test_sms' component={Link} to='/sms/test' className={classes.nested} selected={pathname === '/sms/test'}>
                      <ListItemIcon><CodeIcon /></ListItemIcon>
                      <ListItemText primary='Probar SMS' />
                    </ListItem>

                    {/* <ListItem button key='sms_template' component={Link} to='/sms/template' className={classes.nested} selected={pathname === '/sms/template'}>
                      <ListItemIcon><TextSnippetIcon /></ListItemIcon>
                      <ListItemText primary='Plantillas' />
                    </ListItem> */}

                    <ListItem button key='campaigns' component={Link} to='/sms/campaigns' className={classes.nested} selected={pathname === '/sms/campaigns'}>
                      <ListItemIcon><FolderOpenIcon /></ListItemIcon>
                      <ListItemText primary='Campañas' />
                    </ListItem>

                    <ListItem button key='outgoing_sms' component={Link} to='/sms/outgoing' className={classes.nested} selected={pathname === '/sms/outgoing'}>
                      <ListItemIcon><SendIcon /></ListItemIcon>
                      <ListItemText primary='SMS salientes' />
                    </ListItem>

                    <ListItem button key='incoming_sms' component={Link} to='/sms/incoming' className={classes.nested} selected={pathname === '/sms/incoming'}>
                      <ListItemIcon><InboxIcon /></ListItemIcon>
                      <ListItemText primary='SMS entrantes' />
                    </ListItem>
                  </List>
                </Collapse>

                {false &&
                  <Box>

                    <ListItem button onClick={() => this.handleDropdown('whatsapp_api')}>
                      <ListItemIcon>
                        <WhatsAppIcon />
                      </ListItemIcon>
                      <ListItemText primary="Whatsapp" />
                      {this.state.menu.whatsapp_api ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.menu.whatsapp_api} timeout={this.state.menu.start === 'whatsapp_api' ? 0 : 'auto'} unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem button key='whatsapp_config' component={Link} to='/whatsapp/config' className={classes.nested} selected={pathname === '/whatsapp/config'}>
                          <ListItemIcon><Settings /></ListItemIcon>
                          <ListItemText primary='Configuración' />
                        </ListItem>
                        <ListItem button key='whatsapp_template' component={Link} to='/whatsapp/template' className={classes.nested} selected={pathname === '/whatsapp/template'}>
                          <ListItemIcon><TextSnippetIcon /></ListItemIcon>
                          <ListItemText primary='Plantillas' />
                        </ListItem>

                      </List>
                    </Collapse>


                    <ListItem button onClick={() => this.handleDropdown('email_api')}>
                      <ListItemIcon>
                        <EmailIcon />
                      </ListItemIcon>
                      <ListItemText primary="E-Mail" />
                      {this.state.menu.email_api ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.menu.email_api} timeout={this.state.menu.start === 'email_api' ? 0 : 'auto'} unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem button key='email_config' component={Link} to='/email/config' className={classes.nested} selected={pathname === '/email/config'}>
                          <ListItemIcon><Settings /></ListItemIcon>
                          <ListItemText primary='Configuración' />
                        </ListItem>
                        <ListItem button key='email_template' component={Link} to='/email/template' className={classes.nested} selected={pathname === '/email/template'}>
                          <ListItemIcon><TextSnippetIcon /></ListItemIcon>
                          <ListItemText primary='Plantillas' />
                        </ListItem>

                      </List>
                    </Collapse>



                    <ListItem button onClick={() => this.handleDropdown('voip_api')}>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ListItemText primary="VOIP" />
                      {this.state.menu.voip_api ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.menu.voip_api} timeout={this.state.menu.start === 'voip_api' ? 0 : 'auto'} unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem button key='voip_config' component={Link} to='/voip/config' className={classes.nested} selected={pathname === '/voip/config'}>
                          <ListItemIcon><Settings /></ListItemIcon>
                          <ListItemText primary='Configuración' />
                        </ListItem>
                        <ListItem button key='calls' component={Link} to='/voip/calls' className={classes.nested} selected={pathname === '/voip/calls'}>
                          <ListItemIcon><HistoryIcon /></ListItemIcon>
                          <ListItemText primary='Historial' />
                        </ListItem>
                      </List>

                    </Collapse>





                  </Box>
                }

                <ListItem button onClick={() => this.handleDropdown('verify_api')}>
                  <ListItemIcon>
                    <VpnKeyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Verify" />
                  {this.state.menu.verify_api ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.menu.verify_api} timeout={this.state.menu.start === 'verify' ? 0 : 'auto'} unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button key='verify_services' component={Link} to='/verify/services' className={classes.nested} selected={pathname === '/verify/services'}>
                      <ListItemIcon><BuildIcon /></ListItemIcon>
                      <ListItemText primary='Servicios' />
                    </ListItem>
                  </List>
                </Collapse>

                <ListItem button key='contactos' component={Link} to="/contacts" selected={pathname === '/contacts'}>
                  <ListItemIcon><PeopleIcon /></ListItemIcon>
                  <ListItemText primary='Contactos' />
                </ListItem>

                <ListItem button onClick={() => this.handleDropdown('purchases')}>
                  <ListItemIcon>
                    <MonetizationOnIcon />
                  </ListItemIcon>
                  <ListItemText primary="Cargas" />
                  {this.state.menu.purchases ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.menu.purchases} timeout={this.state.menu.start === 'purchases' ? 0 : 'auto'} unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button key='topup' component={Link} to='/topup' className={classes.nested} selected={pathname === '/topup'}>
                      <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
                      <ListItemText primary='Cargar crédito' />
                    </ListItem>
                    <ListItem button key='topups' component={Link} to='/topups' className={classes.nested} selected={pathname === '/topups'}>
                      <ListItemIcon><ReceiptIcon /></ListItemIcon>
                      <ListItemText primary='Historial de cargas' />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </PrivateComponent>

            <PrivateComponent roles={['admin']}>
              <Divider />
              <List>
                <ListItem button key='companies' component={Link} to='/admin/companies'>
                  <ListItemIcon><BusinessIcon /></ListItemIcon>
                  <ListItemText primary='Empresas' />
                </ListItem>

                <ListItem button key='sms_admin' component={Link} to='/admin/messages'>
                  <ListItemIcon><TextsmsIcon /></ListItemIcon>
                  <ListItemText primary='Messages' />
                </ListItem>

                <ListItem button key='topups_admin' component={Link} to='/admin/topups'>
                  <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
                  <ListItemText primary='Top Ups' />
                </ListItem>

              </List>
            </PrivateComponent>

            <PrivateComponent roles={['agent']}>
              <Divider />
              <List subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  &nbsp;
                </ListSubheader>
              }>
                <ListItem button key='Dashboard' component={Link} to='/'>
                  <ListItemIcon><DashboardIcon /></ListItemIcon>
                  <ListItemText primary='Dashboard' />
                </ListItem>
                <ListItem button key='calls' component={Link} to='/calls' selected={pathname === '/calls'}>
                  <ListItemIcon><HistoryIcon /></ListItemIcon>
                  <ListItemText primary='Historial' />
                </ListItem>
              </List>
            </PrivateComponent>

          </div>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            {this.props.children}
          </Container>
          <Container>
            <Box pt={4}>
              {/* AxonIO ® */}
            </Box>
          </Container>
        </main>
      </Root>
    );
  }
}

export default withRouter(Layout);
