import React from 'react';
import { Link } from 'react-router-dom';

import { authenticationService } from 'auth/authenticationService';
import MoneyTransaction from '../moneyTransaction';
// import { PhoneNumber } from 'adds/PhoneNumber.js'
import SandboxPhone from 'pages/sms/SandboxPhone';

import {
  Paper, Box, Grid, 
  // Alert,
  Typography, Button, Tooltip, 
  // IconButton,
  Card, CardContent, 
  // CardActions,
  Stack,
  // Collapse, 
  Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EmailIcon from '@mui/icons-material/Email';
// import PhoneIcon from '@mui/icons-material/Phone';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CodeIcon from '@mui/icons-material/Code';
// import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
// import TextsmsIcon from '@mui/icons-material/Textsms';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import LaunchIcon from '@mui/icons-material/Launch';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SchoolIcon from '@mui/icons-material/School';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import BuildIcon from '@mui/icons-material/Build';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

const useStyles = (theme) => ({
  fullHeightCard: {
    'height': '100%',
    'position': 'relative',
    'display': 'flex',
    'flexDirection': 'column',
    '& .MuiCardActions-root': {
      height: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
  },
});


class CompanyDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: authenticationService.currentUser.person_object.company_object,
      routes: [],
      html:{
        expand_index: -1
      },
      sandbox: {
        sandbox_validated_phone: '',
        sandbox_balance: 0,
      },
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleLoad() {
    authenticationService.fetchApi({
      url: '/company_dashboard/',
      method: 'GET',
    })
      .then((result) => {
        this.setState({ routes: result.routes, sandbox: result.sandbox });
      });
  }
  handleExpand(index) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        expand_index: index === prevState.html.expand_index ? null : index,
      }
    }));
  }

  componentDidMount() {
    this.handleLoad();
  }

  render() {
    return (
      <Grid container spacing={2}>
        
        
        <Grid item sm={12} lg={6}>
          <Card className={this.props.classes.fullHeightCard}>
            <CardContent>

              <Stack direction="row" alignItems="center" gap={1}>
                <SchoolIcon color='secondary' sx={{color:'#0066ff', fontSize:"2em", fontWeight:'300'}}/>
                <Box sx={{color:'#0066ff', fontSize:"1.5em", fontWeight:'300'}} p={1}>
                  Paso a Paso
                </Box>
              </Stack>
              {this.state.sandbox.sandbox_balance > 0 &&
              <Accordion  key={0}
                          expanded={0 === this.state.html.expand_index} 
                          onChange={(event) => this.handleExpand(0)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="overline" sx={{fontWeight:"bold"}} color="secondary">Paso 0: Manda gratis con un teléfono de pruebas (opcional)</Typography>
                 
                </AccordionSummary>
                <AccordionDetails>
                  

                  <Box p={1}>
                    <SandboxPhone />
                  </Box>
                      
                  <Box p={1} align="right">
                    <Button size="small" color="primary" component={Link} to='/api_v1' target="_blank">
                      Ver documentación
                    </Button>
                  </Box>

                </AccordionDetails>
              </Accordion>
              }

              <Accordion  key={1}
                          expanded={1 === this.state.html.expand_index}
                          onChange={(event) => this.handleExpand(1)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                > 
                  
                  <Typography variant="overline" sx={{fontWeight:"bold"}} color="primary">Paso 1: Carga Créditos</Typography>
                
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" color="textSecondary">
                    El envío de SMS funciona mediante precarga de créditos. Si eres una empresa y deseas un modalidad postcarga, escríbenos a contacto@axonio.dev.
                    <br/><br/>
                    Para realizar precargas, puedes usar tarjeta de crédito o realizar una transferencia bancaria.
                    A mayor carga, mayor será el porcentaje de descuento al que accederás.
                    <br/>
                      <Button size="small" color="primary" component={Link} to='/topup' startIcon={<MonetizationOnIcon/>}>
                        Empieza cargando saldo
                      </Button>
                    <br/><br/>
                    {/* Cuando selecciones el monto que quieres cargar, verás el precio al que accedes segun el descuento por volumen. */}
                    Para ver el costo de tus mensajes, lo puedes hacer en la sección Rutas y Precios,
                    que además mostrará los países que tienes activados.
                    <br/>
                    <Button size="small" component={Link} to='/sms/routes' startIcon={<PriceChangeIcon/>}>Revisa Rutas y precios</Button>
                  </Typography>
                  
                </AccordionDetails>
              </Accordion>

              <Accordion  key={2}
                          expanded={2 === this.state.html.expand_index}
                          onChange={(event) => this.handleExpand(2)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                > 
                  
                  <Typography variant="overline" sx={{fontWeight:"bold"}} color="primary" >Paso 2: Administra tus credenciales</Typography>
                  
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Las credenciales son las llaves de acceso a las funcionalidades de la API y plataforma, 
                    donde cada una tiene un token único.<br/>
                    La creación de tu cuenta ya incorpora una credencial por defecto que activa todas las funciones. 
                    Al mismo tiempo, al activar tu teléfono de pruebas del Paso 0, también se activa una credencial con el saldo de pruebas con funciones limitadas.<br/>
                    Puedes tener varias credenciales para distintos usuarios y desactivarlas cuando requieras. <br/>Todas las credenciales comparten el mismo saldo disponible. <br /><br />
                    

                    <Button size="small" color="primary" component={Link} to='/company/credentials' startIcon={<VpnKeyIcon/>}>
                      Adminstra tus credenciales
                    </Button>
                  </Typography>

                </AccordionDetails>
              </Accordion>

              <Accordion  key={3}
                          expanded={3 === this.state.html.expand_index}
                          onChange={(event) => this.handleExpand(3)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                > 
                  
                  <Typography variant="overline" sx={{fontWeight:"bold"}} color="primary" >Paso 3: Comienza a utilizar las funcionalidades!</Typography>
                  
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Si quiere probar el envío de mensajes, las sección de Probar SMS te permitirá enviar un SMS 
                    junto con generar y desplegar el código API que realiza dicho envío.<br/>
                    <Button size="small" color="primary" component={Link} to='/sms/test' startIcon={<CodeIcon/>}>
                      Prueba un envío de SMS
                    </Button><br/>
                    Si quieres hacer envíos masivos o envíos programados, en la sección de campañas tenemos un conjunto de funcionalidaes 
                    que te permitirán crear listas de contactos, subir nóminas, programar horarios de envíos, entre muchas otras cosas.
                    <br/>
                    <Button size="small" color="primary" component={Link} to='/sms/campaigns' startIcon={<FolderOpenIcon/>}>
                      Crea campañas de envíos masivos
                    </Button><br/>
                      Luego, si quieres utilizar nuestra APP de OTP, puedes ir directo a la sección de Verify y administrar servicios con mensajes personalizados.
                    <br/>
                    <Button size="small" color="primary" component={Link} to='/verify/services' startIcon={<BuildIcon/>}>
                      Crear un servicio Verify
                    </Button>
                  </Typography>

                </AccordionDetails>
              </Accordion>

              <Box p={2}>
                <Typography variant="overline" color='primary'>¿Necesitas ayuda?</Typography>
                <Box>
                  <Tooltip title="Enviar correo">
                    <Button
                      href="mailto:contacto@axonio.dev"
                      target="_top"
                      aria-label="enviar"
                      color='secondary'
                      size="small"
                      startIcon={<EmailIcon fontSize="small" />}
                    >
                      Escríbenos a contacto@axonio.dev
                    </Button>
                  </Tooltip>
                  <br/>
                  <Tooltip title="Revisa documentación de API">
                    <Button
                      component={Link}
                      target="_blank"
                      to='/api_v1'
                      aria-label="api"
                      color='secondary'
                      size="small"
                      startIcon={<CodeIcon fontSize="small" />}
                    >
                      Revisa la documentación de la API
                    </Button>
                  </Tooltip>
                  <br/>
                  <Tooltip title="Revisa documentación de plataforma">
                    <Button
                      component={Link}
                      target="_blank"
                      to={{ pathname: 'https://www.axonio.dev/documentacion/'}}
                      color='secondary'
                      size="small"
                      aria-label="api"
                      startIcon={<AutoStoriesIcon fontSize="small" />}
                    >
                      Revisa la documentación de la plataforma
                    </Button>
                  </Tooltip>
                  
                </Box>
              </Box>

            </CardContent>
          </Card>
        </Grid>

        <Grid item sm={12} lg={6}>
          <Paper>
            <Box p={2}>
              <Stack direction="row" alignItems="center" gap={1}>
                <MonetizationOnIcon color='secondary' sx={{color:'#0066ff', fontSize:"2em", fontWeight:'300'}}/>
                <Box sx={{color:'#0066ff', fontSize:"1.5em", fontWeight:'300'}} p={1}>
                  Transacciones de los últimos días
                </Box>
              </Stack>
              <MoneyTransaction company={this.state.company} />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs md={4}>
          <Paper>
            
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(CompanyDashboard);
