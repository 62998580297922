import React from 'react';
import Layout from '../../layout/index.js'
import {authenticationService} from 'auth/authenticationService'
import Credential from './Credential.js'
import {
  Paper, Box,
  Button, List, IconButton,
  Typography, Grid
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';
import { Link } from 'react-router-dom';


class Credentials extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company_uuid: this.props.company_uuid,
      credentials: [],
      html: {
        add: false,
      },
    };

    this.list = this.list.bind(this);
    this.addNew = this.addNew.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  addNew(prevState) {
    this.setState((prevState) => ({
      credentials: [{
        credential_uuid: '',
        token: '',
        expiration_date: '',
      }, ...prevState.credentials],

    }), this.setState({html: {add: false}}),
    );
  }

  removeItem(index) {
    const credentials = [...this.state.credentials];
    credentials.splice(index, 1)
    this.setState({credentials})
  }

  list() {
    this.setState({html: {add: true}});
    authenticationService.fetchApi({
      url: '/company/credential/',
      method: 'GET',
    })
        .then((result) => {
          this.setState({credentials: []},
              () => this.setState({credentials: result}));
        }).catch((err)=>{})
  }

  componentDidMount() {
    this.list();
  }

  render() {
    return (
      <Layout title='Credenciales API'>
        <Paper>
          <Box p={2}>
            <Grid container>
              <Grid item xs={2} sm={1}>
                <IconButton component={Link} 
                          aria-label="help" 
                          to={{ pathname: 'https://www.axonio.dev/credenciales-api/'}}
                          target='_blank'>
                  <HelpIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10} sm={11}>
                <Typography variant="caption" sx={{color:'#AAAAAA'}}>
                  Las credenciales API son necesarias para el uso de todas las funciones de la API y
                  para todas las funciones de la plataforma que consuman la API 
                  (indicadas tanto en esta documentación como en la plataforma). 
                  Como tal, debes tener al menos una credencial activa. 
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box p={1} m={1}>
            {this.state.html.add &&
              <Box textAlign="left">
                <Button onClick={this.addNew} variant="contained" color="primary" startIcon={<AddIcon />}>Agregar credencial de acceso</Button>
              </Box>
            }
            <List className="credentials">
              {this.state.credentials && this.state.credentials.map((credential, index) => {
                return <Credential key={credential.credential_uuid}
                  data={credential}
                  list={this.list}
                  removeItem={this.removeItem.bind(this, index)} />
              })}
            </List>
          </Box>
        </Paper>
      </Layout>
    );
  }
}

export default Credentials;
