import React from "react";
import { Link, withRouter } from 'react-router-dom';
import LoginLayout from 'layout/LoginLayout'
import { authenticationService } from 'auth/authenticationService'
import {
  TextField, Typography, CircularProgress,
  Button, Box, Stack, Divider,
} from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import TagManager from 'react-gtm-module';


class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: true,
      username: '',
      password: '',
      login_error: false,
      login_feedback: false,
      loginloading:false,

      two_factor: false,
      two_factor_token: '',
      two_factor_token_error: false,
      two_factor_token_feedback: false,
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLoad(token) {
    TagManager.dataLayer({
      dataLayer: {
      event : 'vpv',
      pagePath : '/login',
      pageTitle: 'Inicia el Login'

//        event: 'login-view',
//        pagePath: '/login',
//        pageTitle: 'Inicia Login',
      },
    });
  }

  componentDidMount() {
    this.handleLoad();
    console.log(this.props);
  }

  handleLogin(event) {
    event.preventDefault();
    let data = {
      username: this.state.username,
      password: this.state.password
    }

    if (this.state.two_factor) {
      data['two_factor_token'] = this.state.two_factor_token
      // console.log('entra');
    }
    // console.log(data)

    this.setState({loginloading: true});

    authenticationService.login(data)
      .then((response) => {
        if (response.status === 'OK') {
          this.setState({
            login_error: false,
            two_factor_token_feedback: false,
            two_factor_token_error: false,
            two_factor: false,
            login: true,
            loginloading: false
          });
          TagManager.dataLayer({
            dataLayer: {
              event: 'login-success',
              pagePath: '/login',
              pageTitle: 'Login Correcto',
            },
          });
          this.props.history.push('/dashboard');
        }
        else if (response.status === 'TWO_FACTOR') {
          this.setState({
            login_error: false,
            login_feedback: false,
            two_factor: true,
            login: false,
            loginloading: false
          })
        }
        else if (response.status === 'ERROR') {
          TagManager.dataLayer({
            dataLayer: {
              event: 'login-fail',
              pagePath: '/login',
              pageTitle: 'Login Incorrecto',
            },
          });

          if (response.status_description === 'WRONG_USERNAME_OR_PASSWORD') {
            this.setState({
              login_feedback: 'Usuario o contraseña inválidos',
              login_error: true,
              loginloading: false
            });
          }
          else if (response.status_description === 'WRONG_TOKEN') {
            this.setState({
              two_factor_token_feedback: 'Código incorrecto',
              two_factor_token_error: true,
              loginloading: false
            });
          }
        }
        else {

          this.setState({
            login_feedback: 'Usuario o contraseña inválidos',
            login_error: true,
            loginloading: false
          });
        }
      })
      .catch(error => {
        this.setState({
          login_feedback: 'No se pudo concretar el login. Por favor contáctanos por el chat.',
          login_error: true,
          loginloading: false
        });
      });
  }

  render() {
    return (
      <LoginLayout title='Login'>
        <form onSubmit={this.handleLogin}>

          {this.state.login &&
            <Stack gap={2} mb={3}>
              <TextField label='Email'
                id="username"
                name="username"
                value={this.state.username}
                error={this.state.login_error}
                fullWidth
                onChange={e => this.setState({ username: e.target.value })}
              />
              <TextField label='Contraseña'
                type="password"
                name="password"
                className='form-control'
                value={this.state.password}
                error={this.state.login_error}
                helperText={this.state.login_feedback}
                fullWidth
                onChange={e => this.setState({ password: e.target.value })}
              />
              <Button
                type="submit"
                onClick={this.handleLogin}
                color='secondary'
                variant="contained"
                startIcon={this.state.loginloading ? <CircularProgress size={20}/> : <LockOpenIcon />}
                disabled={this.state.loginloading ? true : false}
                fullWidth>
                Iniciar sesión
              </Button>
            </Stack>
          }

          {this.state.two_factor &&
            <Stack gap={2} mb={3}>
              <TextField label='Código de 4 dígitos para autenticación de dos factores'
                type="two_factor_token"
                name="two_factor_token"
                className='form-control'
                value={this.state.two_factor_token}
                error={this.state.two_factor_token_error}
                helperText={this.state.two_factor_token_feedback}
                fullWidth
                onChange={e => this.setState({ two_factor_token: e.target.value })}
              />
              <Button type='submit' onClick={this.handleLogin} color='secondary' variant='contained' fullWidth={true}>Validar código e iniciar Sesión</Button>
            </Stack>
          }
        </form>
        <Stack direction="row" gap={2} mb={2} justifyContent="space-between">
          <Button component={Link} to='https://www.axonio.dev' color='primary' size='small' >
              Volver al sitio web
          </Button>
          <Button component={Link} to='password_recovery/'  color='primary' size='small' >
              ¿Olvidaste tu contraseña?
          </Button>
        </Stack>
        <Divider />
        <Box mt={4}>
          <Button component={Link} to='signup/' variant="contained" color='primary' fullWidth={true}>¿Quieres registrarte?</Button>
        </Box>
      </LoginLayout>
    );
  }
}

export default withRouter(Login);
