import React from "react";
import LoginLayout from 'layout/LoginLayout'
import { Link,  withRouter } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import { authenticationService } from 'auth/authenticationService'
import {
  Typography, TextField, Button, Box, Alert
} from '@mui/material';


class PasswordRecovery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        token: '',
        password: '',
        password2: '',
      },
      html:{
        start: false,
        check_mail: false,
        form: false,
        success: false
      },
      feedback: {
        username: '',
        password: '',
        password2: '',
      },
      status:{
        username:null,
        password: null,
        password2:null,
      },
      error_msg:'',
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleUsernameValidation = this.handleUsernameValidation.bind(this);
    this.handlePasswordRecovery = this.handlePasswordRecovery.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePasswordValidation = this.handlePasswordValidation.bind(this);
    this.handleSubmitPassword = this.handleSubmitPassword.bind(this);


  }


  handleLoad(token){
    // console.log(token);
    if(token){
      this.setState(prevState => ({
        data: {
            ...prevState.data,
            token: token
        }
      }));
      this.setState(prevState => ({html: {...prevState.html,
        form: true
      }}));
    }
    else{
      this.setState(prevState => ({html: {...prevState.html,
        start: true
      }}));
    }
  }

  componentDidMount(){
    // console.log(this.props.match.params);
    this.handleLoad(this.props.match.params.token);
  }

  handleUsernameValidation(event){

    const { data } = { ...this.state };
    const currentState = data;
    const { name, value } = event.target;
    currentState[name] = value;

    this.setState({ data: currentState });

    let feedback = '';
    let status = false;
    if(value !== '' && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)){
      // console.log('a');
      authenticationService.checkExistence({username: value})
      .then( response => {
        if('username' in response){
          feedback = '';
          status = true;
        }
        else{
          feedback = 'Usuario no existe';
          status=false;
        }
        this.setState(prevState => ({feedback: {...prevState.feedback,
          username: feedback
        }}));
        this.setState(prevState => ({status: {...prevState.status,
          username: status
        }}));
      });
    }
    else{
      feedback = 'Email con formato incorrecto';
      status=false;
      this.setState(prevState => ({feedback: {...prevState.feedback,
        username: feedback
      }}));
      this.setState(prevState => ({status: {...prevState.status,
        username: status
      }}));
      // console.log(this.state.feedback)
    }

  }
  handlePasswordRecovery(event){
    event.preventDefault()
    if(this.state.status.username){
      authenticationService.passwordRecovery({username: this.state.data.username})
      .then( (response) => {
        // console.log(response);
        if('error' in response){
          this.setState({error_msg: response.error});
        }
        else{
          this.setState(prevState => ({html: {...prevState.html,
            start:false,
            check_mail: true,
            form:false,
            success: false
          }}));
        }
      });
    }
    else{

      this.setState(prevState => ({feedback: {...prevState.feedback,
        username: 'Debes ingresar un mail'
      }}));
      this.setState(prevState => ({status: {...prevState.status,
        username: false
      }}));
    }
  }

  handlePassword(value){
    this.setState(prevState => ({data: {...prevState.data,
      password: value
    }}),
    this.handlePasswordValidation(null,value)
    );
    if(value === ''){
      // console.log(1);
      this.setState(prevState => ({feedback: {...prevState.feedback,
        password: 'Debes ingresar una nueva contraseña'
      }}));
      this.setState(prevState => ({status: {...prevState.status,
        password: false
      }}));
    }
    else{

      let score = zxcvbn(value).score;
      // console.log(score)
      if (score<3){
        this.setState(prevState => ({feedback: {...prevState.feedback,
          password: "Password muy débil. \n Trata con más caractéres, números y/o símbolos"
        }}));
        this.setState(prevState => ({status: {...prevState.status,
          password: false
        }}));
      }
      else{
        this.setState(prevState => ({feedback: {...prevState.feedback,
          password: ''
        }}));
        this.setState(prevState => ({status: {...prevState.status,
          password: true
        }}));
      }
    }
  }

  handlePasswordValidation(value ,p_input=false){
    if(!p_input){
      this.setState(prevState => ({data: {...prevState.data,
        password2: value
      }}));
      p_input = this.state.data.password;
    }
    else{
      value = this.state.data.password2
    }

    if(value === p_input && value!==''){
      this.setState(prevState => ({feedback: {...prevState.feedback,
        password2: ''
      }}));
      this.setState(prevState => ({status: {...prevState.status,
        password2: true
      }}));
    }
    else{
      // console.log(1);
      this.setState(prevState => ({feedback: {...prevState.feedback,
        password2: 'Las dos contraseñas deben ser iguales'
      }}));
      this.setState(prevState => ({status: {...prevState.status,
        password2: false
      }}));
    }
  }

  handleSubmitPassword(event){
    event.preventDefault();
    if(this.state.data.token!=='' && this.state.status.password && this.state.status.password2){
      authenticationService.passwordRecovery({
        token: this.state.data.token,
        password: this.state.data.password,
      })
      .then( (response) => {
        // console.log(response);
        if('error' in response){
          this.setState({error_msg: response.error});
        }
        else{
          this.setState({html:
            {
              start: false,
              check_mail: false,
              form: false,
              success: true,
            }
          });
        }
      });
    }
  }

  render() {
    return (
    <LoginLayout>

      {this.state.html.start &&
      <Box>
        <Typography variant='h6'>Recuperación de Contraseña</Typography>

        <form onSubmit={this.handlePasswordRecovery}>
          <Box py={1}>
            <TextField  type="username"
                        name="username"
                        label="Ingresa tu email"
                        value={this.state.username}
                        error={this.state.status.username===false?!this.state.status.username:null}
                        helperText={this.state.feedback.username}
                        fullWidth
                        onChange={this.handleUsernameValidation}
            />
          </Box>
          <Box py={1}>
            <Button type='submit' onClick={this.handlePasswordRecovery} color='primary' variant='contained'>Recuperar contraseña</Button>
          </Box>
        </form>

        <br/>
        <Box py={1}>
          <Button component={Link} color='primary'  to='/login'>Ingresar a la plataforma</Button>
        </Box>
      </Box>
      }

      {this.state.html.check_mail &&
      <Box py={1}>
        <Alert severity="info">
          Revisa tu email, para seguir con el cambio de contraseña.
        </Alert>
        <Box py={1} align='right'>
          <Button component={Link} to='/login' color='primary'>Ingresar a la plataforma</Button>
        </Box>
      </Box>
      }

      {this.state.html.form &&
      <Box>
        <Typography variant='h6'>Ingresa tu nueva contraseña</Typography>
        <form onSubmit={this.handleNewPassword}>

          <Box py={1}>
            <TextField  label="Contraseña"
                        type="password"
                        name="password"
                        error={this.state.status.password===false?!this.state.status.password:null}
                        value={this.state.password}
                        helperText={this.state.feedback.password}
                        fullWidth
                        onChange={(event) => this.handlePassword(event.target.value)}
            />
          </Box>

          <Box py={1}>
            <TextField  label="Validar contraseña"
                        type="password"
                        name="password2"
                        value={this.state.password2}
                        error={this.state.status.password2===false?!this.state.status.password2:null}
                        helperText={this.state.feedback.password2}
                        fullWidth
                        onChange={(event) => this.handlePasswordValidation(event.target.value)}
            />
          </Box>

          <Box py={1}>
            <Button onClick={this.handleSubmitPassword} variant='contained' color='primary'>Cambiar contraseña</Button>
          </Box>

        </form>
      </Box>
      }
      {this.state.html.success &&
      <Box>
        <Alert severity="info">
          La contraseña ha sido modifcada. Por favor, ingresa a tu cuenta en el siguiente botón.
        </Alert>
        <Box py={1} align='right'>
          <Button component={Link} to='/login' color='primary'>Ir a la autenticación</Button>
        </Box>
      </Box>
      }
      <div className="error_msg">
        {this.state.error_msg}
      </div>

    </LoginLayout>
    );
  }
}

export default withRouter(PasswordRecovery);
