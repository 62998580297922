import React from 'react';
import { authenticationService } from 'auth/authenticationService'
import {
  Typography, Paper, Box,
} from '@mui/material';


class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: authenticationService.currentUser.person_object.company_object,
    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <Paper>
          <Box p={2} m={1}>
          <Typography variant='h5'>Graph</Typography>
          <Box>Contenido del gráfico</Box>
          </Box>
      </Paper>
    );
  }
}

export default AdminDashboard;
