import React from 'react';

import { authenticationService } from 'auth/authenticationService'


// Auth Components
export default function PrivateComponent(props) {
  let groups = authenticationService.currentUser.groups
  let haveRole = false;

  let intersection = props.roles.filter(x => groups.includes(x));
  intersection.length>0 ? haveRole=true : haveRole=false


  return (
    authenticationService.isAuthenticated &&
    haveRole ?
    (
      <div>
        {props.children}
      </div>
    )
    : null
  );
}
