import React from "react";
import { authenticationService } from 'auth/authenticationService'
import {
  Grid, Box, Button, TextField, Fade, Alert,
  TableSortLabel, TablePagination, TableContainer,
  Table, TableRow, TableHead, TableBody, TableCell,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from "@mui/icons-material/Send";

const headCells = [
  { id: "first_name", numeric: false, disablePadding: false, label: "Nombres", sorting: false },
  { id: "last_name", numeric: false, disablePadding: false, label: "Apellidos", sorting: false },
  { id: "email", numeric: false, disablePadding: false, label: "Email", sorting: false },
  { id: "extension", numeric: false, disablePadding: false, label: "Extension", sorting: true },
  { id: "actions", numeric: true, disablePadding: false, label: "Acciones", sorting: false }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          !headCell.sorting ?
            <TableCell key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}>
              {headCell.label}
            </TableCell>
            : (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className='hideSort'>
                      {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        ))}
      </TableRow>
    </TableHead>
  );
}

function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

class Agents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      objects: [],
      html: {
        page: 1,
        list: true,
        error: false,
        agent_dialog: false,
      },
      data: {
        person_uuid: '',
        first_name: '',
        last_name: '',
        username: '',
        extension: '',
      },
      filters: {
      },
      pagination: {
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by: 'extension',
        order: 'asc',
      },
      resend_success: false,
      resend_fail: false,
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.openAgentDialog = this.openAgentDialog.bind(this);
    this.closeAgentDialog = this.closeAgentDialog.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAgentSubmit = this.handleAgentSubmit.bind(this);
    this.handleResendInvitation = this.handleResendInvitation.bind(this)
  }

  handleChangePage(event, page) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
      }
    }), () => this.handleLoad());
  }

  handleChangeRowsPerPage(event) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        rows_per_page: event.target.value,
      }
    }), () => this.handleLoad());
  }

  handleSort(event, property) {
    const isAsc = this.state.sorting.order_by === property && this.state.sorting.order === "asc";
    this.setState(prevState => ({
      sorting: {
        ...prevState.sorting,
        order: isAsc ? "desc" : "asc",
        order_by: property,
      }
    }), () => this.handleLoad());
  }

  handleLoad(page = null) {
    var params = {
      page: this.state.pagination.page + 1,
      order_by: this.state.sorting.order_by,
      order: this.state.sorting.order,
      rows_per_page: this.state.pagination.rows_per_page,
    };

    var query = encodeQueryData(params)

    authenticationService.fetchApi({
      url: '/agent/?' + query,
      method: 'GET',
      api: true
    })
      .then((result) => {
        if (result) {
          this.setState(prevState => ({
            pagination: {
              ...prevState.pagination,
              rows_num: result.rows_num
            },
            html: {
              ...prevState.html,
              list: true,
              agent_dialog: false,
            },
            objects: result.data,
            data: {
              person_uuid: '',
              first_name: '',
              last_name: '',
              username: '',
              extension: '',
            }
          }));
        }
        else {
          this.setState(prevState => ({
            html: {
              ...prevState.html,
              error: true,
              list: false,
              agent_dialog: false,
            },
          }));
        }
      });
  }

  componentDidMount() {
    this.handleLoad();
  }

  openAgentDialog(event, entity) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        agent_dialog: true,
        agent_dialog_entity: entity,
      },
      data: {
        person_uuid: entity.person_uuid || 'new',
        first_name: entity.first_name || '',
        last_name: entity.last_name || '',
        username: entity.username || '',
        extension: entity.extension || '',
      }
    }));
  }

  closeAgentDialog(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        agent_dialog: false,
      },
      data: {
        person_uuid: '',
        first_name: '',
        last_name: '',
        username: '',
        extension: '',
      }
    }));
  }

  handleInputChange(event) {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value } = event.target;
    currentState[name] = value;

    this.setState({ data: currentState });
  }

  handleAgentSubmit(event) {
    event.preventDefault();

    authenticationService.fetchApi({
      url: '/agent/',
      method: 'POST',
      body: JSON.stringify(this.state.data),
      api: true,
    })
      .then((result) => {
        this.handleLoad();
      });
  }

  handleResendInvitation(event, person_uuid) {
    event.preventDefault();

    authenticationService.fetchApi({
      url: '/agent/resend_invitation/',
      method: 'POST',
      body: JSON.stringify({person_uuid: person_uuid}),
      api: true,
    })
      .then((result) => {
        if ('success' in result) {
          this.setState({ resend_success: true });
          setTimeout(() => {
            this.setState({ resend_success: false });
          }, 1500);
        }
        else {

          this.setState({ resend_fail: true });
          setTimeout(() => {
            this.setState({ resend_fail: false });
          }, 1500);
        }

      });
  }

  render() {
    return (
      <Box m={2}>
        { this.state.html.list &&
          <Box>
            <Box align="right">
              <Button onClick={(event) => this.openAgentDialog(event, 'new')} startIcon={<AddCircleIcon />} color="primary" variant="contained">Crear Agente</Button>
            </Box>
            {this.state.objects.length > 0 &&
              <Box my={2} pb={2}>
                <TablePagination
                  rowsPerPageOptions={[5, 10]}
                  component="div"
                  labelRowsPerPage='Resultados por página'
                  count={this.state.pagination.rows_num}
                  rowsPerPage={this.state.pagination.rows_per_page}
                  page={this.state.pagination.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
                <TableContainer>
                  <Table>
                    <EnhancedTableHead
                      order={this.state.sorting.order}
                      orderBy={this.state.sorting.order_by}
                      onRequestSort={this.handleSort}
                    />
                    <TableBody>
                      {this.state.objects.map((object) => {
                        return (
                          <TableRow key={object.person_uuid}>
                            <TableCell>
                              {object.first_name}
                            </TableCell>
                            <TableCell>
                              {object.last_name}
                            </TableCell>
                            <TableCell>
                              {object.username}
                            </TableCell>
                            <TableCell>
                              {object.extension}
                            </TableCell>
                            <TableCell align="right">
                              <Box display="inline" mr={1}><Button onClick={(event) => this.openAgentDialog(event, object)} size="small" variant="outlined" color="primary" startIcon={<EditIcon />}>Editar</Button></Box>
                              <Box display="inline"><Button onClick={(event) => this.handleResendInvitation(event, object.person_uuid)} size="small" variant="outlined" color="primary" startIcon={<SendIcon />}>Reenviar invitación</Button></Box>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            }
            {this.state.objects.length === 0 &&
              <Alert severity='warning'>
                No se encontraron agentes
            </Alert>
            }
            {this.state.resend_success &&
              <Fade in={this.state.resend_success}>
                <Alert severity='success'>
                  Se ha enviado una nueva invitación al agente.
                </Alert>
              </Fade>
            }
            {this.state.resend_fail &&
              <Fade in={this.state.resend_fail}>
                <Alert severity='error'>
                  Hubo un error
              </Alert>
              </Fade>
            }
          </Box>
        }
        { this.state.html.error &&
          <Alert severity='error'>Para desplegar esta vista debes seleccionar una credencial por defecto que esté activa y no-expirada, en la sección de Mi Empresa.</Alert>
        }
        <Dialog open={this.state.html.agent_dialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}>
          <DialogTitle id="form-dialog-title">Datos generales del Agente</DialogTitle>
          <DialogContent dividers={true}>
            {this.state.html.agent_dialog_entity !== '' &&
              <form noValidate autoComplete="off" onSubmit={this.handleAgentSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField name='first_name'
                      id='first_name'
                      label='Nombres'
                      fullWidth={true}
                      value={this.state.data.first_name}
                      onChange={this.handleInputChange} />

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='last_name'
                      id='last_name'
                      label='Apellidos'
                      fullWidth={true}
                      value={this.state.data.last_name}
                      onChange={this.handleInputChange} />

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='username'
                      id='username'
                      label='Email'
                      fullWidth={true}
                      value={this.state.data.username}
                      onChange={this.handleInputChange} />

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='extension'
                      id='extension'
                      label='Extension'
                      fullWidth={true}
                      value={this.state.data.extension}
                      onChange={this.handleInputChange} />

                  </Grid>
                </Grid>
              </form>
            }
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="outlined" onClick={this.handleAgentSubmit}>
              Guardar
            </Button>
            <Button color="secondary" onClick={this.closeAgentDialog}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default Agents;
