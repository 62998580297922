import React from "react";
import NumberFormat from 'react-number-format';
import Layout from '../../layout/index.js'
import { withRouter, Link } from 'react-router-dom';
import { authenticationService } from 'auth/authenticationService'
import {
  Box, Paper, Typography, Table, TableRow, TableHead, TableBody, TableCell
} from '@mui/material';

class WebpayReturn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webpay: {
        token: '',
        url: ''
      },
      data: '',
      success: 'display-none',
      fail: 'display-none',

    };

    this.handleLoad = this.handleLoad.bind(this);
  }

  handleLoad() {
    authenticationService.fetchApi({
      url: '/topup/webpay_payment/',
      method: 'POST',
      body: JSON.stringify({
        token: this.props.match.params.token,
        do: 'return'
      }),
    })
      .then(result => {
        this.setState(prevState => ({
          data: result,
          success: result["response_code"] === "0" ? 'display-inline' : 'display-none',
          fail: result["response_code"] !== "0" ? 'display-inline' : 'display-none',
          webpay: {
            token: result.token,
            url_redirection: result.url_redirection
          }
        }));
      });
  }

  componentDidMount() {
    this.handleLoad();
  }

  render() {
    return (
      <Layout title='Compra finalizada'>
        <Box m={1} className={this.state.success}>
          <Paper>
            <Box p={2}>
              <Typography variant='h5'>Compra realizada exitosamente</Typography>
              <form method="POST" action={this.state.webpay.url_redirection} className="form-edit">
                <Box py={2}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Detalle de la compra
                        </TableCell>
                        <TableCell>
                          
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          Orden de compra
                        </TableCell>
                        <TableCell>
                          {this.state.data.buy_order}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Comercio
                        </TableCell>
                        <TableCell>
                          AxonRT SPA ({this.state.data.commerce_code})
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Monto de la Compra
                        </TableCell>
                        <TableCell>
                          <NumberFormat displayType={'text'}
                            value={this.state.data.amount}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'$'} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Código de authorización
                        </TableCell>
                        <TableCell>
                          {this.state.data.authorization_code}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Fecha Transacción
                        </TableCell>
                        <TableCell>
                          {this.state.data.transaction_date}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Tipo de Pago
                        </TableCell>
                        <TableCell>
                          {this.state.data.payment_type_code}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Cuotas
                        </TableCell>
                        <TableCell>
                          {this.state.data.installments_number || 1}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Tarjeta terminada en
                        </TableCell>
                        <TableCell>
                          XXXX-XXXX-XXXX-{this.state.data.card_number}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <input type="hidden"
                  name="token_ws"
                  onChange={this.handleInputChange}
                  value={this.state.webpay.token} />
                {/* <Button type="submit"
                  variant="contained"
                  className='btn btn-submit'>
                    Obtener comprobante transbank
                </Button> */}
              </form>
            </Box>
          </Paper>
        </Box>
        <Box m={1} className={this.state.fail}>
          <Paper>
            <Box p={2}>
              <Typography variant='h5'>Hubo un error en el proceso de compra y no pudo finalizar</Typography>
              <Box py={2}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Parámetros del intento de compra
                      </TableCell>
                      <TableCell>
                
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Comercio
                      </TableCell>
                      <TableCell>
                        AxonRT SPA ({this.state.data.commerce_code})
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Monto de la Compra
                      </TableCell>
                      <TableCell>
                        <NumberFormat displayType={'text'}
                          value={this.state.data.amount}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          prefix={'$'} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Tarjeta terminada en
                      </TableCell>
                      <TableCell>
                        XXXX-XXXX-XXXX-{this.state.data.card_number}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Link className='btn btn-submit' to='/topup'>Intentar de nuevo</Link>
            </Box>
          </Paper>
        </Box>
      </Layout>
    );
  }
}

export default withRouter(WebpayReturn);
