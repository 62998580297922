import React from "react";
import { authenticationService } from 'auth/authenticationService'
import NumberFormat from 'react-number-format';
import BasketAdminDetails from './BasketAdminDetails';
import {
  Box, Paper, Typography, Alert,
  Accordion, AccordionDetails, AccordionSummary,
  TablePagination
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function encodeQueryData(data) {
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
}


class BasketAdmin extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      company_uuid: this.props.company_uuid ? this.props.company_uuid : null,
      basket_uuid: this.props.basket_uuid ? this.props.basket_uuid : null,
      objects: [],
      expanded: false,
      html: {
        page: 1,
        dialog: false
      },
      pagination:{
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by:'id',
        order:'desc',
      }
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleExpanded = this.handleExpanded.bind(this);
    this.reloadItem = this.reloadItem.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
  }
  handleChangePage(event, page){
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
          page: page,
      }}), ()=> this.handleLoad());

  }
  handleChangeRowsPerPage(event){
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
          rows_per_page: event.target.value,
      }}), ()=> this.handleLoad());
  }
  handleLoad() {
    if (!(this.state.company_uuid || this.state.basket_uuid)){
      return false;
    }

    this.setState(prevState => ({
      html: {
        ...prevState.html,
        object_display: 'display-none',
        list_display: 'display-inline',
        return: 'display-none'
      }
    }));

    
    var params = {
      page: this.state.pagination.page+1,
      order_by: this.state.sorting.order_by,
      order: this.state.sorting.order,
      rows_per_page: this.state.pagination.rows_per_page,
    };

    if (this.props.company_uuid) {
      params['company_uuid'] = this.props.company_uuid;
    }
    else if(this.props.basket_uuid) {
      params['basket_uuid'] = this.props.basket_uuid;
    }

    var query = encodeQueryData(params)

    authenticationService.fetchApi({
      url: '/topup/?'+ query ,
      method: 'GET',
    })
    .then(result => {
      this.setState(prevState => ({
        pagination: {
            ...prevState.pagination,
            rows_num: result.rows_num
        },
        objects: result.data
      }));
    })
  }

  handleExpanded(event, isExpanded, panel) {
    this.setState({expanded: isExpanded ? panel : false});
  }

  componentDidMount() {
    this.handleLoad();
  }

  reloadItem(index, params) {
    let objects = this.state.objects;
    objects[index] = params;
    this.setState({objects: objects});
  }

  render() {
    return (
      <Box>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          labelRowsPerPage='Resultados por página'
          count={this.state.pagination.rows_num}
          rowsPerPage={this.state.pagination.rows_per_page}
          page={this.state.pagination.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
        {this.state.objects && this.state.objects.map((object,index) =>
          <Accordion key={object.id} expanded={this.state.expanded === 'panel_' + object.id} onChange={(event, isExpanded) => this.handleExpanded(event, isExpanded, 'panel_' + object.id)}>
            <AccordionSummary   expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                >
              <Box p={1}>
                <Typography component='h5'>
                  {(new Date(object.updated_at)).toLocaleString('es-CL')}
                </Typography>
              </Box>
              <Box p={1}>
                <b>{object.state}</b> |&nbsp;
                {'Empresa: ' + object.company.name} |&nbsp;
                <NumberFormat displayType={'text'}
                              value={object.amount}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={'$'} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
                { object &&
                  <div className='display-inline'>
                  <Paper style={{backgroundColor: '#F5F8F5'}}>
                    <BasketAdminDetails topup={object} refresh_all={this.handleLoad} refresh_header={this.reloadItem.bind(this, index)}/>
                  </Paper>
                  </div>
                }
            </AccordionDetails>
          </Accordion>
        )}
        {this.state.objects.length === 0 &&
          <Alert severity="warning">
            No se encontraron cargas
          </Alert>
        }
      </Box>
    );
  }
}

export default BasketAdmin;