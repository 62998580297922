import React from "react";
import Layout from '../../layout/index.js'
import Lists from "./Lists.js";
import Tags from "./Tags.js";
import Contacts from "./Contacts.js";
import {
  AppBar, Paper, Tabs, Tab, Alert, Box
} from '@mui/material';

import { authenticationService } from 'auth/authenticationService'


class ContactsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        tab_index: 0,
        credential_error: true,
      },
      data: {},
      lists: [],
      tags: [],
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.loadObjects = this.loadObjects.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  componentDidMount() {
    let get_credential = authenticationService.getValidCredential()
    
    if (!get_credential.error) {
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          credential_error: false,
        },
      }), () => {
        this.handleLoad();
      });
    }
    else {
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          credential_error: true,
        },
      }));
    }
  }

  handleLoad() {
    this.loadObjects('both');
  }

  loadObjects(type = null) {
    var params = {
      rows_per_page: 1000
    };

    var query = encodeQueryData(params);
    
    // Lists
    if (['both', 'lists'].includes(type)) {
      authenticationService.fetchApi({
        url: '/list/?' + query,
        method: 'GET',
        api: true
      })
      .then(result => {
        this.setState({lists: result.data});
      })
      .catch(err => {

      });
    }

    // Tags
    if (['both', 'tags'].includes(type)) {
      authenticationService.fetchApi({
        url: '/tag/?' + query,
        method: 'GET',
        api: true
      })
      .then(result => {
        this.setState({tags: result.data});
      })
      .catch(err => {

      });
    }
  }

  handleChangeTab(event, newValue) {
    event.preventDefault();

    this.setState(prevState => ({
      html: {
        ...prevState.html,
        tab_index: newValue
      }
    }));
  }

  render() {
    return (
      <Layout title='Contactos'>
        <Paper>

          {this.state.html.credential_error &&
            <Alert severity='error'>Para desplegar esta vista debes seleccionar una credencial por defecto que esté activa y no-expirada, en la sección de Mi Empresa.</Alert>
          }
          {!this.state.html.credential_error &&
          <Box>
            <AppBar position="static">
              <Tabs
                value={this.state.html.tab_index}
                onChange={this.handleChangeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="Tabmenu"
                textColor="inherit"
              >
                <Tab label="Contactos" id="nav-tab-0" aria-controls="nav-tabpanel-0" />
                <Tab label="Listas" id="nav-tab-1" aria-controls="nav-tabpanel-1" />
                <Tab label="Etiquetas" id="nav-tab-2" aria-controls="nav-tabpanel-2" />
              </Tabs>
            </AppBar>
            <div role="tabpanel" hidden={this.state.html.tab_index !== 0} id="simple-tabpanel-0" aria-labelledby="simple-tab-0">
              <Contacts lists={this.state.lists} tags={this.state.tags} />
            </div>
            <div role="tabpanel" hidden={this.state.html.tab_index !== 1} id="simple-tabpanel-1" aria-labelledby="simple-tab-1">
              <Lists parentReload={() => this.loadObjects('lists')} />
            </div>
            <div role="tabpanel" hidden={this.state.html.tab_index !== 2} id="simple-tabpanel-2" aria-labelledby="simple-tab-2">
              <Tags parentReload={() => this.loadObjects('tags')} />
            </div>
          </Box>
          }
        </Paper>
      </Layout>
    );
  }
}

export default ContactsList;

function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}