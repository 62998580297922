import React from "react";
import { authenticationService } from 'auth/authenticationService';
import {
  Box, Paper, Button, Typography, TextField,
  Dialog, DialogTitle, DialogContent, DialogActions,
  TableSortLabel, TablePagination, TableContainer,
  Table, TableRow, TableHead, TableBody, TableCell,
  Alert, Autocomplete, CircularProgress, Stack,
  // FormControl, InputLabel, FormHelperText,
  FormControlLabel, Checkbox, Select, MenuItem, Chip,
  Divider, Avatar, Popover
} from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import PersonIcon from '@mui/icons-material/Person';
import jsonData from '../sms/sms_status.json';
import { read, utils } from 'xlsx';
const sms_status = jsonData;

const headCells = [
  { id: "first_name", numeric: false, disablePadding: false, label: "Contacto asociado", sorting: true },
  { id: "phone", numeric: false, disablePadding: false, label: "Teléfono", sorting: true },
  { id: "message", numeric: false, disablePadding: false, label: "Mensaje", sorting: false },
  { id: "status", numeric: false, disablePadding: false, label: "Estado", sorting: true },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          !headCell.sorting ?
            <TableCell key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}>
              {headCell.label}
            </TableCell>
            : (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className='hideSort'>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}


class CampaignContacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: this.props.campaign,
      objects: [],
      data: {
        list_uuid: '',
        tag_uuid: '',
        count: 0,
        body: '',
        cursorPosition: 0,
        template_uuid: '',
        link: '',
      },
      html: {
        submitting: false,
        contact_dialog: false,
        import_dialog: false,
        import_results: false,
        preview_messages: [],
        popover_anchorEl: null,
        popover_open: false,
        popover_content: ''
      },
      filters: {
        list_uuid: '',
        tag_uuid: ''
      },
      error: {
        body: false,
        link: false,
      },
      pagination: {
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by: 'phone',
        order: 'asc',
      },
      lists: [],
      tags: [],
      importOptions: {
        encodedFile: null,
        firstRowHeader: true,
        cols: [
          'phone',
          'body',
        ],
        rows: [],
        body: '',
        results: {
          new: 0,
          existing: 0,
          error: 0
        }
      },
      templates: [
        {template_uuid: '1111', name: 'Template 1', body: 'Hola {{nombres}} {{apellidos}}, para validar tu teléfono {{telefono}} haz click en el siguente link: '},
        {template_uuid: '2222', name: 'Template 2', body: 'Hola {{nombres}}, tienes un crédito preabrobado. Revisa las condiciones en el siguiente link: '},
      ]
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.loadObjects = this.loadObjects.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.openContactDialog = this.openContactDialog.bind(this);
    this.closeContactDialog = this.closeContactDialog.bind(this);
    this.openImportDialog = this.openImportDialog.bind(this);
    this.closeImportDialog = this.closeImportDialog.bind(this);
    this.fileHandler = this.fileHandler.bind(this);
    this.handleImportCheckboxChange = this.handleImportCheckboxChange.bind(this);
    this.handleImportColsChange = this.handleImportColsChange.bind(this);
    this.handleImportSubmit = this.handleImportSubmit.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.addCustomField = this.addCustomField.bind(this);
    this.handleTemplate = this.handleTemplate.bind(this);
    this.handleShorten = this.handleShorten.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePopover = this.handlePopover.bind(this);
  }

  componentDidMount() {
    this.handleLoad();
    this.loadObjects();
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastUpdate !== prevProps.lastUpdate) {
      this.handleLoad();
    }
  }
  
  handleLoad(page = null) {
    var params = {
      page: this.state.pagination.page + 1,
      order_by: this.state.sorting.order_by,
      order: this.state.sorting.order,
      rows_per_page: this.state.pagination.rows_per_page,
    };

    var query = encodeQueryData(params);
    
    authenticationService.fetchApi({
      url: '/campaign/' + this.state.campaign.campaign_uuid + '/contact?' + query,
      method: 'GET',
      api: true
    })
    .then(result => {
      this.setState(prevState => ({
        pagination: {
          ...prevState.pagination,
          rows_num: result.rows_num
        },
        html: {
          ...prevState.html,
          submitting: false,
          contact_dialog: false,
        },
        objects: result.data
      }));
    })
    .catch(error => {

    });
  }

  loadObjects() {
    var params = {
      rows_per_page: 1000
    };

    var query = encodeQueryData(params);
    
    // Lists
    authenticationService.fetchApi({
      url: '/list/?' + query,
      method: 'GET',
      api: true
    })
    .then(result => {
      this.setState({lists: result.data});
    })
    .catch(err => {

    });

    // Tags
    authenticationService.fetchApi({
      url: '/tag/?' + query,
      method: 'GET',
      api: true
    })
    .then(result => {
      this.setState({tags: result.data});
    })
    .catch(err => {

    });
  }

  handleChangePage(event, page) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
      }
    }), () => this.handleLoad());
  }

  handleChangeRowsPerPage(event) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        rows_per_page: event.target.value,
      }
    }), () => this.handleLoad());
  }

  handleSort(event, property) {
    const isAsc = this.state.sorting.order_by === property && this.state.sorting.order === "asc";
    this.setState(prevState => ({
      sorting: {
        ...prevState.sorting,
        order: isAsc ? "desc" : "asc",
        order_by: property,
      }
    }), () => this.handleLoad());
  }

  openContactDialog(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        contact_dialog: true,
      }
    }));
  }

  closeContactDialog(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        contact_dialog: false,
      },
      data: {
        ...prevState.data,
        list_uuid: '',
        tag_uuid: '',
        count: 0,
        body: '',
        cursorPosition: 0,
        template_uuid: '',
        link: '',
      }
    }));
  }

  openImportDialog(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        import_dialog: true,
      },
    }));
  }

  closeImportDialog(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        import_dialog: false,
        import_results: false,
      },
      data: {
        ...prevState.data,
        body: ''
      },
      importOptions: {
        encodedFile: null,
        firstRowHeader: true,
        cols: [
          'phone',
          'body',
        ],
        rows: [],
        results: {
          new: 0,
          existing: 0,
          error: 0
        }
      }
    }));
  }

  toBase64(file) {
    return(
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
  )}

  async fileHandler(event) {
    const fileObj = event.target.files[0];
    const data = await fileObj.arrayBuffer();
    /* data is an ArrayBuffer */
    const wb = read(data, {sheetRows: 4});
    const ws = wb.Sheets[wb.SheetNames[0]];
    const rows = utils.sheet_to_json(ws, {header: 1});

    this.toBase64(fileObj).then(encodedFile => {
      this.setState(prevState => ({
        importOptions: {
          ...prevState.importOptions,
          encodedFile: encodedFile,
          cols: rows[0].map(c => (['Teléfono', 'Telefono', 'phone'].includes(c) ? {id: 'phone', label: 'Teléfono'} : {id: c, label: c})),
          rows: rows.slice(0, 4),
        }
      }));
    });
  }

  handleImportCheckboxChange(event) {
    const {name, checked} = event.target;
    this.setState(prevState => ({
      importOptions: {
        ...prevState.importOptions,
        [name]: checked
      }
    }));
  }

  handleImportColsChange(event, index) {
    const { value } = event.target;
    
    const {importOptions: {cols}} = this.state;
    cols[index] = value;

    this.setState(prevState => ({
      importOptions: {
        ...prevState.importOptions,
        cols
      }
    }));
  }

  handleFilterChange(name, value) {
    if (value) {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          [name]: value[name],
          count: 0
        },
      }));

      var params = {
        [name]: value[name],
        page: this.state.pagination.page + 1,
        rows_per_page: 1,
      };

      var query = encodeQueryData(params);
      
      authenticationService.fetchApi({
        url: '/contact/?' + query,
        method: 'GET',
        api: true
      })
        .then(result => {
          this.setState(prevState => ({
            data: {
              ...prevState.data,
              count: result.rows_num
            },
          }));
        })
        .catch(err => {

        });
    }
    else {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          [name]: '',
          count: 0
        },
      }));
    }
  }

  handleInputChange(event) {
    const { name, value, selectionStart } = event.target;
    
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: value,
        cursorPosition: name === 'body' ? selectionStart : this.state.data.body.length,
      },
    }));
  }

  addCustomField(field) {
    const {cursorPosition, body} = this.state.data;
    let textBeforeCursorPosition = body.substring(0, cursorPosition);
    let textAfterCursorPosition = body.substring(cursorPosition, body.length);

    const newBody = (textBeforeCursorPosition + ' ' + field + ' ' + textAfterCursorPosition)
      .replace('  ', ' ')
      .trim();

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        body: newBody,
        cursorPosition: newBody.length,
      },
    }));
  }

  handleTemplate(event) {
    event.preventDefault();
    const {value} = event.target
   
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        count: 0,
        body: this.state.templates.filter(t => t.template_uuid === value)[0]?.body || '',
        cursorPosition: 0,
        template_uuid: value,
      }
    }))
  }

  handleShorten(event) {
    event.preventDefault();

    const re = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

    if (this.state.data?.link === '' || !this.state.data?.link?.match(re)) {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          link: true,
        },
      }));

      return;
    }

    this.setState(prevState => ({
      html: {
        ...prevState.html,
        submitting: true,
      },
      error: {
        ...prevState.error,
        link: false,
      },
    }));

    authenticationService.fetchApi({
        url: '/short_url/shorten/',
        method: 'POST',
        body: JSON.stringify({
          campaign_uuid: this.state.campaign.campaign_uuid,
          url: this.state.data.link
        }),
        api: true,
    })
    .then((result) => {
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          submitting: false,
        },
        data: {
          ...prevState.data,
          body: (prevState.data.body + ' ' + (result.data?.shortened_url || '')).replace('  ', ' ').trim()
        }
      }));
    })
    .catch(message => {
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          submitting: false,
        },
      }));
    });
  }

  handlePreview(event) {
    event.preventDefault();

    this.setState(prevState => ({
      html: {
        ...prevState.html,
        submitting: true
      },
      importOptions: {
        ...prevState.importOptions,
        body: prevState.data.body,
      }
    }), () => {
      authenticationService.fetchApi({
          url: '/campaign/' + this.state.campaign.campaign_uuid + '/contact_preview',
          method: 'POST',
          body: JSON.stringify(this.state.html.import_dialog ? this.state.importOptions : this.state.data),
          api: true,
      })
      .then((result) => {
        this.setState(prevState => ({
          html: {
            ...prevState.html,
            submitting: false,
            preview_messages: result.messages,
          }
        }));
      })
      .catch(message => {
        this.setState(prevState => ({
          html: {
            ...prevState.html,
            submitting: false,
          },
        }));
      });
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.data?.body === '') {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          body: true,
        },
      }));

      return;
    }

    this.setState(prevState => ({
      html: {
        ...prevState.html,
        submitting: true
      },
    }));

    authenticationService.fetchApi({
        url: '/campaign/' + this.state.campaign.campaign_uuid + '/contact',
        method: 'POST',
        body: JSON.stringify(this.state.data),
        api: true,
    })
    .then((result) => {
      this.handleLoad();
      
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          submitting: false,
          contact_dialog: false,
          preview_messages: [],
        },
        data: {
          ...prevState.data,
          list_uuid: '',
          body: '',
          count: 0,
        }
      }));
    })
    .catch(message => {
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          submitting: false,
        },
      }));
    });
  }

  handleImportSubmit(event) {
    event.preventDefault();

    this.setState(prevState => ({
      html: {
        ...prevState.html,
        submitting: true,
      },
      importOptions: {
        ...prevState.importOptions,
        body: prevState.data.body,
      }
    }), () => {
      authenticationService.fetchApi({
        url: '/campaign/' + this.state.campaign.campaign_uuid + '/contact_import',
        method: 'POST',
        body: JSON.stringify(this.state.importOptions),
        api: true,
        // no_error_popup: true,
      })
      .then(result => {
        this.setState(prevState => ({
          html: {
            ...prevState.html,
            submitting: false,
            preview_messages: [],
            import_results: true,
          },
          data: {
            ...prevState.data,
            body: '',
            count: 0,
          },
          importOptions: {
            ...prevState.importOptions,
            encodedFile: null,
            firstRowHeader: true,
            cols: [
              'phone',
              'body',
            ],
            rows: [],
            body: '',
            results: {
              new: result.new,
              existing: result.existing,
              error: result.error
            }
          }
        }));

        this.handleLoad();
      })
      .catch(message => {
        this.setState(prevState => ({
          html: {
            ...prevState.html,
            submitting: false,
          },
        }));
      });
    });
  }

  handlePopover(event, i) {
    if (!this.state.html.popover_open) {
      const target = event.currentTarget;
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          popover_anchorEl: target,
          popover_open: Boolean(target),
          popover_content: this.state.objects[i]?.message_body || ''
        }
      }));
    }
    else {
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          popover_open:false,
          popover_anchorEl: null,
          popover_content: ''
        }
      }))
    }
  }

  render() {
    return <>
      <Paper>
        <Box mt={3} p={2}>
          <Stack direction="row" gap={1} justifyContent="space-between">
            <Typography variant="h6" color="initial">Mensajes de la campaña</Typography>
            {(new Date()).getTime() < (new Date(this.props.campaign?.delivery_date)) && this.props.campaign?.state === 'CREATED' &&
              <Stack direction="row" gap={1}>
                <Button onClick={this.openImportDialog} color="primary" variant="outlined">
                  Importar contactos
                </Button>
                <Button onClick={this.openContactDialog} color="primary" variant="outlined">
                  Agregar desde listas existentes
                </Button>
              </Stack>
            }
          </Stack>
          {this.state.objects.length > 0 &&
          <Box my={2} pb={2}>
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component="div"
              labelRowsPerPage='Resultados por página'
              count={this.state.pagination.rows_num}
              rowsPerPage={this.state.pagination.rows_per_page}
              page={this.state.pagination.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  order={this.state.sorting.order}
                  orderBy={this.state.sorting.order_by}
                  onRequestSort={this.handleSort}
                />
                <TableBody>
                  {this.state.objects.map((object, i) => {
                    return (
                      <TableRow key={`cic_${i}`}>
                        <TableCell>
                        {object.contact?.first_name ?
                          <Chip icon={<PersonIcon />} size="small" label={`${object.contact?.first_name || ''} ${object.contact?.last_name || ''}`} />
                          :
                          <Chip size="small" label="Sin contacto asociado" sx={{ backgroundColor: '#EEEEEE', color: '#AAAAAA' }} />
                        }
                        </TableCell>
                        <TableCell>
                          {object.phone || object.contact?.phone || '-'}
                        </TableCell>
                        <TableCell>
                          <Button onClick={e => this.handlePopover(e, i)}>
                            <MessageIcon />
                          </Button>
                        </TableCell>
                        <TableCell>
                          {object.message?.status ?
                            <>
                              {sms_status[0].statuses[object.message?.status].type === 0 &&
                                <Chip label={(sms_status[0].statuses[object.message?.status].spanish)} size="small" sx={{ backgroundColor: '#FFDDDD', color: '#884444' }} />
                              }
                              {sms_status[0].statuses[object.message?.status].type === 1 &&
                                <Chip label={(sms_status[0].statuses[object.message?.status].spanish)} size="small" sx={{ backgroundColor: '#FFFFDD', color: '#888844' }} />
                              }
                              {sms_status[0].statuses[object.message?.status].type === 2 &&
                                <Chip label={(sms_status[0].statuses[object.message?.status].spanish)} size="small" sx={{ backgroundColor: '#DDFFDD', color: '#448844' }} />
                              }
                            </>
                            :
                            <Chip label="Pendiente" size="small" />
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Popover
              open={this.state.html.popover_open}
              anchorEl={this.state.html.popover_anchorEl}
              onClose={event=>this.handlePopover(event, false)}
              id={this.state.html.popover_open?'popover_fair':undefined}
              anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
              PaperProps={{style: { maxWidth: '400px' },}}
              >
              <Box p={3}>
                {this.state.html.popover_content}
              </Box>
            </Popover>
          </Box>
          }
          {this.state.objects.length === 0 &&
            <Box pt={2}>
              <Alert severity='warning'>
                Aún no se han agregado contactos a esta campaña
              </Alert>
            </Box>
          }
        </Box>
      </Paper>
      <Dialog open={this.state.html.contact_dialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth>
        <DialogTitle id="form-dialog-title">Agregar contactos a campaña</DialogTitle>
        <DialogContent dividers>
          <form noValidate autoComplete="off"
            onSubmit={this.handleSubmit}
            >
            <Stack direction="column" spacing={2}>
              {/* Recipients */}
              <Paper variant="outlined" sx={{backgroundColor: "#FDFDFD"}}>
                <Stack direction="column" gap={1} p={1}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Avatar sx={{width: 20, height: 20, backgroundColor: '#0066FF'}}>1</Avatar>
                    <Typography variant="overline" color="primary">Elegir lista de destinatarios</Typography>
                  </Stack>
                  <Autocomplete
                    name="lists"
                    options={this.state.lists}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    fullWidth
                    onChange={(event, values) => this.handleFilterChange('list_uuid', values)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Buscar o elegir lista de contactos"
                        placeholder="Buscar o elegir lista de contactos"
                        helperText="Se muestran la listas creadas en el menú 'Contactos'"
                      />
                    )}
                  />
                  <Alert variant="standard" color="info">
                    Se agregarán <strong>{this.state.data.count} contactos</strong> a la campaña.
                  </Alert>
                </Stack>
              </Paper>
              {/* Message content */}
              <Paper variant="outlined" sx={{backgroundColor: "#FDFDFD"}}>
                <Stack direction="column" gap={1} p={1}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Avatar sx={{width: 20, height: 20, backgroundColor: '#0066FF'}}>2</Avatar>
                    <Typography variant="overline" color="primary">Redactar mensaje</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="start" justifyContent="space-between" gap={2}>
                    {/* <FormControl variant="outlined" size="small" sx={{minWidth: 250}}>
                      <InputLabel id="template-label">Utilizar plantilla</InputLabel>
                      <Select
                        name='template_uuid'
                        labelId="template-label"
                        variant="outlined"
                        size="small"
                        value={this.state.data.template_uuid}
                        onChange={this.handleTemplate}
                        label="Utilizar plantilla"
                        >
                        <MenuItem value="">Ninguno</MenuItem>
                        {this.state.templates?.map(t => 
                          <MenuItem key={t.template_uuid} value={t.template_uuid}>{t.name}</MenuItem>
                        )}
                      </Select>
                      <FormHelperText>
                        El uso de plantilla es opcional
                      </FormHelperText>
                    </FormControl> */}
                    <Stack direction="row" alignItems="start" gap={0.4}>
                      <TextField
                        name='link'
                        label="Insertar link"
                        variant="outlined"
                        size="small"
                        value={this.state.data.link}
                        onChange={this.handleInputChange}
                        placeholder="ej. https://www.axonio.dev/"
                        error={this.state.error.link}
                        helperText={this.state.error.link ? 'URL inválida' : 'Se insertará versión acortado'}
                        fullWidth
                      />
                      <Button variant="outlined" color="inherit" onClick={this.handleShorten}>Acortar</Button>
                    </Stack>
                    <Stack direction="row" alignItems="center" alignContent="end" gap={0.4}>
                      <Typography variant="body2">Campos dinámicos:</Typography>
                      <Button size="small" variant="outlined" color="inherit" onClick={() => this.addCustomField('{{nombres}}')}>Nombres</Button>
                      <Button size="small" variant="outlined" color="inherit" onClick={() => this.addCustomField('{{apellidos}}')}>Apellidos</Button>
                      <Button size="small" variant="outlined" color="inherit" onClick={() => this.addCustomField('{{telefono}}')}>Teléfono</Button>
                      <Button size="small" variant="outlined" color="inherit" onClick={() => this.addCustomField('{{custom_id}}')}>Custom_ID</Button>
                    </Stack>
                  </Stack>
                  <TextField
                    name='body'
                    label='Contenido SMS'
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={this.state.data.body}
                    onChange={this.handleInputChange}
                    onKeyUp={this.handleInputChange}
                    onClick={this.handleInputChange}
                    inputProps={{ maxLength: 460 }}
                    error={this.state.error.body}
                    helperText={`${this.state.data.body.length} de un máximo de 460 caracteres, lo que equivale a ${Math.ceil(this.state.data.body.length / 160)} SMS por cada destinatario.`}
                  />
                </Stack>
              </Paper>
              {/* Preview */}
              <Paper variant="outlined" sx={{backgroundColor: "#FDFDFD"}}>
                <Stack direction="column" gap={1} p={1}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Avatar sx={{width: 20, height: 20, backgroundColor: '#0066FF'}}>3</Avatar>
                    <Typography variant="overline" color="primary">Vista previa</Typography>
                  </Stack>
                  {[0, 1, 2].map((m, index) =>
                    <React.Fragment key={`preview_${index}`}>
                      <Typography variant="caption">
                        {this.state.html.preview_messages[m] || '-'}
                      </Typography>
                      {index < 2 && <Divider />}
                    </React.Fragment>
                  )}
                </Stack>
              </Paper>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            startIcon={this.state.html.submitting ? <CircularProgress size={20}/> : null}
            disabled={this.state.data.count === 0 || this.state.data.body.length === 0 || this.state.html.submitting}
            onClick={this.handlePreview}
            >
            Vista Previa
          </Button>
          <Button
            color="primary"
            variant="outlined"
            startIcon={this.state.html.submitting ? <CircularProgress size={20}/> : null}
            disabled={this.state.data.count === 0 || this.state.data.body.length === 0 || this.state.html.submitting}
            onClick={this.handleSubmit}
            >
            Agregar contactos
          </Button>
          <Button color="secondary" onClick={this.closeContactDialog}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Import dialog */}
      <Dialog open={this.state.html.import_dialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth>
        <DialogTitle id="form-dialog-title">Importar contactos</DialogTitle>
        <DialogContent dividers>
          <Stack direction="column" spacing={2}>
            {/* Recipients */}
            <Paper variant="outlined" sx={{backgroundColor: "#FDFDFD"}}>
              <Stack direction="column" gap={1} p={1}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Avatar sx={{width: 20, height: 20, backgroundColor: '#0066FF'}}>1</Avatar>
                  <Typography variant="overline" color="primary">Seleccionar listado de destinatarios</Typography>
                </Stack>
                <TextField
                  type="file"
                  variant="outlined"
                  onChange={this.fileHandler}
                  accept=".xls,.xlsx"
                  fullWidth
                />
                {this.state.importOptions.rows.length === 0 &&
                  <Alert variant="outlined" severity="info">
                    Debes seleccionar un archivo .xls o .xlsx que será previsualizado a continuación. 
                    El campo obligatorio es Teléfono (ej. 56987654321). Opcionalmente es posible incluir una columna con contenido del mensaje o distintas columnas con contenido dinámico.
                  </Alert>
                }
                {this.state.importOptions.rows.length > 0 &&
                  <>
                    <Paper variant="outlined" sx={{p: 2}}>
                      <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                        <Typography variant="body1">Previsalización</Typography>
                        <FormControlLabel control={<Checkbox name="firstRowHeader" checked={this.state.importOptions.firstRowHeader} onChange={this.handleImportCheckboxChange} />} label="Ignorar primera fila" />
                      </Stack>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow key="cols">
                            {this.state.importOptions.cols.map((col, index) =>
                              <TableCell key={`col_${index}`}>
                                <Select
                                  value={col.id}
                                  fullWidth
                                  onChange={(e) => this.handleImportColsChange(e, index)}
                                  size="small"
                                  variant="outlined"
                                >
                                  <MenuItem value=""></MenuItem>
                                  <MenuItem value="phone">Teléfono</MenuItem>
                                  <MenuItem value="body">Mensaje</MenuItem>
                                  {this.state.importOptions.cols.map(c =>
                                    !['Teléfono', 'Telefono', 'phone'].includes(c.id) ? <MenuItem key={`menuitem_${index}_${c.id}`} value={c.id}>{c.label}</MenuItem>
                                    :
                                    undefined
                                  )}
                                </Select>
                              </TableCell>
                            )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.importOptions.rows.map((row, index) => {
                              if (index === 0 && this.state.importOptions.firstRowHeader) {
                                return null;
                              }
                              else if(!row[0]) {
                                return null;
                              }
                              return (
                                <TableRow key={`row_${index}`}>
                                  {row.map((c, i2) => <TableCell key={`c_${index}_${i2}`}>{c || ''}</TableCell>)}
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </>
                }
              </Stack>
            </Paper>
            {/* Message content */}
            <Paper variant="outlined" sx={{backgroundColor: "#FDFDFD"}}>
              <Stack direction="column" gap={1} p={1}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Avatar sx={{width: 20, height: 20, backgroundColor: '#0066FF'}}>2</Avatar>
                  <Typography variant="overline" color="primary">Redactar mensaje</Typography>
                </Stack>
                <Stack direction="row" alignItems="start" justifyContent="space-between" gap={2}>
                  {/* <FormControl variant="outlined" size="small" sx={{minWidth: 250}}>
                    <InputLabel id="template-label">Utilizar plantilla</InputLabel>
                    <Select
                      name='template_uuid'
                      labelId="template-label"
                      variant="outlined"
                      size="small"
                      value={this.state.data.template_uuid}
                      onChange={this.handleTemplate}
                      label="Utilizar plantilla"
                      >
                      <MenuItem value="">Ninguno</MenuItem>
                      {this.state.templates?.map(t => 
                        <MenuItem key={t.template_uuid} value={t.template_uuid}>{t.name}</MenuItem>
                      )}
                    </Select>
                    <FormHelperText>
                      El uso de plantilla es opcional
                    </FormHelperText>
                  </FormControl> */}
                  <Stack direction="row" alignItems="start" gap={0.4}>
                    <TextField
                      name='link'
                      label="Insertar link"
                      variant="outlined"
                      size="small"
                      value={this.state.data.link}
                      onChange={this.handleInputChange}
                      placeholder="ej. https://www.axonio.dev/"
                      error={this.state.error.link}
                      helperText={this.state.error.link ? 'URL inválida' : 'Se insertará versión acortado'}
                      fullWidth
                    />
                    <Button variant="outlined" color="inherit" onClick={this.handleShorten}>Acortar</Button>
                  </Stack>
                  <Stack direction="row" alignItems="center" alignContent="end" gap={0.4}>
                    <Typography variant="body2">Campos dinámicos:</Typography>
                    {this.state.importOptions.cols.map((c, index) =>
                      <Button
                        key={`button_${index}`}
                        size="small"
                        variant="outlined"
                        color="inherit"
                        onClick={() => this.addCustomField(`{{${this.state.importOptions.firstRowHeader ? c.id : (index + 1)}}}`)}>
                        {this.state.importOptions.firstRowHeader ? c.label : (index + 1)}
                      </Button>
                    )}
                  </Stack>
                </Stack>
                <TextField
                  name='body'
                  label='Contenido SMS'
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={this.state.data.body}
                  onChange={this.handleInputChange}
                  onKeyUp={this.handleInputChange}
                  onClick={this.handleInputChange}
                  inputProps={{ maxLength: 460 }}
                  error={this.state.error.body}
                  helperText={`${this.state.data.body.length} de un máximo de 460 caracteres, lo que equivale a ${Math.ceil(this.state.data.body.length / 160)} SMS por cada destinatario.`}
                />
              </Stack>
            </Paper>
            {/* Preview */}
            <Paper variant="outlined" sx={{backgroundColor: "#FDFDFD"}}>
              <Stack direction="column" gap={1} p={1}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Avatar sx={{width: 20, height: 20, backgroundColor: '#0066FF'}}>3</Avatar>
                  <Typography variant="overline" color="primary">Vista previa</Typography>
                </Stack>
                {[0, 1, 2].map((m, index) =>
                  <React.Fragment key={`preview_${index}`}>
                    <Typography variant="caption">
                      {this.state.html.preview_messages[m] || '-'}
                    </Typography>
                    {index < 2 && <Divider />}
                  </React.Fragment>
                )}
              </Stack>
            </Paper>
            {this.state.html.import_results &&
              <Alert variant="filled" severity="info">
                Se importaron <b>{this.state.importOptions.results.new}</b> nuevos contactos, ignorando <b>{this.state.importOptions.results.existing}</b> existentes y <b>{this.state.importOptions.results.error}</b> filas con errores.
              </Alert>
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            startIcon={this.state.html.submitting ? <CircularProgress size={20}/> : null}
            disabled={this.state.importOptions.rows.length === 0 || this.state.data.body.length === 0 || this.state.html.submitting}
            onClick={this.handlePreview}
            >
            Vista Previa
          </Button>
          <Button
            color="primary"
            variant="outlined"
            startIcon={this.state.html.submitting ? <CircularProgress size={20}/> : null}
            disabled={this.state.html.submitting}
            onClick={this.handleImportSubmit}
            >
            Confirmar e importar
          </Button>
          <Button color="secondary" onClick={this.closeImportDialog}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>;
  }
}

function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

export default CampaignContacts;