import React from "react";
import Layout from 'layout/index.js'
import { Link } from 'react-router-dom';
import { authenticationService } from 'auth/authenticationService'
import {
  Paper, Box, Typography, TextField, Button,
  TableSortLabel, TablePagination, TableContainer,
  Table, TableRow, TableHead, TableBody, TableCell,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const headCells = [
  { id: "name",numeric: false,disablePadding: false,label: "Nombre de la empresa", sorting: true},
  { id: "created_at", numeric: false, disablePadding: false, label: "Fecha creación", sorting: true},
  { id: "username", numeric: false, disablePadding: false, label: "Usuarios", sorting: true},
  { id: "count_message", numeric: false, disablePadding: false, label: "Mensajes enviados", sorting: true},
  { id: "view", numeric: false, disablePadding: false, label: "Ver detalles de la empresa", sorting: false}
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          !headCell.sorting ? <TableCell key={headCell.id}>{headCell.label}</TableCell> : (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className='hideSort'>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

function encodeQueryData(data) {
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
}

class CompaniesAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      objects: [],

      html: {
        page: 1,
        list: 'display-none',
      },
      filters: {
        name:'',
      },
      pagination:{
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by:'created_at',
        order:'desc',
      }
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  handleChangePage(event, page){
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
          page: page,
      }}), ()=> this.handleLoad());

  }
  handleChangeRowsPerPage(event){
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
          rows_per_page: event.target.value,
      }}), ()=> this.handleLoad());
  }

  handleSort(event, property){
    const isAsc = this.state.sorting.order_by === property && this.state.sorting.order === "asc";
    this.setState(prevState => ({
      sorting: {
          ...prevState.sorting,
          order: isAsc ? "desc" : "asc",
          order_by: property,
    }}),()=>this.handleLoad()
    );
  }

  handleFilter(event){
    const {name, value} = event.target

    let filters = this.state.filters

    filters[name] = value
    this.setState({filters:filters},()=>{
    });


    // this.state.filters.name = value
    // this.setState(prevState => ({
    //   filters: {
    //     ...prevState.filters,
    //       name: value,
    //   }}));
  }

  handleLoad(page=null){

    var params = {
        page: this.state.pagination.page+1,
        order_by: this.state.sorting.order_by,
        order: this.state.sorting.order,
        rows_per_page: this.state.pagination.rows_per_page,
    };
    if(this.state.filters.name!==''){
      params['name'] = this.state.filters.name;
    }

    var query = encodeQueryData(params)

    authenticationService.fetchApi({
      url: '/company/?' + query,
      method: 'GET',
    })
    .then(result=>{
      this.setState(prevState => ({
        pagination: {
            ...prevState.pagination,
            rows_num: result.rows_num
        }
      }));

      this.setState(prevState => ({html:{...prevState.html,list:'display-inline'}}));
      this.setState({objects:result.data});

    });
  }

  componentDidMount(){
    this.handleLoad();
    // console.log(this.props)
  }

  render() {
    // console.log(this.props);
    // const { params } = this.props.match


    return (
      <Layout title='Empresas'>
        <Paper>
          <Box p={1} m={1}>
  {
  ////////////////////////////////////
  }
            <Box p={1}>
              <Typography variant='h6'>
                Filtros
              </Typography>
              <Box p={1}>
                <TextField  name='name'
                            id='name'
                            onChange={this.handleFilter}
                            value={this.state.filters.name}
                            label='Nombre empresa'
                            placeholder='Ingresa un nombre'
                            fullWidth={true}
                />
              </Box>
              <Box p={1} align='right'>
                <Button color='primary' onClick={this.handleLoad}>Aplicar Filtros</Button>
              </Box>
            </Box>
  {
    ////////////////////////////////
  }
            <TablePagination
              rowsPerPageOptions={[5, 10, 50, 100]}
              component="div"
              labelRowsPerPage='Resultados por página'
              count={this.state.pagination.rows_num}
              rowsPerPage={this.state.pagination.rows_per_page}
              page={this.state.pagination.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  order={this.state.sorting.order}
                  orderBy={this.state.sorting.order_by}
                  onRequestSort={this.handleSort}
                />
                <TableBody>
                {this.state.objects.length>0 && this.state.objects.map((object) => {
                  return(
                    <TableRow key={object.company_uuid}>
                      <TableCell>
                        {object.name}
                      </TableCell>
                      <TableCell>
                        {(new Date(object.created_at)).toLocaleString('es-CL')}
                      </TableCell>
                      <TableCell>
                        {object.username}
                      </TableCell>
                      <TableCell>
                        {object.count_message}
                      </TableCell>
                      <TableCell>
                        <Button component={Link} color='primary' startIcon={<SearchIcon />} to={`/admin/companies/${object.company_uuid}`}>Ver Detalles</Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Layout>
    );
  }
}

export default CompaniesAdmin;
