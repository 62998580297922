import React from "react";
import { Link, withRouter } from 'react-router-dom';
import {
  Box, Paper, Grid, Button, Typography, TextField,
  Alert, Stack, CircularProgress, Chip, Tooltip,
  IconButton, Switch
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';
import Layout from 'layout/index.js';
import { authenticationService } from 'auth/authenticationService';
import CampaignContacts from "./CampaignContacts";
import jsonData from './campaign_status.json';
const campaign_status = jsonData;


class Campaign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        campaign_uuid: '',
        name: '',
        delivery_date: null,
        state: '',
        auto_queue: false,
      },
      html: {
        form_display: false,
        list_display: true,
        contact_dialog: false,
        submitting: false,
        error_message: '',
        last_update: 0,
      },
      delay: 10000,
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitCampaign = this.handleSubmitCampaign.bind(this);
    this.handleEditForm = this.handleEditForm.bind(this);
    this.handleCancelEdit = this.handleCancelEdit.bind(this);
    this.openContactDialog = this.openContactDialog.bind(this);
    this.closeContactDialog = this.closeContactDialog.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.handleLoad();
    this.interval = setInterval(this.tick, this.state.delay);
  }

  handleLoad() {
    if (this.props.match.params.campaign_uuid !== 'new') {
      authenticationService.fetchApi({
        url: '/campaign/' + this.props.match.params.campaign_uuid,
        method: 'GET',
        api: true,
      })
      .then(result => {
        this.setState(prevState => ({
          data: result,
          html: {
            ...prevState.html,
            form_display: false,
            list_display: true,
            submitting: false,
            error_message: '',
            last_update: prevState.html.last_update + 1,
          }
        }));
      })
      .catch(error => {

      });
    }
    else {
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          form_display: true,
          list_display: false,
          submitting: false,
        }
      }));
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () => {
    if (moment().isAfter(moment(this.state.data?.delivery_date)) && moment().isBefore(moment(this.state.data.delivery_date).add(2, 'hours'))) {
      this.handleLoad();
    }
  }

  handleDateChange(date = null) {
    const { data } = { ...this.state };
    const currentState = data
    currentState['delivery_date'] = date;
    this.setState({ data: currentState });
  }

  handleInputChange(event) {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value, checked } = event.target;
    currentState[name] = event.target.type === 'checkbox' ? checked : value;
    this.setState({ data: currentState });
  }

  handleCancelEdit(event) {
    event.preventDefault();
    this.setState({
      html: {
        form_display: false,
        list_display: true,
      }
    }, () => this.handleLoad());
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.data?.name === '' || this.state.data?.delivery_date === '') {
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          error_message: 'Todos los campos son requeridos'
        },
      }));

      return;
    }
    else if (this.state.data?.delivery_date !== '' && (new Date()).getTime() > (new Date(this.state.data.delivery_date))) {
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          error_message: 'Fecha y hora de envío debe ser mayor que actual'
        },
      }));

      return;
    }

    this.setState(prevState => ({
      html: {
        ...prevState.html,
        submitting: true,
        error_message: ''
      },
    }));

    authenticationService.fetchApi({
        url: '/campaign/',
        method: 'POST',
        body: JSON.stringify(this.state.data),
        api: true,
        no_error_popup: true,
    })
    .then((result) => {
      if (this.state.data.campaign_uuid) {
        this.handleLoad();
      }
      else {
        this.props.history.push('/sms/campaigns/' + result.campaign_uuid);
        this.setState(prevState => ({
          data: result,
          html: {
            ...prevState.html,
            form_display: false,
            list_display: true,
            submitting: false
          }
        }));
      }
    })
    .catch(err => {
      const error_message = err.data?.request_errors?.includes('INVALID_DELIVERY_DATE') ? 'Favor especificar una fecha y hora válida, al menos 5 minutos mayor que la actual' : 'Ocurrió un error. Favor intente de nuevo';

      this.setState(prevState => ({
        html: {
          ...prevState.html,
          submitting: false,
          error_message
        },
      }));
    });
  }

  handleSubmitCampaign(event) {
    event.preventDefault();

    this.setState(prevState => ({
      html: {
        ...prevState.html,
        submitting: true
      },
    }));

    authenticationService.fetchApi({
        url: `/campaign/${this.state.data.campaign_uuid}/send`,
        method: 'POST',
        body: {},
        api: true,
        no_error_popup: true,
    })
    .then((result) => {
      this.handleLoad();
    })
    .catch(err => {
      const error_message = err.data?.request_errors?.includes('NO_MESSAGES') ? 'No se han agregado contactos a la campaña' : 'Ocurrió un error. Favor intente de nuevo';

      this.setState(prevState => ({
        html: {
          ...prevState.html,
          submitting: false,
          error_message
        },
      }));
    });
  }

  handleEditForm(event, data = null) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        form_display: true,
        list_display: false,
      }
    }));

    event.preventDefault();
  }

  openContactDialog(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        contact_dialog: true,
      }
    }));
  }

  closeContactDialog(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        contact_dialog: false,
      }
    }));
  }

  handleFilterChange(name, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [name]: value ? value[name] : '',
      },
      pagination: {
        ...prevState.pagination,
        page: 0
      }
    }), () => this.handleLoad());
  }

  render() {
    return (
      <Layout title="Campañas">
        <Box py={1}>
          <Button startIcon={<ArrowBackIcon/>} component={Link} to='/sms/campaigns/' color='primary'> Volver a la lista de campañas</Button>
        </Box>
        <Paper>
          <Box p={2}>
            {this.state.html.form_display &&
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField  name='name'
                              id='name'
                              label='Nombre de la campaña'
                              className='form-control'
                              fullWidth={true}
                              value={this.state.data.name}
                              required
                              onChange={this.handleInputChange} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateTimePicker name='delivery_date'
                                  id='delivery_date'
                                  label='Fecha programada de envío'
                                  ampm={false}
                                  onChange={this.handleDateChange}
                                  // defaultValue="2017-05-24T10:30"
                                  value={this.state.data.delivery_date}
                                  variant='inline'
                                  disablePast
                                  format="DD-MM-YYYY, HH:mm:ss"
                                  renderInput={(props) => <TextField {...props} required fullWidth />}
                                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='overline'>Auto encolar campaña</Typography>
                  <Tooltip title="Si está desactivado la campaña no se enviará hasta que el usuario confirme el envío manualmente, incluso si se cumple la fecha de envío.">
                    <IconButton size="small" aria-label="delete">
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                  <Box>
                    <Switch checked={this.state.data.auto_queue}
                      onChange={this.handleInputChange}
                      color="primary"
                      name="auto_queue"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Box>
                </Grid>
              </Grid>
              {this.state.html.error_message !== '' &&
                <Box pt={2}>
                  <Alert severity='error'>
                    {this.state.html.error_message}
                  </Alert>
                </Box>
              }

              <Box pt={2}>
                <Box display="inline" mr={1}>
                  <Button
                    onClick={this.handleSubmit}
                    color='primary'
                    variant='contained'
                    startIcon={this.state.html.submitting ? <CircularProgress size={20}/> : null}
                    disabled={this.state.html.submitting}>
                      Guardar cambios
                  </Button>
                </Box>
                <Box display="inline">
                  <Button
                    onClick={this.handleCancelEdit}
                    color='primary'
                    startIcon={this.state.html.submitting ? <CircularProgress size={20}/> : null}
                    disabled={this.state.html.submitting}>
                    Descartar cambios
                  </Button>
                </Box>
              </Box>
            </>
            }
            {this.state.html.list_display && this.state.data && <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant='overline' color="textSecondary">Nombre de la campaña</Typography>
                  <Typography variant="body1">
                    {this.state.data.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant='overline' color="textSecondary">campaign_uuid</Typography>
                  <Typography variant="body1">
                    {this.state.data.campaign_uuid}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="overline" color="textSecondary">Fecha programada de envío</Typography>
                  <Typography variant="body1">
                    {(new Date(this.state.data.delivery_date)).toLocaleString('es-CL')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="overline" color="textSecondary">Estado</Typography>
                  <Box>
                    {this.state.data?.state ?
                      <>
                        {campaign_status[0].statuses[this.state.data.state].type === 0 &&
                          <Chip label={(campaign_status[0].statuses[this.state.data.state].spanish)} size="small" style={{ backgroundColor: '#FFDDDD', color: '#884444' }} />
                        }
                        {campaign_status[0].statuses[this.state.data.state].type === 1 &&
                          <Chip label={(campaign_status[0].statuses[this.state.data.state].spanish)} size="small" style={{ backgroundColor: '#FFFFDD', color: '#888844' }} />
                        }
                        {campaign_status[0].statuses[this.state.data.state].type === 2 &&
                          <Chip label={(campaign_status[0].statuses[this.state.data.state].spanish)} size="small" style={{ backgroundColor: '#DDFFDD', color: '#448844' }} />
                        }
                      </>
                      :
                      <Chip label="Pendiente" size="small" />
                    }
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="overline" color="textSecondary">Auto-encolar</Typography>
                  <Box>
                    {this.state.data?.auto_queue ? "Si" : "No"}
                  </Box>
                </Grid>
              </Grid>
              <Stack gap={1}>
                <Stack direction="row" gap={1} pt={2}>
                  {/* SCHEDULE */}
                  {(
                    !this.state.data?.auto_queue &&
                    this.state.data.state === 'CREATED' &&
                    moment().isBefore(moment(this.state.data.delivery_date).subtract(5, 'minutes'))
                  ) &&
                    <Button
                      color='primary'
                      variant="contained"
                      startIcon={this.state.html.submitting ? <CircularProgress size={20}/> : <SendIcon />}
                      disabled={this.state.html.submitting}
                      onClick={this.handleSubmitCampaign}>
                      Programar envío
                    </Button>
                  }
                  {/* EDIT */}
                  {(
                    this.state.data.state === 'CREATED' ||
                    (this.state.data.state === 'INITIATED' && moment().isBefore(moment(this.state.data.delivery_date).subtract(5, 'minutes')))
                  ) &&
                    <Button onClick={this.handleEditForm} color='primary' variant="outlined" startIcon={<EditIcon />}>Editar</Button>
                  }
                  <Button component={Link} color='primary' startIcon={<SearchIcon />} to={`/sms/outgoing/campaign_uuid=${this.state.data.campaign_uuid}`} variant="outlined">Ver SMS en campaña</Button>
                </Stack>
                {this.state.data.state !== 'CREATED' && (new Date()).getTime() > (new Date(this.state.data.delivery_date)) &&
                  <Alert severity='info'>
                    Esta campaña no se puede modificar dado que ya superó la fecha de envío y el estado de envío cambió.
                  </Alert>
                }
                {this.state.html.error_message !== '' &&
                  <Alert severity='error'>
                    {this.state.html.error_message}
                  </Alert>
                }
              </Stack>
            </>}
          </Box>
        </Paper>
        {this.state.html.list_display && this.state.data.campaign_uuid &&
          <CampaignContacts campaign={this.state.data} lastUpdate={this.state.html.last_update} />
        }
      </Layout>
    );
  }
}

// function encodeQueryData(data) {
//   const ret = [];
//   for (let d in data)
//     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
//   return ret.join('&');
// }

export default withRouter(Campaign);