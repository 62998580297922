import React from "react";

import { authenticationService } from 'auth/authenticationService'
// import NumberFormat from 'react-number-format';
import {
  Grid, Box, Typography, Alert,
  TextField, Button, Switch, FormControlLabel, IconButton,
  Dialog, DialogContent, DialogActions, ClickAwayListener, Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
// import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import MessageIcon from '@mui/icons-material/Message';
import PhoneIcon from '@mui/icons-material/Phone';

import {PhoneNumberField, PhoneNumber, countriesService} from 'adds/PhoneNumber.js'


class SandboxPhone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        form: false,
        view: true,
        copy_tooltip: false,
      },
      data:{
        company_uuid:'',
        sandbox_phone:'',
        sandbox_validated_phone:null,
        sandbox_balance:'',
      },
      status:{
        sandbox_phone:true
      },
      dialog: {
        open: false,
        error_message: '',
        success_message: '',
        token_input: false,
      },
      sandbox_token: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditForm = this.handleEditForm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleOpenValidatePhone = this.handleOpenValidatePhone.bind(this);
    this.handleCloseValidatePhone = this.handleCloseValidatePhone.bind(this);
    this.handleValidatePhone = this.handleValidatePhone.bind(this);
    this.copyCredential = this.copyCredential.bind(this);
    this.handleTooltipClose = this.handleTooltipClose.bind(this);
  }


  handleLoad() {
    return (
      authenticationService.fetchApi({
        url: '/company/sms_admin/' + this.state.data.company_uuid,
        method: 'GET',
      })
      .then(result => {
        this.setState(prevState => ({
          data: {
            company_uuid:result.company_uuid,
            sandbox_phone:result.sandbox_phone?result.sandbox_phone.toString():'',
            sandbox_validated_phone:result.sandbox_validated_phone?result.sandbox_validated_phone.toString():'',
            sandbox_balance:result.sandbox_balance,
            sandbox_credential:result.sandbox_credential,
          },
          html: {
            ...prevState.html,
            view: true,
            form: false,
          }
        }));
        return (true);
      })
    );
  }

  componentDidMount() {
    if('sandbox' in this.props){
      this.setState({
        data: this.props.sandbox
      });
    }
    else{
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          company_uuid: authenticationService.currentUser.person_object.company_object.company_uuid
        }
      }), () => this.handleLoad());
    }
  }

  handleInputChange(event) {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value, checked } = event.target;

    if(name === 'sandbox_phone'){
      
      this.setState(prevState=>({
        status:{
          ...prevState.status,
          sandbox_phone: countriesService.validatePhone(value)
        }
      }))
      
    }
    currentState[name] = value;

    this.setState({ data: currentState });
  }

  handleSubmit(event) {
    event.preventDefault();

    authenticationService.fetchApi({
      url: '/company/sms_admin/',
      method: 'POST',
      body: JSON.stringify(this.state.data),
    })
    .then(() => {
      authenticationService.refresh().then(
        this.handleOpenValidatePhone()
      )
    });
  }

  handleEditForm(event) {
    event.preventDefault();
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        form: true,
        view: false,
      }
    }));
  }

  handleCancel(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        form: false,
        view: true,
      }
    }),()=>{this.handleLoad()});
  }

  handleOpenValidatePhone() {
    authenticationService.fetchApi({
      url: '/company/validate_phone/?sandbox_phone='+this.state.data.sandbox_phone,
      method: 'GET',
    })
    .then((result) => {
      if ('error' in result) {
        this.setState(prevState => ({
          dialog: {
            ...prevState.dialog,
            open: true,
            token_input: false,
            error_message: 'Hubo un problema en la operación'
          }
        }));
      }
      else {
        this.setState(prevState => ({
          dialog: {
            ...prevState.dialog,
            open: true,
            error_message: '',
            token_input: true,
          }
        }));
      }
    });
  }

  handleCloseValidatePhone() {
    this.setState(prevState => ({
      dialog: {
        ...prevState.dialog,
        open: false,
        error_message: '',
        token_input: false,
      }
    }));
  }

  handleValidatePhone(event) {
    event.preventDefault();

    if (this.state.sandbox_token !== '') {
      event.preventDefault();
      authenticationService.fetchApi({
        url: '/company/validate_phone/',
        method: 'POST',
        body: JSON.stringify({ sandbox_token: this.state.sandbox_token }),
      })
      .then((result) => {
        if ('error' in result) {
          this.setState(prevState => ({
            dialog: {
              ...prevState.dialog,
              token_input: true,
              error_message: "Código inválido",
            }
          }));
        }
        else {
          this.setState(prevState => ({
            dialog: {
              ...prevState.dialog,
              open: false,
              token_input: false,
              error_message: "",
              success_message: "Número de teléfono validado"
            }
          })
            , () => {
              authenticationService.refresh().then(
                this.handleLoad()
              )
            }
          );
        }
      });
    }
  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  copyCredential(){
    var credential = this.state.data.sandbox_credential?.token
    console.log(this.state.data)
    navigator.clipboard.writeText(credential)

    this.setState(prevState=>({
      html:{...prevState.html,
        copy_tooltip: true
      }
    }),()=>{
      this.sleep(1000).then(()=>{
        this.setState(prevState=>({
          html:{...prevState.html,
            copy_tooltip: false
          }
        }))}
      );
    })

  }
  handleTooltipClose(){
    this.setState(prevState=>({
      html:{...prevState.html,
        copy_tooltip: false
      }
    }))
  }

  render() {
    return (
      <Box>
        {this.state.html.form &&
          <form noValidate autoComplete="off">
            <Box>
              <PhoneNumberField name='sandbox_phone'
                id='sandbox_phone'
                fullWidth={true}
                label='Teléfono para pruebas SMS'
                value={this.state.data.sandbox_phone?this.state.data.sandbox_phone:''}
                onChange={this.handleInputChange} 
                error={!this.state.status.sandbox_phone}
                helperText={this.state.status.sandbox_phone?'':"Número inválido"}
                />
            </Box>
          
            {this.state.data.sandbox_phone && this.state.data.sandbox_phone!='' &&
            <Box p={1}>
              {(
                (this.state.data.sandbox_validated_phone !== this.state.data.sandbox_phone) && 
                (this.state.data.sandbox_phone !== null && this.state.data.sandbox_phone !== '')) &&
                (countriesService.validatePhone(this.state.data.sandbox_phone)) ?
                <>
                  <Button color='primary' 
                          onClick={this.handleOpenValidatePhone}
                          variant='outlined'
                          startIcon={<MessageIcon/>}>
                    Validar Teléfono
                  </Button>
                </>:<></>
                
              }
              
              
            </Box>
            }
            <Box p={1}>
              <Button onClick={this.handleCancel} 
                          color='secondary'
                          startIcon={<CancelIcon />}>
                            Cancelar
              </Button>
            </Box>
            
          </form>
        }
        {this.state.html.view &&
          <>
            <Box>
                {this.state.data.sandbox_phone ? 
                  <Box>

                    <Typography color="textSecondary" variant="overline">
                      Teléfono validado para pruebas
                    </Typography>
                    <Box>
                      <Box sx={{color:"#0066ff", fontWeight:"bold",display:"inline"}}>
                        <PhoneNumber value={this.state.data.sandbox_phone} color='primary'/>
                      </Box>
                      <IconButton onClick={this.handleEditForm} 
                                  color='primary' 
                                  variant="contained">
                        <EditIcon />
                      </IconButton>
                    </Box>

                    <Box sx={{display:'inline', color:'#888888', fontSize:'1em'}} pt={1}>
                      Ingresando un teléfono de pruebas podrás testear gratis todas las funcionalidades de SMS. <br/>
                    </Box>
                    <Box sx={{display:'inline', color:'#aaaaaa', fontSize:'1em'}}>
                      Tienes un balance de <Box sx={{display:'inline', color:'#0066ff'}}>{this.state.data.sandbox_balance}</Box> mensajes sms gratis.
                    </Box>
                    <Box pt={2}>
                      <ClickAwayListener onClickAway={this.handleTooltipClose}>
                        <>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={this.handleTooltipClose}
                            open={this.state.html.copy_tooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copiada"
                          >
                            <Button onClick={this.copyCredential} 
                                    color="primary" 
                                    variant='outlined' 
                                    size='small'>
                              Copiar credencial de pruebas
                            </Button>
                          </Tooltip>
                        </>
                      </ClickAwayListener>
                    </Box>

                  </Box>
                        : 
                  <Box>
                    <Box sx={{display:'inline', color:'#888888', fontSize:'1em'}} pt={1}>
                      Ingresando un teléfono de pruebas podrás testear gratis todas las funcionalidades de SMS. <br/>
                      ¡Tienes <Box sx={{display:'inline', color:'#0066ff'}}>{this.state.data.sandbox_balance}</Box> mensajes gratis!
                    </Box>
                    <Box p={2} align='center'>
                      <Button onClick={this.handleEditForm} color='primary' variant="outlined" startIcon={<PhoneIcon/>}>
                        Ingresar teléfono
                      </Button>
                    </Box>
                  </Box>
                }
              
            </Box>
          </>
        }
        <Dialog open={this.state.dialog.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            {this.state.dialog.token_input &&
              <Box>
                <Typography variant="h6">
                  Validación de teléfono
                </Typography>
                <Box p={1}>
                  <Typography variant="body2">
                    Ingresa el código de validación que envíamos a tu teléfono
                  </Typography>
                </Box>
                <Box p={1}>
                  <TextField name='sandbox_token'
                    id='sandbox_token'
                    className='form-control'
                    fullWidth={true}
                    label='Código de validación'
                    value={this.state.sandbox_token}
                    onChange={(event) => this.setState({ sandbox_token: event.target.value })} />
                </Box>
                <Box p={1}>
                  <Button onClick={(event) => this.handleValidatePhone(event)} color="primary" variant='contained'>
                    Continuar
                  </Button>
                </Box>
              </Box>
            }
            {this.state.dialog.error_message !== '' &&
              <Box>
                <Alert severity='error'>{this.state.dialog.error_message}</Alert>
              </Box>
            }
            {this.state.dialog.success_message !== '' &&
              <Box>
                <Alert severity='success'>{this.state.dialog.success_message}</Alert>

                <Button onClick={() => this.handleCloseValidatePhone()} color="secondary">
                  Salir
                </Button>
              </Box>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleCloseValidatePhone()} color="secondary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default SandboxPhone;