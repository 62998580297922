import React from "react";
import Layout from 'layout/index.js'
import { withRouter, Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { authenticationService } from 'auth/authenticationService'
import {
  Paper, Box, Alert,
  Button, Typography,
  TextField, Switch,
  TableSortLabel, TablePagination, TableContainer,
  Table, TableRow, TableHead, TableBody, TableCell,
  Card, CardActionArea, CardContent, CardActions,
  Divider, Grid, IconButton, Tooltip,
  Autocomplete, ToggleButtonGroup, ToggleButton
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ClearIcon from "@mui/icons-material/Clear";
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import SearchIcon from '@mui/icons-material/Search';

const headCells = [
  { id: "created_at", numeric: false, disablePadding: false, label: "Fecha de creación", sorting: true },
  { id: "network", numeric: false, disablePadding: false, label: "Red", sorting: true },
  { id: "provider", numeric: false, disablePadding: false, label: "Proveedor", sorting: true },
  { id: "src_phone", numeric: false, disablePadding: false, label: "Número de origen", sorting: true },
  { id: "dst_phone", numeric: false, disablePadding: false, label: "Número de destino", sorting: true },
  { id: "view", numeric: false, disablePadding: false, label: "Detalles SMS", sorting: false }
];

function download(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          !headCell.sorting ? <TableCell key={headCell.id}>{headCell.label}</TableCell> : (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className='hideSort'>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}


function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

class Messages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      objects: [],
      statistics: [],

      html: {
        page: 1,
        list: 'display-none',
        view_type: 'cards',
      },
      filters: {
        no_campaigns: false,
        campaigns: [],
        credentials: [],
        start_date: null,
        end_date: null,
      },
      pagination: {
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by: 'created_at',
        order: 'desc',
      },
      company: null,
      companies: []
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleViewType = this.handleViewType.bind(this)
    this.handleLoadCompanies = this.handleLoadCompanies.bind(this)
    this.handleCredentialFilter = this.handleCredentialFilter.bind(this)
    this.handleCampaignFilter = this.handleCampaignFilter.bind(this)
    this.handleSwitchFilter = this.handleSwitchFilter.bind(this)
    this.handleDateFilter = this.handleDateFilter.bind(this)
    this.handleCSVDownload = this.handleCSVDownload.bind(this)
    this.resetDate = this.resetDate.bind(this)
  }

  handleChangePage(event, page) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
      }
    }), () => this.handleLoad());

  }
  handleChangeRowsPerPage(event) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        rows_per_page: event.target.value,
      }
    }), () => this.handleLoad());
  }

  handleSort(event, property) {
    const isAsc = this.state.sorting.order_by === property && this.state.sorting.order === "asc";
    this.setState(prevState => ({
      sorting: {
        ...prevState.sorting,
        order: isAsc ? "desc" : "asc",
        order_by: property,
      }
    }), () => this.handleLoad()
    );

  }


  handleLoad(page = null) {


    var params = {
      page: this.state.pagination.page + 1,
      order_by: this.state.sorting.order_by,
      order: this.state.sorting.order,
      rows_per_page: this.state.pagination.rows_per_page,
      statistics: true,
    };

    if (this.state.company) {
      params['company_uuid'] = this.state.company.company_uuid;
    }

    if (Object.keys(this.state.filters).length !== 0) {
      params['filters'] = this.state.filters;
    }

    // var query = encodeQueryData(params)

    authenticationService.fetchApi({
      url: '/message/',
      method: 'POST',
      body: JSON.stringify(params)
    })
      .then(result => {
        this.setState(prevState => ({
          pagination: {
            ...prevState.pagination,
            rows_num: result.rows_num
          }
        }));

        this.setState(prevState => ({ html: { ...prevState.html, list: 'display-inline' } }));
        this.setState({ objects: result.data });
        this.setState({ statistics: result.statistics });

      });
  }

  /////////////////////
  // FILTERS
  getObjects() {
    return (
      authenticationService.fetchApi({
        url: '/message_filters/' + this.state.company.company_uuid,
        method: 'GET',
      })
        .then(result => {
          this.setState({ credentials: result.credentials });
          this.setState({ campaigns: result.campaigns });
        })
    )
  }

  handleCredentialFilter(values) {
    let values_array = []
    values.map(value => { values_array = values_array.concat(value.credential_uuid); return true; })
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        credential: values_array,
      }
    }), () => this.handleLoad());
  }
  handleCampaignFilter(values) {
    let values_array = []
    values.map(value => { values_array = values_array.concat(value.campaign_uuid); return true; })
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        campaign: values_array,
      }
    }), () => this.handleLoad());
  }

  handleSwitchFilter(event) {
    // const { filters } = { ...this.state };
    // const currentState = filters;
    let { checked } = event.target;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        // [name]: checked,
        no_campaigns: checked,
        campaigns: []
      }
    }), () => {
      this.handleLoad()
    });
  }
  handleDateFilter(name, date = null) {
    const { filters } = { ...this.state };
    const currentState = filters
    // console.log(date)
    // console.log(name)
    currentState[name] = date;
    this.setState({ filters: currentState }, () => this.handleLoad());

  }
  resetDate(name) {
    const { filters } = { ...this.state };
    const currentState = filters
    currentState[name] = null;
    this.setState({ filters: currentState }, () => this.handleLoad());

  }
  // END FILTERS
  /////////////////////////////////

  componentDidMount() {
    let groups = authenticationService.currentUser.groups;
    let is_company = ['company'].filter(x => groups.includes(x)).length > 0;

    if (is_company) {
      this.setState({ company: authenticationService.currentUser.person_object.company_object.company_uuid }, () => this.getObjects())
    }
    this.handleLoad();
  }

  handleViewType(event, newAlignment) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        view_type: newAlignment
      }
    }));
  }

  handleLoadCompanies(event, value = null) {
    event.preventDefault();
    let params = {
      page: 1,
      rows_per_page: 10,

    }
    if (value) {
      params['name'] = value
    }
    var query = encodeQueryData(params)

    authenticationService.fetchApi({
      url: '/company/?' + query,
      method: 'GET',
    })
      .then(result => {
        // console.log(result);
        this.setState({ companies: result.data });
      });
  }

  handleCompanyChange(event, value, reason) {
    if (value) {
      this.setState({ company: value }, () => {
        this.getObjects();
        this.handleLoad();
      });
    }
    else {

      this.setState({ company: null }, () => {
        this.setState({ filters: {} });
        this.handleLoad();
      });
    }
  }

  handleCSVDownload(event) {
    var params = {
      page: this.state.pagination.page + 1,
      order_by: this.state.sorting.order_by,
      order: this.state.sorting.order,
      rows_per_page: this.state.pagination.rows_per_page,
      statistics: true,
      csv: true,
    };

    if (this.state.company) {
      params['company_uuid'] = this.state.company.company_uuid;
    }

    if (Object.keys(this.state.filters).length !== 0) {
      params['filters'] = this.state.filters;
    }

    authenticationService.fetchApi({
      url: '/message/',
      method: 'POST',
      body: JSON.stringify(params),
      ContentType: 'csv',
    })
      .then(blob => {
        // var curTime = new Date().toLocaleString();
        download(blob, "SMS_" + Math.random().toString(36).substring(2, 15) + ".csv");
      });
  }


  render() {
    // console.log(this.props);
    // const { params } = this.props.match
    return (
      <Layout title='Messages'>
        <Paper>
          <Box p={1} m={1}>

            <Box>
              <Typography variant="h5">
                Envíos y estadísticas
            </Typography>
            </Box>

            <Box p={2} m={2}>

              {['admin'].filter(x => authenticationService.currentUser.groups.includes(x)).length > 0 &&
                <Box>
                  <Autocomplete id="company"
                    value={this.state.company ? this.state.company : null}
                    options={this.state.companies}
                    label='Filtar por empresa'
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    onInputChange={this.handleLoadCompanies}
                    onChange={(event, value, reason) => this.handleCompanyChange(event, value, reason)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Filtar por empresa"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
              }

              {this.state.credentials && this.state.company &&
                <Box>

                  <Autocomplete multiple
                    id="crendential-filter"
                    options={this.state.credentials}
                    getOptionLabel={(option) => option.title}
                    filterSelectedOptions
                    onChange={(event, values) => this.handleCredentialFilter(values)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Filtrar por una o más credenciales"
                        placeholder="Selecciona una o multiples credenciales"
                      />
                    )}
                  />

                </Box>
              }

              {this.state.campaigns && this.state.company && !this.state.filters.no_campaigns &&
                <Box>
                  <Autocomplete multiple
                    id="campaign-filter"
                    options={this.state.campaigns}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    onChange={(event, values) => this.handleCampaignFilter(values)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Filtrar por una o más campañas"
                        placeholder="Selecciona una o multiples campañas "
                      />
                    )}
                  />
                </Box>
              }

              {this.state.credentials && this.state.company &&
                <Box>
                  <Switch checked={this.state.filters.no_campaigns}
                    onChange={this.handleSwitchFilter}
                    color="primary"
                    name="no_campaigns"
                    id="no_campaigns"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
              Solo SMSs sin campaña
            </Box>
              }

              {this.state.credentials && this.state.company &&
                <Box>
                  <Grid container spacing={1}>
                    <Grid item md={6} sm={12}>
                      <DateTimePicker name='start_date'
                        id='start_date'
                        label='Filtra por fecha desde'
                        fullWidth
                        ampm={false}
                        onChange={(date) => this.handleDateFilter('start_date', date)}
                        value={this.state.filters.start_date}
                        variant='inline'
                        format="DD-MM-YYYY, HH:mm:ss"
                        renderInput={(props) => <TextField {...props} />}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              // style={{ padding: 0 }}
                              edge="end"
                              size="small"
                              disabled={!this.state.filters.start_date}
                              onClick={() => this.resetDate('start_date')}
                            >
                              <ClearIcon />
                            </IconButton>
                          ),
                        }}
                      />

                    </Grid>
                    <Grid item md={6} sm={12}>
                      <DateTimePicker name='end_date'
                        id='end_date'
                        label='Filtra por fecha hasta'
                        fullWidth
                        ampm={false}
                        onChange={(date) => this.handleDateFilter('end_date', date)}
                        value={this.state.filters.end_date}
                        variant='inline'
                        format="DD-MM-YYYY, HH:mm:ss"
                        renderInput={(props) => <TextField {...props} />}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              // style={{ padding: 0 }}
                              edge="end"
                              size="small"
                              disabled={!this.state.filters.end_date}
                              onClick={() => this.resetDate('end_date')}
                            >
                              <ClearIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                </Box>
              }

            </Box>

            <Box p={2} m={2}>

              <Typography variant="overline" component="h6">
                Estadísticas
            </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Red
                  </TableCell>
                    <TableCell>
                      En cola
                  </TableCell>
                    <TableCell>
                      Envíados al proveedor
                  </TableCell>
                    <TableCell>
                      Envíados
                  </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(this.state.statistics).map((keyName, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          {keyName !== 'null' ? keyName : 'Sin red identificada aún'}
                        </TableCell>
                        <TableCell>
                          {'SCHEDULED' in this.state.statistics[keyName] ? this.state.statistics[keyName].SCHEDULED : 0}
                        </TableCell>
                        <TableCell>
                          {'ESME_ROK' in this.state.statistics[keyName] ? this.state.statistics[keyName].ESME_ROK : 0}
                        </TableCell>
                        <TableCell>
                          {'DELIVRD' in this.state.statistics[keyName] ? this.state.statistics[keyName].DELIVRD : 0}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>

            {this.state.objects.length > 0 &&
              <Box>
                <Box p={2} align='right'>
                  <Grid container>
                    <Grid item md={6} align='left'>
                      <ToggleButtonGroup
                        value={this.state.html.view_type}
                        exclusive
                        onChange={this.handleViewType}
                        size='small'
                      >
                        <ToggleButton value="cards" aria-label="Cards">
                          <DashboardIcon fontSize='small' />
                        </ToggleButton>
                        <ToggleButton value="list" aria-label="List">
                          <FormatAlignJustifyIcon fontSize='small' />
                        </ToggleButton>
                      </ToggleButtonGroup>

                      <Box ml={1} display="inline">
                        <Tooltip title="Descargar como CSV" placement="top">
                          <IconButton onClick={this.handleCSVDownload} aria-label="descargar" size="large">
                            <GetAppOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>

                    </Grid>
                    <Grid item md={6}>
                      <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        labelRowsPerPage='Resultados por página'
                        count={this.state.pagination.rows_num}
                        rowsPerPage={this.state.pagination.rows_per_page}
                        page={this.state.pagination.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {this.state.html.view_type === 'list' &&
                  <Box p={1}>
                    <TableContainer>
                      <Table size="small">
                        <EnhancedTableHead
                          order={this.state.sorting.order}
                          orderBy={this.state.sorting.order_by}
                          onRequestSort={this.handleSort}
                        />
                        <TableBody>
                          {this.state.objects.map((object) => {
                            return (
                              <TableRow key={object.message_uuid}>
                                <TableCell>
                                  {(new Date(object.created_at)).toLocaleString('es-CL')}
                                </TableCell>
                                <TableCell>
                                  {object.network ? object.network.title : ''}
                                </TableCell>
                                <TableCell>
                                  {object.provider ? object.provider.title : ''}
                                </TableCell>
                                <TableCell>
                                  {object.src_phone}
                                </TableCell>
                                <TableCell>
                                  {object.dst_phone}
                                </TableCell>
                                <TableCell>
                                  <Button component={Link} color='primary' startIcon={<SearchIcon />} to={`/messages/${object.message_uuid}`}>Detalles</Button>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                }
                {this.state.html.view_type === 'cards' &&
                  <Box m={10}>
                    <Grid container spacing={2}>
                      {this.state.objects.length > 0 && this.state.objects.map((object) => {
                        return (
                          <Grid item md={4} sm={12} key={object.message_uuid}>
                            <Card variant="outlined">
                              <CardActionArea>
                                <CardContent>

                                  <Typography gutterBottom variant="caption" color="textSecondary">
                                    Número de destino
                                  </Typography>
                                  <Typography gutterBottom variant="h5" component="h2">
                                    <NumberFormat displayType={'text'}
                                      value={object.dst_phone}
                                      format="+# (###) ###-####" mask="_"
                                    />
                                  </Typography>

                                  <Typography gutterBottom variant="caption" color="textSecondary">
                                    Número de origen
                                  </Typography>
                                  <Typography gutterBottom variant="h5" component="h2">
                                    +{object.src_phone}
                                  </Typography>

                                  <Typography gutterBottom variant="body2" color="textSecondary">
                                    message_uuid: {object.message_uuid}
                                  </Typography>

                                  <Typography variant="body2" color="textSecondary" gutterBottom>
                                    Fecha creación: {(new Date(object.created_at)).toLocaleString('es-CL')}
                                  </Typography>
                                  <Typography gutterBottom variant="body2" color="textSecondary">
                                    Estado: {object.status}
                                  </Typography>

                                  <Typography variant="body2" color="textSecondary" gutterBottom>
                                    Fecha creación: {(new Date(object.created_at)).toLocaleString('es-CL')}
                                  </Typography>

                                  <Typography variant="body2" color="textSecondary">
                                    Red: {object.network ? object.network.title : 'Sin red aún'}
                                  </Typography>

                                  <Typography variant="body2" color="textSecondary">
                                    Proveedor: {object.provider ? object.provider.title : 'Sin proveedor aún'}
                                  </Typography>

                                  <Typography variant="body2" color="textSecondary">
                                    Credencial: {object.credential ? object.credential.title : ''}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    Campaña: {object.campaign ? object.campaign.name : 'Sin campaña asociada'}
                                  </Typography>
                                  <br />

                                  <Divider /><br />

                                  <Typography gutterBottom variant="caption" color="textSecondary">
                                    Contenido del SMS
                        </Typography>
                                  <Typography variant="body2">
                                    {object.body}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                              <CardActions>
                                <Button component={Link} startIcon={<SearchIcon />} to={`/`} color='primary'>Ver Detalles</Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Box>
                }

              </Box>
            }
            {this.state.objects.length === 0 &&
              <Alert severity='warning'>
                Aún no hay sms en esta campaña
            </Alert>
            }
          </Box>
        </Paper>
      </Layout>
    );
  }
}

export default withRouter(Messages);
