import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export default function Api(props) {
  return (
    <SwaggerUI url="api_v1.yaml" />
  );
}


