import React from 'react';
import Layout from 'layout/index.js';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Link } from 'react-router-dom';
import { PhoneNumberField, PhoneNumber, countriesService } from 'adds/PhoneNumber.js'
import {
  Box, Paper, Tooltip,
  Typography, IconButton,
  Button,
  TextField,
  Grid,
  MenuItem,
  Card, CardContent, Chip,
  Alert,
} from '@mui/material';

import { authenticationService } from 'auth/authenticationService'
import { withRouter } from 'react-router-dom';
import jsonData from './sms_status.json';

import HelpIcon from '@mui/icons-material/Help';
const sms_status = jsonData;


class TestSMS extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      delay: 10000,
      data: {
        src_phone: '',
        dst_phone: '',
        body: '',
        token: '',
        language: 'python',
      },
      html: {
        form_display: false,
        list_display: true,
        submit_disabled: false,
        credential_error:false,
      },
      status: {
        src_phone: true,
        dst_phone: true,
        body: true,
        token: true,
      },
      elastic_numbers: [],
      credentials: [],
      countries: [],
      refresh_highlight: true,
      languages: ['java', 'php', 'python', 'ruby', '.net'],
      sms: null,
      show_inputs: true,
      code: `import json
import requests

url = 'https://api.axonio.dev/v1/sms/send/'
headers = {
    'content-type': 'application/json',
    'Authorization': 'axoniotoken '
}
body = {
    'messages': [
        {
            'dst_phone': '',
            'body': ''
        }
    ],
}
requests.post(url, data=json.dumps(body), headers=headers)`,
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditForm = this.handleEditForm.bind(this);
    this.getCredentials = this.getCredentials.bind(this);
    this.getNumbers = this.getNumbers.bind(this);
    this.getCountries = this.getCountries.bind(this);
    this.getSms = this.getSms.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleTokenValidation = this.handleTokenValidation.bind(this);
    this.handleBodyValidation = this.handleBodyValidation.bind(this);
  }

  handleLoad() {
    this.getCredentials();
    this.getNumbers();
    this.getCountries();

    this.setState(prevState=>({
      html: { ...prevState.html,
        form_display: true,
        list_display: false,
      },
    }));
  }

  componentDidMount() {
    let get_credential = authenticationService.getValidCredential()
    
    if(!get_credential.error){
      this.setState(prevState=>({
        html: { ...prevState.html,
          credential_error: false,
        },
      }),()=>{
        this.handleLoad(this.props.match.params.unique_id);
        // this.interval = setInterval(this.tick, this.state.delay);
      });
      
    }
    else{
      this.setState(prevState=>({
        html: { ...prevState.html,
          credential_error: true,
        },
      }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sms && 'status' in this.state.sms) {
      if (sms_status[0].statuses[this.state.sms.status].type !== 1) {
        clearInterval(this.interval);
      }
    }
    if (prevState.delay !== this.state.delay) {
      clearInterval(this.interval);
      this.interval = setInterval(this.tick, this.state.delay);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () => {
    if (this.state.sms && !this.state.show_inputs) {
      this.getSms(this.state.sms);
    }
  }


  handleInputChange(event) {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value } = event.target;
    currentState[name] = value;

    if (name === 'token') {
      if (this.state.credentials.filter((credential) => credential.token === value).length > 0 &&
        this.state.credentials.filter((credential) => credential.token === value)[0].type === 'SANDBOX') {
        if (authenticationService.currentUser.person_object &&
          authenticationService.currentUser.person_object.sms_settings &&
          authenticationService.currentUser.person_object.sms_settings.sandbox_validated_phone
        ) {
          // console.log("---3")
          currentState['dst_phone'] = authenticationService.currentUser.person_object.sms_settings.sandbox_validated_phone.toString();
        }
      }
    }
    // this.setState({ refresh_highlight: false }, () => this.setState({ data: currentState }, () => this.setState({ refresh_highlight: true })));
    this.setState({ data: currentState });

    this.handleCodeChange(this.state.data.language);
  }

  handlePhoneChange(event) {
    const { data } = { ...this.state };
    const currentState = data
    const { value } = event.target

    currentState['dst_phone'] = value;
    this.setState((prevState) => ({
      status: {
        ...prevState.status,
        dst_phone: countriesService.validatePhone(value),
      },
    }));

    // this.setState({ refresh_highlight: false }, () => this.setState({ data: currentState }, () => this.setState({ refresh_highlight: true })));
    this.setState({ data: currentState });

    this.handleCodeChange(this.state.data.language);
  }


  handleTokenValidation(value) {
    let status = false;
    if (value !== '') {
      status = true;
    }
    this.setState((prevState) => ({
      status: {
        ...prevState.status,
        token: status,
      },
    }));
    return status;
  }

  handleBodyValidation(value) {
    let status = false;
    if (value !== '') {
      status = true;
    }
    this.setState((prevState) => ({
      status: {
        ...prevState.status,
        body: status,
      },
    }));
    return status;
  }


  handleCodeChange(language) {
    // Cambiar ejemplo de codigo
    // this.setState();

    var src_phone_code_piece = '';

    if (language === 'python') {
      src_phone_code_piece = '';
      if (typeof this.state.data.src_phone !== "undefined" && this.state.data.src_phone !== '') {
        src_phone_code_piece = "\n            'src_phone': '" + this.state.data.src_phone + "',";
      }

      this.setState({
        code: `import json
import requests

url = 'https://api.axonio.dev/v1/sms/send/'
headers = {
    'content-type': 'application/json',
    'Authorization': 'axoniotoken `+ this.state.data.token +
          `'
}
body = {
    'messages': [
        {
            'dst_phone': '`+ this.state.data.dst_phone + `',
            'body': '`+ this.state.data.body + `'` + src_phone_code_piece + `
        }
    ],
}
response = requests.post(url, data=json.dumps(body), headers=headers)
print(response.text)`});
    } else if (language === 'php') {

      src_phone_code_piece = '';
      if (typeof this.state.data.src_phone !== "undefined" && this.state.data.src_phone !== '') {
        src_phone_code_piece = `\n                "src_phone": "` + this.state.data.src_phone + `",`;
      }

      this.setState({
        code: `<?php
$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.axonio.dev/v1/sms/send/',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
    "messages":[
                {
                "dst_phone": "`+ this.state.data.dst_phone + `",
                "body": "`+ this.state.data.body + `"` + src_phone_code_piece + `
                }
            ]
    }',
  CURLOPT_HTTPHEADER => array(
    'Content-Type: application/json',
    'Authorization: axoniotoken `+ this.state.data.token + `'
  ),
));

$response = curl_exec($curl);
curl_close($curl);
echo $response;`});
    } else if (language === 'ruby') {

      src_phone_code_piece = '';
      if (typeof this.state.data.src_phone !== "undefined" && this.state.data.src_phone !== '') {
        src_phone_code_piece = `\n                "src_phone": "` + this.state.data.src_phone + `",`;
      }


      this.setState({
        code: `require "uri"
require "net/http"

url = URI("https://api.axonio.dev/v1/sms/send/")

http = Net::HTTP.new(url.host, url.port);
request = Net::HTTP::Post.new(url)
request["Content-Type"] = "application/json"
request["Authorization"] = "axoniotoken `+ this.state.data.token + `"
request.body = "{\n    "messages":[\n                {\n                "dst_phone": "`+ this.state.data.dst_phone + `",\n                "body": "` + this.state.data.body + `",` + src_phone_code_piece + `                \n                }\n            ]\n}"

response = http.request(request)
puts response.read_body
      `});
    } else if (language === 'java') {

      src_phone_code_piece = '';
      if (typeof this.state.data.src_phone !== "undefined" && this.state.data.src_phone !== '') {
        src_phone_code_piece = `\n                "src_phone": "` + this.state.data.src_phone + `",`;
      }

      this.setState({
        code: `OkHttpClient client = new OkHttpClient().newBuilder().build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\n    "messages":[\n                {\n                "dst_phone": "`+ this.state.data.dst_phone + `",\n                "body": "` + this.state.data.body + `",` + src_phone_code_piece + `\n                }\n            ]\n}");
Request request = new Request.Builder()
.url("http://localhost:8000/v1/sms/send/")
.method("POST", body)
.addHeader("Content-Type", "application/json")
.addHeader("Authorization", "axoniotoken `+ this.state.data.token + `")
.build();
Response response = client.newCall(request).execute();`});
    } else if (language === '.net') {

      src_phone_code_piece = '';
      if (typeof this.state.data.src_phone !== "undefined" && this.state.data.src_phone !== '') {
        src_phone_code_piece = `\n                "src_phone": "` + this.state.data.src_phone + `",`;
      }

      this.setState({
        code: `var client = new RestClient("http://localhost:8000/v1/sms/send/");
        client.Timeout = -1;
        var request = new RestRequest(Method.POST);
        request.AddHeader("Content-Type", "application/json");
        request.AddHeader("Authorization", "axoniotoken `+ this.state.data.token + `");
        request.AddParameter("application/json", "{\n    "messages":[\n                {\n                "dst_phone": "`+ this.state.data.dst_phone + `",\n                "body": "` + this.state.data.body + `",` + src_phone_code_piece + `\n                }\n               ]\n}",  ParameterType.RequestBody);
        IRestResponse response = client.Execute(request);
        Console.WriteLine(response.Content);`});
    };
  }

  handleSubmit(event) {
    event.preventDefault();

    // console.log(this.state)
    const dst_phone_status = this.state.status.dst_phone;
    const token_status = this.handleTokenValidation(this.state.data.token);
    const body_status = this.handleBodyValidation(this.state.data.body);



    if (dst_phone_status && token_status && body_status) {

      var messages = [{ dst_phone: this.state.data.dst_phone, body: this.state.data.body }];

      if (typeof this.state.data.src_phone != "undefined") {
        messages[0]["src_phone"] = this.state.data.src_phone;
      }

      authenticationService.fetchApi({
        token: this.state.data.token,
        url: '/sms/send/',
        method: 'POST',
        body: JSON.stringify({ messages: messages }),
      })
        .then((result) => {
          if (result.request_status && result.request_status !== 'ERROR') {
            this.getSms(result.messages[0]);
            this.interval = setInterval(this.tick, this.state.delay);
          }
          
        });
    }
  }

  handleEditForm(event, data = null) {
    this.setState({
      html: {
        form_display: true,
        list_display: false,
      },
    });
    event.preventDefault();
  }

  getCredentials() {
    authenticationService.fetchApi({
      url: '/company/credential/',
      method: 'GET',
    }).then((result) => {
      this.setState({ credentials: [] },
        () => this.setState({ credentials: result }));
    })
  }

  getNumbers() {
    authenticationService.fetchApi({
      url: '/number/',
      method: 'GET',
      api: true,
    })
    .then((result) => {
      this.setState({ elastic_numbers: result.data });
    });
  }

  getCountries() {
    authenticationService.fetchApi({
      url: '/country/',
      method: 'GET',
    }).then((result) => {
      this.setState({ countries: [] },
        () => this.setState({ countries: result.countries }));
    })
  }

  getSms(sms) {
    authenticationService.fetchApi({
      url: '/sms/' + sms.message_uuid,
      method: 'POST',
      api: true,
    }).then((result) => {
      this.setState({ sms: [] },
        () => this.setState({ sms: result, show_inputs: false }));
    })
      .catch((err) => {
        console.log('Entra al catch: ', err)
      });
  }

  render() {
    return (
      <Layout title='Generar código de envío de SMS'>
        <Paper>
          <Box p={2}>
            <Grid container>
              <Grid item xs={2} sm={1}>
                <IconButton component={Link} 
                          aria-label="help" 
                          to={{ pathname: 'https://www.axonio.dev/probar-sms/'}}
                          target='_blank'>
                  <HelpIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10} sm={11}>
                <Typography variant="caption" sx={{color:'#AAAAAA'}}>
                  Esta interfaz te permite mandar un sms individual y al mismo tiempo ver el código que se genera para el envío.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {this.state.html.credential_error &&
            <Alert severity='error'>Para desplegar esta vista debes seleccionar una credencial por defecto que esté activa y no-expirada, en la sección de Mi Empresa.</Alert>
          }
          {!this.state.html.credential_error &&
          <Box p={1}>

            {this.state.html.form_display &&
              <Grid container spacing={3}>
                <Grid item md={5} xs={12}>
                  <form>
                    {this.state.credentials.length > 0 && this.state.credentials && this.state.show_inputs &&
                      <Box p={1}>
                        {(this.state.credentials.filter((credential) => credential.type === 'SANDBOX').length) > 0 &&
                          <Box p={1}>
                            <Alert severity='info'>
                              Si quieres utilizar los SMS de prueba, debes seleccionar la "Credencial de pruebas". Los SMS de prueba tiene un largo máximos de 100 caractéres.
                            </Alert>
                          </Box>
                        }
                        <TextField
                          name="token"
                          id="token"
                          select
                          label="Credencial de acceso de API"
                          fullWidth={true}
                          value={this.state.data.token}
                          onChange={this.handleInputChange}
                          error={!this.state.status.token}
                          helperText="Selecciona tu credencial de acceso para la API"
                        >
                          {this.state.credentials.map((option) => (
                            <MenuItem key={option.token} value={option.token}>
                              {option.title}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    }
                    {this.state.elastic_numbers.length > 0 && this.state.elastic_numbers && this.state.show_inputs &&
                      <Box p={1}>
                        <TextField
                          name="src_phone"
                          id="src_phone"
                          select
                          label="Número de salida"
                          fullWidth={true}
                          value={this.state.data.src_phone}
                          onChange={this.handleInputChange}
                          error={!this.state.status.src_phone}
                          helperText="Selecciona tu número de salida"
                        >
                          {this.state.elastic_numbers.map((option) => (
                            <MenuItem key={option.number} value={option.number}>
                              <PhoneNumber value={option.number} />
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    }
                    {this.state.show_inputs &&
                      <div>
                        {this.state.credentials.filter((credential) => credential.token === this.state.data.token).length > 0 &&
                          this.state.credentials.filter((credential) => credential.token === this.state.data.token)[0].type === 'SANDBOX' ?
                          <Box p={1}>
                            {authenticationService.currentUser.person_object &&
                              authenticationService.currentUser.person_object.sms_settings &&
                              authenticationService.currentUser.person_object.sms_settings.sandbox_phone &&
                              authenticationService.currentUser.person_object.sms_settings.sandbox_phone === authenticationService.currentUser.person_object.sms_settings.sandbox_validated_phone &&
                              <Box>
                                <Typography>
                                  Teléfono de pruebas:&nbsp;
                                </Typography>
                                <Typography color='primary'>
                                  <PhoneNumber value={authenticationService.currentUser.person_object.sms_settings.sandbox_validated_phone} />
                                </Typography>
                              </Box>
                            }
                          </Box> :
                          <Box p={1}>
                            <PhoneNumberField name='dst_phone'
                              id='dst_phone'
                              fullWidth={true}
                              label='Número de destino'
                              placeholder='Número de destino'
                              value={this.state.data.dst_phone}
                              onChange={this.handlePhoneChange}
                              error={!this.state.status.dst_phone}
                            />
                          </Box>
                        }

                        <Box p={1}>
                          <TextField name='body'
                            id='body'
                            label='Contenido SMS'
                            fullWidth={true}
                            multiline
                            rows={4}
                            variant="outlined"
                            value={this.state.data.body}
                            onChange={this.handleInputChange}
                            inputProps={{ maxLength: 460 }}
                            error={!this.state.status.body}
                          />
                          {this.state.credentials.filter((credential) => credential.token === this.state.data.token).length > 0 &&
                            this.state.credentials.filter((credential) => credential.token === this.state.data.token)[0].type === 'SANDBOX' ?
                            <Typography gutterBottom variant="caption" color="textSecondary">
                              {this.state.data.body.length} de un máximo de 100 caracteres, (solo para SMS con credential de pruebas).
                            </Typography> :
                            <Typography gutterBottom variant="caption" color="textSecondary">
                              {this.state.data.body.length} de un máximo de 460 caracteres, lo que equivale a {Math.ceil(this.state.data.body.length / 160)} SMS.
                            </Typography>
                          }
                        </Box>

                        <Box p={1}>
                          <Button
                            disabled={this.state.html.submit_disabled}
                            color='primary'
                            onClick={this.handleSubmit}
                            variant="contained"
                            startIcon={<SendIcon />}
                          >
                            Enviar SMS
                          </Button>
                        </Box>
                      </div>
                    }
                    {!this.state.show_inputs &&
                      <Box p={1}>
                        <Button
                          disabled={this.state.html.submit_disabled}
                          color='primary'
                          onClick={() => {
                            this.setState({},
                              () => this.setState({
                                show_inputs: true, data: {
                                  dst_phone: '',
                                  body: '',
                                  token: '',
                                  language: 'python',
                                },
                              }));
                          }}
                          variant="contained"
                          startIcon={<ArrowBackIcon />}
                        >
                          Volver a enviar
                        </Button>
                      </Box>
                    }
                  </form>
                  <Box p={1}>
                    {this.state.sms && !this.state.show_inputs &&
                      <div>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography variant="caption" color="textSecondary">
                              Teléfono de destino:
                            </Typography>
                            <Typography variant="subtitle1">
                              <PhoneNumber value={this.state.sms.dst_phone} />
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              Teléfono de origen:
                            </Typography>
                            <Typography variant="subtitle1">
                              <PhoneNumber value={this.state.sms.src_phone} />
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              Estado:
                            </Typography>
                            <Box>
                              {this.state.sms.status &&
                                <Tooltip title={sms_status[0].statuses[this.state.sms.status].description} placement="top-start">
                                  <>
                                    {sms_status[0].statuses[this.state.sms.status].type === 0 &&
                                      <Chip variant="outlined" label={(sms_status[0].statuses[this.state.sms.status].spanish)} style={{ backgroundColor: '#FFDDDD', color: '#884444', border: '0px' }} />
                                    }
                                    {sms_status[0].statuses[this.state.sms.status].type === 1 &&
                                      <Chip variant="outlined" label={(sms_status[0].statuses[this.state.sms.status].spanish)} style={{ backgroundColor: '#FFFFDD', color: '#888844', border: '0px' }} />
                                    }
                                    {sms_status[0].statuses[this.state.sms.status].type === 2 &&
                                      <Chip variant="outlined" size="small" label={(sms_status[0].statuses[this.state.sms.status].spanish)} style={{ backgroundColor: '#DDFFDD', color: '#448844', border: '0px' }} />
                                    }
                                  </>
                                </Tooltip>
                              }
                            </Box>

                            <Typography variant="caption" color="textSecondary">
                              Identificador único:
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {this.state.sms.message_uuid}
                            </Typography>

                            <Typography display="inline" variant="caption" color="textSecondary">
                              Creación:&nbsp;
                            </Typography>
                            <Typography display="inline" variant="body2" gutterBottom>
                              {(new Date(this.state.sms.created_at)).toLocaleString('es-CL')}
                            </Typography>
                            <br />
                            <Typography display="inline" variant="caption" color="textSecondary">
                              Última actualización:&nbsp;
                            </Typography>
                            <Typography display="inline" variant="body2" gutterBottom>
                              {(new Date(this.state.sms.updated_at)).toLocaleString('es-CL')}
                            </Typography>
                            <br />
                            <Typography display="inline" variant="caption" color="textSecondary">
                              Red de destino:&nbsp;
                            </Typography>
                            <Typography display="inline" variant="body2" gutterBottom>
                              {this.state.sms.network ? this.state.sms.network.title : 'Sin determinar'} ({this.state.sms.country ? this.state.sms.country.title : 'Sin determinar'})
                            </Typography>
                            <br />
                            <Typography display="inline" variant="caption" color="textSecondary">
                              Credencial utilizada:&nbsp;
                            </Typography>
                            <Typography display="inline" variant="body2" gutterBottom>
                              {this.state.sms.credential ? this.state.sms.credential.title : ''}
                            </Typography>
                            <br />
                            <Typography display="inline" variant="caption" color="textSecondary">
                              Campaña:&nbsp;
                            </Typography>
                            <Typography display="inline" variant="body2" gutterBottom>
                              {this.state.sms.campaign ? this.state.sms.campaign.name : 'Sin campaña'}
                            </Typography>
                            <br />
                            <Typography variant="caption" color="textSecondary">
                              Contenido del SMS:
                            </Typography>

                            <Typography variant="body2">
                              <i>{this.state.sms.body}</i>
                            </Typography>
                            {this.state.sms.body &&
                              <Typography gutterBottom variant="caption" color="textSecondary">
                                {this.state.sms.body.length} caracteres, equivalente a {this.state.sms.number_of_sms} SMS.
                              </Typography>
                            }
                          </CardContent>

                        </Card>
                        <Button component={Link} color='primary' to={`/sms/outgoing/`}>Ver historial de envíos</Button>
                      </div>

                    }
                  </Box>
                </Grid>
                <Grid item md={7} xs={12}>
                  {this.state &&
                    <Box p={1}>
                      <TextField
                        name="language"
                        id="language"
                        select
                        label="Lenguaje de programación"
                        fullWidth={true}
                        value={this.state.data.language}
                        onChange={this.handleInputChange}
                        helperText="Selecciona el lenguaje que deseas utilizar en la API."
                      >
                        {this.state.languages.map((language) => (
                          <MenuItem key={language} value={language}>
                            {language}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  }
                  {this.state.refresh_highlight &&
                    <Box label="Código para uso de API en Python" p={1}>
                      <SyntaxHighlighter language={this.state.data.language} style={docco}>
                        {this.state.code}
                      </SyntaxHighlighter>
                      <Grid container justify="flex-end">
                        <Tooltip title="Copiar código">
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(this.state.code)
                            }}
                            aria-label="copiar"
                          >
                            <FileCopyIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Box>
                  }
                </Grid>
              </Grid>
            }
          </Box>
          }
        </Paper>
      </Layout >
    );
  }
}

export default withRouter(TestSMS);
