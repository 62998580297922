import React from "react";
import Layout from '../../layout/index.js'
import { withRouter } from 'react-router-dom';
import BasketAdmin from './BasketAdmin.js'
import NumberFormat from 'react-number-format';
import MoneyTransaction from '../moneyTransaction'
import { authenticationService } from 'auth/authenticationService'
import {  
  Paper, Box, Typography, Grid, Button, TextField
} from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      isNumericString
      prefix="$"
    />
  );
});

class CompanyAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        form_disable: 'disable',
        btn_edit: 'btn btn-edit',
        btn_submit: 'display-none',
      },
      data: {
        company_uuid: this.props.match.params.company_uuid,
        name: '',
        description: '',
      },
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.handleCreateProductInBasket = this.handleCreateProductInBasket.bind(this);
  }

  handleLoad() {
    authenticationService.fetchApi({
        url: '/company/'+this.state.data.company_uuid,
        method: 'GET',
    })
    .then(result => {
      this.setState({data: result});
    });
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleCreateProductInBasket() {
    var params = {}
    params.add = 1;
    params.amount = this.state.amount;
    params.company_uuid = this.props.match.params.company_uuid;

    authenticationService.fetchApi({
      url: '/topup/',
      method: 'POST',
      body: JSON.stringify(params),
    })
    .then(result => {
      this.setState({amount: ''});
      this.refs.reloadBasket.handleLoad();
    });
  }

  render() {
    return (
      <Layout title='Panel de administración empresa'>
        <Paper>
          <Box py={1} m={2}>
            <Typography variant='h5'>Detalles de la empresa</Typography>

            <Typography variant='overline'>Nombre</Typography>
            <Typography color='textSecondary' variant='body2'>{this.state.data.name}</Typography>

            <Typography variant='overline'>Descripción de la empresa [1000 caracteres]</Typography>
            <Typography color='textSecondary' variant='body2'>{this.state.data.description}</Typography>

          </Box>
        </Paper>
        <Paper>
          <Box py={1} m={2}>
            <Box>
              <Typography variant='h5'>
                {<MonetizationOnIcon/>} Cargar crédito
              </Typography>
            </Box>
            <Grid container>
              <Grid item md={6} sm={12}>
                <TextField  name='amount'
                            id='amount'
                            helperText='El monto mínimo para ingresar es $10.0000'
                            label='Ingresa el monto que deseas cargar'
                            type='text'
                            fullWidth
                            value={this.state.amount}
                            onChange={(event) => this.setState({amount: event.target.value})}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}/>
              </Grid>
              <Grid item md={6} sm={12}>
                <Button onClick={this.handleCreateProductInBasket} color='primary'>Cargar crédito</Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Paper>
          <Box py={1} m={2}>
            <Typography variant='h5'>Cargas de crédito</Typography>
            <BasketAdmin company_uuid={this.state.data.company_uuid}/>
          </Box>
        </Paper>

        <Paper>
          <Box py={1} m={2}>
            <Typography variant='h5'>Money transactions</Typography>
            <MoneyTransaction company={this.state.data}/>
          </Box>
        </Paper>

      </Layout>
    );
  }
}

export default withRouter(CompanyAdmin);
