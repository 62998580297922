import React from 'react';
import {
  Box, Button,
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
// const ErrorMessageContext = React.createContext('error_message');

const originalSetItem = localStorage.setItem;

localStorage.setItem = function(key, value) {
  var event = new Event('itemInserted');
  event.value = value; // Optional..
  event.key = key; // Optional..
  document.dispatchEvent(event);
  originalSetItem.apply(this, arguments);
  console.log('SetITEM')
};


class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      error_content: {
        error: false,
        status: null,
        statusText: null,
        description: null
      }
    };

    this.popUpError = this.popUpError.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this)

    localStorage.setItem('error', false);
  }

  componentDidMount() {
    window.addEventListener("storage",
      () => {
        this.popUpError();
      }, false
    );
  }

  componentDidUpdate() {

  }

  popUpError() {
    let error_state = JSON.parse(localStorage.getItem('error'));

    if (error_state.error === true) {
      this.setState({ error_content: error_state });

      let reset = {
        error: false,
        status: null,
        statusText: null,
        description: null
      }
      this.setState({ error: true }, () => {
        localStorage.setItem('error', JSON.stringify(reset))
      });
    }
  }

  handleCloseDialog() {
    this.setState({ error: false });
  }

  render() {
    return (
      <Box>
        <Box>
          <Dialog open={this.state.error}
            onClose={this.handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth={true}
            sx={{minWidth: 500}}
          >
            <DialogTitle id="alert-dialog-title">
              <Box>
                ERROR {this.state.error_content.status}
              </Box>
            </DialogTitle>
            <DialogContent>
              {this.state.error_content.statusText}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDialog} color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        {this.props.children}
      </Box>
    );
  }
}

export default ErrorHandler;
