import React from "react";
import Layout from 'layout/index.js'
import VerifyService from './VerifyService.js'
import { Link } from 'react-router-dom';
import { authenticationService } from 'auth/authenticationService'
import {
  Paper, Box, Button, Typography, Alert,  
  TableSortLabel, TablePagination, TableContainer,
  Table, TableRow, TableHead, TableBody, TableCell,
  Grid, IconButton, 
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import HelpIcon from '@mui/icons-material/Help';

const headCells = [
  { id: "created_at", numeric: false, disablePadding: false, label: "Fecha creación", sorting: true},
  { id: "verify_service_uuid", numeric: false,disablePadding: false,label: "verify_service_uuid", sorting: true},
  { id: "title", numeric: false,disablePadding: false,label: "Título", sorting: true},
  { id: "body", numeric: false, disablePadding: false, label: "Contenido mensaje", sorting: true},
  { id: "expiration_time", numeric: false, disablePadding: false, label: "Tiempo de expiración", sorting: true},
  { id: "details", numeric: false, disablePadding: false, label: "Ver detalles", sorting: false},
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          !headCell.sorting ? <TableCell key={headCell.id}>{headCell.label}</TableCell> : (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className='hideSort'>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

function encodeQueryData(data) {
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
}


class VerifyServices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      objects: [],

      html: {
        list: true,
        show_view: false,
        credential_error: false,
      },
      filters: {
      },
      pagination:{
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by:'name',
        order:'desc',
      },
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.createNew = this.createNew.bind(this);
  }



  handleChangePage(event, page){
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
          page: page,
      }}), ()=> this.handleLoad());

  }
  handleChangeRowsPerPage(event){
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
          rows_per_page: event.target.value,
      }}), ()=> this.handleLoad());
  }

  handleSort(event, property){
    const isAsc = this.state.sorting.order_by === property && this.state.sorting.order === "asc";
    this.setState(prevState => ({
      sorting: {
          ...prevState.sorting,
          order: isAsc ? "desc" : "asc",
          order_by: property,
    }}),()=>this.handleLoad()
    );

  }


  handleLoad(){

    var params = {
        page: this.state.pagination.page+1,
        order_by: this.state.sorting.order_by,
        order: this.state.sorting.order,
        rows_per_page: this.state.pagination.rows_per_page,
    };

    var query = encodeQueryData(params)

    authenticationService.fetchApi({
      url:'/verify/service/?'+query,
      method: 'GET',
      api:true,
    })
    .then(result=>{
      let list = false;
      if(result.data.length > 0){
        list=true;
      }
      this.setState(prevState => ({
        pagination: {
            ...prevState.pagination,
            rows_num: result.rows_num
        },
        html: {
          ...prevState.html,
          list: list,
          show_view: true,
          credential_error:false,
        },
        objects: result.data
      }));
    })
    .catch(err=>{
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          show_view: false,
          credential_error:true
        },
      }));
    });

  }

  componentDidMount(){
    let get_credential = authenticationService.getValidCredential()
    
    if(!get_credential.error){
      this.setState(prevState=>({
        html: { ...prevState.html,
          credential_error: false,
        },
      }),()=>{
        this.handleLoad();
      });
      
    }
    else{
      this.setState(prevState=>({
        html: { ...prevState.html,
          credential_error: true,
        },
      }));
    }
  }

  createNew(){
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        list: false,
      },
    }));
  }

  render() {
    // console.log(this.props);
    // const { params } = this.props.match
    return (
      <Layout leftIcon={<VpnKeyIcon/>} title='Verify - Servicios'>
        {!this.state.html.credential_error &&
        <Paper>
          <Box p={2}>
            <Grid container>
              <Grid item xs={2} sm={1}>
                <IconButton component={Link} 
                          aria-label="help" 
                          to={{ pathname: 'https://www.axonio.dev/administrar-servicios-verify/'}}
                          target='_blank'>
                  <HelpIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10} sm={11}>
                <Typography variant="caption" sx={{color:'#AAAAAA'}}>
                  Verify es un servicio de One Time Password (OTP) que te permite por ejemplo hacer 
                  un Two Factor Authentication. Puedes tener varios servicios activados, 
                  cada uno con su propio mensaje personalizado.
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box p={1} m={1}>


            <Box>
              <Box p={1} m={1} align='right'>
                <Button component={Link} to={'/verify/services/new'} color='primary' variant='contained'>Crear nuevo servicio</Button>
              </Box>

              { this.state.html.create &&
                <VerifyService key={'new'} reload={this.handleLoad}/>
              }

              {this.state.objects.length>0 && this.state.html.list &&
              <Box p={1}>
                <TablePagination
                  rowsPerPageOptions={[5, 10]}
                  component="div"
                  labelRowsPerPage='Resultados por página'
                  count={this.state.pagination.rows_num}
                  rowsPerPage={this.state.pagination.rows_per_page}
                  page={this.state.pagination.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
                <TableContainer>
                  <Table size="small" >
                    <EnhancedTableHead
                      order={this.state.sorting.order}
                      orderBy={this.state.sorting.order_by}
                      onRequestSort={this.handleSort}
                    />
                    <TableBody>
                    {this.state.objects.map((object) => {
                      return(
                        <TableRow key={object.verify_service_uuid}>
                          <TableCell>
                            {(new Date(object.created_at)).toLocaleDateString('es-CL')}
                          </TableCell>
                          <TableCell>
                            {object.verify_service_uuid}
                          </TableCell>
                          <TableCell>
                            {object.title}
                          </TableCell>
                          <TableCell>
                            {object.body}
                          </TableCell>
                          <TableCell>
                            {object.expiration_time}
                          </TableCell>
                          <TableCell>
                            <Button component={Link} to={'/verify/services/'+object.verify_service_uuid} color='primary' startIcon={<SearchIcon />}>Ver detalles</Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              }
              {this.state.objects.length===0 && this.state.html.list &&
              <Alert severity='warning'>
                Aún no tienes ningún servicio Verify creado
              </Alert>
              }
            </Box>
          </Box>
        </Paper>
        }
        { this.state.html.credential_error &&
          <Alert severity='error'>Para desplegar esta vista debes seleccionar una credencial por defecto que esté activa y no-expirada, en la sección de Mi Empresa.</Alert>
        }
      </Layout>
    );
  }
}

export default VerifyServices;
