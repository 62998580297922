import React from "react";
import { authenticationService } from 'auth/authenticationService'
import {
  Box, Grid, Button, TextField, CircularProgress,
  TableSortLabel, TablePagination, TableContainer,
  Table, TableRow, TableHead, TableBody, TableCell,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Chip, Alert, Autocomplete, Select, MenuItem,
  FormControlLabel, Checkbox, Stack, Typography,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import {PhoneNumberField, PhoneNumber, countriesService} from 'adds/PhoneNumber.js'
import {ExcelRenderer} from 'react-excel-renderer';

const headCells = [
  { id: "first_name", numeric: false, disablePadding: false, label: "Nombre", sorting: true },
  { id: "phone", numeric: false, disablePadding: false, label: "Teléfono", sorting: true },
  { id: "lists", numeric: false, disablePadding: false, label: "Listas", sorting: false },
  { id: "tags", numeric: false, disablePadding: false, label: "Etiquetas", sorting: false },
  { id: "actions", numeric: true, disablePadding: false, label: "Acciones", sorting: false }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          !headCell.sorting ?
            <TableCell key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}>
              {headCell.label}
            </TableCell>
            : (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className='hideSort'>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

class Contacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      objects: [],
      html: {
        contact_dialog: false,
        import_dialog: false,
        import_results: false,
        submitting: false,
      },
      data: {
        contact_uuid: '',
        first_name: '',
        last_name: '',
        phone: '',
        formated_phone: '',
        custom_id: '',
        lists: [],
        tags: [],
      },
      error: {
        first_name: false,
        last_name: false,
        phone: false,
      },
      feedback: {
        first_name: '',
        last_name: '',
        phone: '',
      },
      filters: {
        list_uuid: '',
        tag_uuid: ''
      },
      pagination: {
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by: 'phone',
        order: 'asc',
      },
      lists: [],
      tags: [],
      importOptions: {
        encodedFile: null,
        firstRowHeader: true,
        cols: [
          'first_name',
          'last_name',
          'phone',
          'custom_id',
          'lists',
          'tags',
        ],
        rows: [],
        results: {
          new: 0,
          existing: 0,
          error: 0
        }
      },
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.openContactDialog = this.openContactDialog.bind(this);
    this.closeContactDialog = this.closeContactDialog.bind(this);
    this.openImportDialog = this.openImportDialog.bind(this);
    this.closeImportDialog = this.closeImportDialog.bind(this);
    this.fileHandler = this.fileHandler.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCustomChange = this.handleCustomChange.bind(this);
    this.handleContactSubmit = this.handleContactSubmit.bind(this);
    this.handleImportSubmit = this.handleImportSubmit.bind(this);
    this.handleImportColsChange = this.handleImportColsChange.bind(this);
    this.handleImportCheckboxChange = this.handleImportCheckboxChange.bind(this);
  }

  componentDidMount() {
    this.handleLoad();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lists.length !== this.props.lists.length) {
      this.setState({lists: this.props.lists});
    }
    
    if (prevProps.tags.length !== this.props.tags.length) {
      this.setState({tags: this.props.tags});
    }
  }

  handleLoad(page = null) {
    var params = {
      page: this.state.pagination.page + 1,
      order_by: this.state.sorting.order_by,
      order: this.state.sorting.order,
      rows_per_page: this.state.pagination.rows_per_page
    };

    if (this.state.filters.list_uuid !== '') {
      params['list_uuid'] = this.state.filters.list_uuid;
    }

    if (this.state.filters.tag_uuid !== '') {
      params['tag_uuid'] = this.state.filters.tag_uuid;
    }

    var query = encodeQueryData(params);
    
    authenticationService.fetchApi({
      url: '/contact/?' + query,
      method: 'GET',
      api: true
    })
    .then(result => {
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          contact_dialog: false,
          submitting: false
        },
        data: {
          contact_uuid: '',
          first_name: '',
          last_name: '',
          phone: '',
          formated_phone: '+',
          custom_id: '',
          lists: [],
          tags: [],
        },
        pagination: {
          ...prevState.pagination,
          rows_num: result.rows_num
        },
        objects: result.data
      }));
    })
    .catch(err => {

    });
  }

  handleChangePage(event, page) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
      }
    }), () => this.handleLoad());
  }

  handleChangeRowsPerPage(event) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        rows_per_page: event.target.value,
      }
    }), () => this.handleLoad());
  }

  handleSort(event, property) {
    const isAsc = this.state.sorting.order_by === property && this.state.sorting.order === "asc";
    this.setState(prevState => ({
      sorting: {
        ...prevState.sorting,
        order: isAsc ? "desc" : "asc",
        order_by: property,
      }
    }), () => this.handleLoad());
  }

  openContactDialog(event, entity) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        contact_dialog: true,
        contact_dialog_entity: entity,
      },
      data: {
        contact_uuid: entity.contact_uuid || 'new',
        first_name: entity.first_name || '',
        last_name: entity.last_name || '',
        phone: entity.phone ? (typeof entity.phone !== "string" ? entity.phone.toString() : entity.phone) : '',
        formated_phone: entity.phone ? (typeof entity.phone !== "string" ? "+" + entity.phone.toString() : entity.phone) : '',
        custom_id: entity.custom_id || '',
        lists: entity.lists || [],
        tags: entity.tags || [],
      }
    }));
  }

  closeContactDialog(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        contact_dialog: false,
      },
      data: {
        contact_uuid: '',
        first_name: '',
        last_name: '',
        phone: '',
        formated_phone: '+',
        custom_id: '',
        lists: [],
        tags: [],
      },
    }));
  }

  openImportDialog(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        import_dialog: true,
      },
    }));
  }

  closeImportDialog(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        import_dialog: false,
        import_results: true,
      },
      importOptions: {
        encodedFile: null,
        firstRowHeader: true,
        cols: [
          'first_name',
          'last_name',
          'phone',
          'custom_id',
          'lists',
          'tags',
        ],
        rows: [],results: {
          new: 0,
          existing: 0,
          error: 0
        }
      }
    }));
  }

  toBase64(file) {
    return(
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
  )}

  fileHandler(event) {
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      }
      else {
        this.toBase64(fileObj).then(encodedFile => {
          this.setState(prevState => ({
            importOptions: {
              ...prevState.importOptions,
              encodedFile: encodedFile,
              rows: resp.rows.slice(0, 5)
            }
          }));
        });
      }
    });
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.handleCustomChange(name, value);
  }

  handleOptionChange(name, uuid, values) {
    const values_array = values.map(value => {
      return value[uuid] || value;
    });

    this.handleCustomChange(name, values_array);
  }

  handleFilterChange(name, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [name]: value ? value[name] : '',
      },
      pagination: {
        ...prevState.pagination,
        page: 0
      }
    }), () => this.handleLoad());
  }

  handleImportColsChange(event, index) {
    const { value } = event.target;
    
    const {importOptions: {cols}} = this.state;
    cols[index] = value;

    this.setState(prevState => ({
      importOptions: {
        ...prevState.importOptions,
        cols
      }
    }));
  }

  handleImportCheckboxChange(event) {
    this.setState(prevState => ({
      importOptions: {
        ...prevState.importOptions,
        firstRowHeader: event.target.checked
      }
    }));
  }

  handleCustomChange(name, value) {

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: value,
      }
    }));
  }

  handleImportSubmit(event) {
    event.preventDefault();

    this.setState(prevState => ({
      html: {
        ...prevState.html,
        submitting: true,
      }
    }));

    authenticationService.fetchApi({
      url: '/contact/import',
      method: 'POST',
      body: JSON.stringify(this.state.importOptions),
      api: true,
      // no_error_popup: true,
    })
    .then(result => {
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          submitting: false,
          import_results: true,
        },
        importOptions: {
          ...prevState.importOptions,
          results: {
            new: result.new,
            existing: result.existing,
            error: result.error
          }
        }
      }));

      this.handleLoad();
    })
    .catch(message => {
      
    });
  }

  handleContactSubmit(event) {
    event.preventDefault();

    let error = false;
    const fields = this.state.error;

    this.setState(prevState => ({
      html: {
        ...prevState.html,
        submitting: true,
      }
    }));
    
    for (const field in fields) {
      let field_error = !this.state.data[field]?.length;
      
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          [field]: field_error
        },
        feedback: {
          ...prevState.feedback,
          [field]: field_error ? "Este campo es obligatorio" : ''
        }
      }));
      
      // Error si al menos uno es vacío
      if (!error && field_error) {
        error = true;
      }
    }
    
    let phone_validity = countriesService.validatePhone(this.state.data.phone)
    // console.log(phone_validity)
    if(!phone_validity){
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          phone: error
        },
        feedback: {
          ...prevState.feedback,
          phone: "Formato incorrecto"
        }
      }));
      error = true
    }
    
    if (error) {
      // Mostrar error
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          submitting: false,
        }
      }));
    }
    else {
      authenticationService.fetchApi({
        url: '/contact/',
        method: 'POST',
        body: JSON.stringify(this.state.data),
        api: true,
        no_error_popup: true,
      })
      .then(result => {
        this.handleLoad();
      })
      .catch(message => {
        if (message.error === 'CONTACT PHONE ALREADY EXISTS') {
          this.setState(prevState => ({
            error: {
              ...prevState.error,
              phone: true,
            },
            feedback: {
              ...prevState.feedback,
              phone: 'Contacto telefónico ya existe',
            },
            html: {
              ...prevState.html,
              submitting: false,
            }
          }));
        }
      });
    }
  }

  render() {
    return (
      <Box m={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs sm={3}>
            <Autocomplete
                name="lists"
                options={this.state.lists}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                fullWidth
                onChange={(event, values) => this.handleFilterChange('list_uuid', values)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filtrar por listas"
                    placeholder="Buscar o elegir"
                  />
                )}
              />
          </Grid>
          <Grid item xs sm={3}>
            <Autocomplete
              name="tags"
              options={this.state.tags}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              fullWidth
              onChange={(event, values) => this.handleFilterChange('tag_uuid', values)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filtrar por Etiquetas"
                  placeholder="Buscar o elegir"
                />
              )}
            />
          </Grid>
          <Grid item xs sm={6}>
            <Box align="right">
              <Box display="inline" mr={1}>
                <Button onClick={this.openImportDialog} startIcon={<CloudUploadIcon />} variant="contained">Importar</Button>
              </Box>
              <Box display="inline">
                <Button onClick={(event) => this.openContactDialog(event, 'new')} startIcon={<AddCircleIcon />} color="primary" variant="contained">Crear contacto</Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {this.state.objects.length > 0 &&
          <Box my={2} pb={2}>
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component="div"
              labelRowsPerPage='Resultados por página'
              count={this.state.pagination.rows_num}
              rowsPerPage={this.state.pagination.rows_per_page}
              page={this.state.pagination.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  order={this.state.sorting.order}
                  orderBy={this.state.sorting.order_by}
                  onRequestSort={this.handleSort}
                />
                <TableBody>
                  {this.state.objects.map((object) => {
                    return (
                      <TableRow key={object.contact_uuid}>
                        <TableCell>
                          {object.first_name} {object.last_name}
                        </TableCell>
                        <TableCell>
                          <PhoneNumber value={object.phone} />
                        </TableCell>
                        <TableCell>
                          {object.lists.map(list => (
                            <Chip key={object.contact_uuid + list.list_uuid}
                              variant="default"
                              size="small"
                              label={list.name}
                              color={this.state.filters.list_uuid === list.list_uuid ? 'secondary' : 'default'}
                              style={{marginRight: 1}} />
                          ))}
                        </TableCell>
                        <TableCell>
                          {object.tags.map(tag => (
                            <Chip key={object.contact_uuid + tag.tag_uuid}
                              variant="default"
                              size="small"
                              label={tag.name}
                              color={this.state.filters.tag_uuid === tag.tag_uuid ? 'secondary' : 'default'}
                              style={{marginRight: 1}} />
                          ))}
                        </TableCell>
                        <TableCell align="right">
                          <Button onClick={(event) => this.openContactDialog(event, object)} size="small" variant="outlined" color="primary" startIcon={<EditIcon />}>Editar</Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        }
        {this.state.objects.length === 0 &&
          <Box py={2}>
            <Alert severity='warning'>
              Aún no hay contactos creados
            </Alert>
          </Box>
        }
        <Dialog open={this.state.html.contact_dialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}>
          <DialogTitle id="form-dialog-title">Detalles del contacto</DialogTitle>
          <DialogContent dividers={true}>
            {this.state.html.contact_dialog_entity !== '' &&
              <form noValidate autoComplete="off" onSubmit={this.handleContactSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField name='first_name'
                      id='first_name'
                      label='Nombres'
                      fullWidth
                      value={this.state.data.first_name}
                      onChange={this.handleInputChange}
                      helperText={this.state.feedback.first_name}
                      error={this.state.error.first_name}
                      required />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name='last_name'
                      id='last_name'
                      label='Apellidos'
                      fullWidth
                      value={this.state.data.last_name}
                      onChange={this.handleInputChange}
                      helperText={this.state.feedback.last_name}
                      error={this.state.error.last_name}
                      required />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <PhoneNumberField
                      name='phone'
                      id='phone'
                      label='Teléfono'
                      onChange={this.handleInputChange}
                      fullWidth
                      value={this.state.data.phone}
                      helperText={this.state.feedback.phone}
                      error={this.state.error.phone}
                      required />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name='custom_id'
                      id='custom_id'
                      label='ID usuario personalizado'
                      fullWidth
                      value={this.state.data.custom_id}
                      onChange={this.handleInputChange}
                      />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete multiple
                      name="lists"
                      options={this.state.lists}
                      getOptionLabel={(option) => option ? option?.name || this.state.lists.filter(l => l.list_uuid === option)[0].name : ''}
                      isOptionEqualToValue={(option, value) => option.list_uuid === value}
                      filterSelectedOptions
                      onChange={(event, values) => this.handleOptionChange('lists', 'list_uuid', values)}
                      value={this.state.data.lists}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Listas"
                          placeholder="Buscar o elegir"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete multiple
                      name="tags"
                      options={this.state.tags}
                      getOptionLabel={(option) => option ? option?.name || this.state.tags.filter(l => l.tag_uuid === option)[0].name : ''}
                      isOptionEqualToValue={(option, value) => option.tag_uuid === value}
                      filterSelectedOptions
                      onChange={(event, values) => this.handleOptionChange('tags', 'tag_uuid', values)}
                      value={this.state.data.tags}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Etiquetas"
                          placeholder="Buscar o elegir"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </form>
            }
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              startIcon={this.state.html.submitting ? <CircularProgress size={20}/> : null}
              disabled={this.state.html.submitting}
              onClick={this.handleContactSubmit}>
              Guardar
            </Button>
            <Button color="secondary" onClick={this.closeContactDialog}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.html.import_dialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth>
          <DialogTitle id="form-dialog-title">Importar contactos</DialogTitle>
          <DialogContent dividers>
            <Stack gap={2}>
              <TextField
                type="file"
                variant="outlined"
                onChange={this.fileHandler}
                accept=".xls,.xlsx"
                fullWidth
              />
              {this.state.importOptions.rows.length === 0 &&
                <Alert variant="standard" severity="info">
                  Debes seleccionar un archivo .xls o .xlsx que será previsualizado a continuación. 
                  Los campos obligatorios son Nombre, Apellido y Teléfono (ej. 56987654321). Opcionalmente es posible agregar Email, Listas y Etiquetas (separadas por comas). 
                </Alert>
              }
              {this.state.importOptions.rows.length > 0 &&
                <>
                  <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                    <Typography variant="body1">Previsalización</Typography>
                    <FormControlLabel control={<Checkbox checked={this.state.importOptions.firstRowHeader} onChange={this.handleImportCheckboxChange} />} label="Ignorar primera fila" />
                  </Stack>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                        {this.state.importOptions.cols.map((col, index) => (
                          <TableCell key={col + index}>
                            <Select
                              value={col}
                              fullWidth
                              onChange={(e) => this.handleImportColsChange(e, index)}
                              size="small"
                              variant="outlined"
                            >
                              <MenuItem value=""></MenuItem>
                              <MenuItem value="first_name">Nombres</MenuItem>
                              <MenuItem value="last_name">Apellidos</MenuItem>
                              <MenuItem value="phone">Teléfono</MenuItem>
                              <MenuItem value="custom_id">ID</MenuItem>
                              <MenuItem value="lists">Listas</MenuItem>
                              <MenuItem value="tags">Etiquetas</MenuItem>
                            </Select>
                          </TableCell>
                        ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.importOptions.rows.map((row, index) => {
                          if (index === 0 && this.state.importOptions.firstRowHeader) {
                            return null;
                          }
                          else if(!row[0]) {
                            return null;
                          }
                          return (
                          <TableRow key={row + index}>
                            <TableCell>{row[0] || ''}</TableCell>
                            <TableCell>{row[1] || ''}</TableCell>
                            <TableCell>{row[2] || ''}</TableCell>
                            <TableCell>{row[3] || ''}</TableCell>
                            <TableCell>{row[4] || ''}</TableCell>
                            <TableCell>{row[5] || ''}</TableCell>
                          </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              }
              {this.state.html.import_results &&
                <Alert variant="standard" severity="info">
                  Se importaron <b>{this.state.importOptions.results.new}</b> nuevos contactos, ignorando <b>{this.state.importOptions.results.existing}</b> existentes y <b>{this.state.importOptions.results.error}</b> filas con errores.
                </Alert>
              }
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              startIcon={this.state.html.submitting ? <CircularProgress size={20}/> : null}
              disabled={this.state.html.submitting}
              onClick={this.handleImportSubmit}
              >
              Confirmar e importar
            </Button>
            <Button color="secondary" onClick={this.closeImportDialog}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default Contacts;
