import React from "react";
import { authenticationService } from 'auth/authenticationService'
import NumberFormat from 'react-number-format';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Box, Stack,
  Table, TableRow, TableHead, TableBody, TableCell,
  IconButton,
  Typography, TextField,
} from '@mui/material';

import ReceiptIcon from '@mui/icons-material/Receipt';


class topupAdminDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topup: null,
      modal: '',
      html: {
        page: 1,
        dialog: false
      },
      importOptions: {
        encodedFile: null,
        payment_uuid: null,
      },
    };

    if (typeof (this.props.refresh_subscription) === 'function') {

    }

    this.paymentDetails = this.paymentDetails.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.updateManualPayment = this.updateManualPayment.bind(this);
    this.createManualPayment = this.createManualPayment.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.fileHandler = this.fileHandler.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  paymentDetails(payment_uuid) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        dialog: true
      }
    }));

    let params = {
      url: '/topup/payment/' + payment_uuid,
      method: 'GET',
    }
    authenticationService.fetchApi(params).then(result => {
      this.setState({ modal: JSON.stringify(result, undefined, 2) });
    });
  }

  handleCloseDialog() {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        dialog: false
      }
    }));
  }

  handleReload() {
    authenticationService.fetchApi({
      url: '/topup/?top_up_uuid=' + this.props.topup.top_up_uuid,
      method: 'GET',
    })
      .then(result => {
        this.setState({ topup: result.data[0] }, () => console.log(this.state));
        this.props.refresh_header(result.data[0]);
      })
  }

  componentDidMount() {
    this.setState({ topup: this.props.topup ? this.props.topup : null })
  }

  componentDidUpdate(prevProps) {
    const matches = (obj, source) =>
      Object.keys(source).every(key => obj.hasOwnProperty(key) && obj[key] === source[key]);

    if (!matches(this.props.topup, prevProps.topup)) {
      this.setState({ topup: this.props.topup ? this.props.topup : null });
    }
  }

  createManualPayment(event) {
    event.preventDefault();

    let data = {
      top_up_uuid: this.state.topup.top_up_uuid,
      method: 'MANUAL',
    }

    authenticationService.fetchApi({
      url: '/topup/payment/',
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(result => {
        this.handleReload();
      });
  }

  updateManualPayment(event, payment_uuid) {
    event.preventDefault();
    authenticationService.fetchApi({
      url: '/topup/manual_payment/',
      method: 'POST',
      body: JSON.stringify({
        payment_uuid: payment_uuid,
        state: 'SUCCESS',
      }),
    })
      .then(result => {
        this.handleReload();
      });
  }

  deleteProduct(product_in_topup_uuid) {
    let params = {
      topup_uuid: this.state.topup.uuid,
      delete: product_in_topup_uuid,
    }

    authenticationService.fetchApi({
      url: '/topup/',
      method: 'POST',
      body: JSON.stringify(params)
    })
      .then(result => {
        if (result.products === null) {
          this.props.refresh_all();
        }
        else {
          this.handleReload();
        }
      });
  }

  toBase64(file) {
    return (
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    )
  }

  fileHandler(event, payment_uuid) {
    let fileObj = event.target.files[0];

    this.toBase64(fileObj).then(encodedFile => {
      this.setState(prevState => ({
        importOptions: {
          ...prevState.importOptions,
          encodedFile: encodedFile,
          payment_uuid: payment_uuid,
        }
      }), () => this.handleUpload());
    });

  }

  handleUpload() {
    authenticationService.fetchApi({
      url: '/company/invoice/',
      method: 'POST',
      body: JSON.stringify(this.state.importOptions),
      // no_error_popup: true,
    })
  }


  render() {
    return (
      <Box>
        {this.state.topup &&
          <Box>
            <Box p={2}>
              <Typography variant='h5'>
                Detalles de la canasta
              </Typography>
            </Box>
            <Box p={2}>
              <Typography variant='overline'>
                Estado de la compra
              </Typography>
              <Typography variant='body2'>
                <b>{this.state.topup.state}</b>
              </Typography>
            </Box>
            <Box p={2}>
              <Typography variant='overline'>
                Comprador
              </Typography>
              <Typography variant='body2'>
                Empresa: {this.state.topup.company.name}
              </Typography>
            </Box>
            <Box p={2}>
              <Typography variant='overline'>
                Monto carga
              </Typography>
              <Typography variant='body2'>
                <NumberFormat displayType={'text'}
                  value={this.state.topup.amount}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'$'} />
              </Typography>
            </Box>
            <Box p={2}>
              <Typography variant='h6'>
                Historial de Pagos
              </Typography>
              <Box p={1}>
                {this.state.topup.state === 'INITIATED' &&
                  <Button onClick={this.createManualPayment} color='primary' variant='contained'>Agregar Pago manual</Button>
                }
              </Box>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Fecha Creación
                      </TableCell>
                      <TableCell>
                        Tipo de pago
                      </TableCell>
                      <TableCell>
                        Monto pago (monto carga)
                      </TableCell>
                      <TableCell>
                        Estado del Pago
                      </TableCell>
                      <TableCell>
                        Factura
                      </TableCell>
                      <TableCell>
                        Acciones
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.topup.payments && this.state.topup.payments.map((payment, index) =>
                      <TableRow key={payment.payment_uuid}>
                        <TableCell>
                          {(new Date(payment.created_at)).toLocaleString('es-CL')}
                        </TableCell>
                        <TableCell>
                          {payment.type}
                        </TableCell>
                        <TableCell>
                          <NumberFormat displayType={'text'}
                            value={payment.amount_with_tax}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'$'} />
                          (<NumberFormat displayType={'text'}
                            value={payment.amount}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'$'} />)
                        </TableCell>
                        <TableCell>
                          {payment.state}
                        </TableCell>
                        <TableCell>

                          {payment.invoice &&
                            <IconButton
                              href={payment.invoice}
                              target="_top"
                              aria-label="factura"
                            >
                              <ReceiptIcon fontSize="small" />
                            </IconButton>
                          }

                        </TableCell>
                        <TableCell>
                          <Stack spacing={1}>
                            {this.state.topup.state === 'IN_REVIEW' && payment.type === 'MANUAL' && payment.amount === this.state.topup.amount && //(index + 1) === this.state.topup.payments.length &&
                              <Button color='primary' variant="contained" size="small" onClick={(event) => this.updateManualPayment(event, payment.payment_uuid)}>Validar Pago</Button>
                            }
                            <Button color="inherit" variant="contained" size="small" onClick={() => this.paymentDetails(payment.payment_uuid)}>Ver detalles</Button>

                            {this.state.topup.state === 'SUCCESS' && payment.amount === this.state.topup.amount &&
                              <div>
                                <Typography>Subir factura:</Typography>

                                <TextField
                                  type="file"
                                  variant="outlined"
                                  onChange={(event) => this.fileHandler(event, payment.payment_uuid)}
                                  accept=".pdf"
                                  fullWidth
                                />
                              </div>
                            }
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Box>
            <Dialog open={this.state.html.dialog}
              onClose={this.handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Detalles de la transacción</DialogTitle>
              <DialogContent>
                <div><pre id="json">{this.state.modal}</pre></div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseDialog} color="primary">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        }
      </Box>
    );
  }
}

export default topupAdminDetails;
