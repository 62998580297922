import React from "react";
import Layout from 'layout/index.js'
import zxcvbn from 'zxcvbn';
import { authenticationService } from 'auth/authenticationService'
import {
  Paper, Box,
  Typography, TextField, Button,
  Switch, FormControlLabel,
  Dialog, DialogContent, DialogActions,
  Fade, Alert
} from '@mui/material';
import { withRouter } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import SecurityIcon from '@mui/icons-material/Security';


class Security extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        password_form: false,
        password_view: false,
        two_factor_form: false,
        two_factor_view: false,
        fade_password_success: false,
      },

      data_password: {
        old_password: '',
        password: '',
        password2: '',
        password_update_date:''
      },
      status:{
        old_password:true,
        password:true,
        password2:true
      },
      feedback:{
        old_password:'',
        password:'',
        password2:''
      },

      data_two_factor: {
        two_factor: false,
        two_factor_phone:'',
        two_factor_validated_phone:'',
      },
      two_factor_token: null,
      dialog:{
        open:false,
        token_input:'',
        sandbox_token:'',
        error_message:'',
        success_message:''
      }

    };
    this.handleLoad = this.handleLoad.bind(this);

    this.handleOldPassword = this.handleOldPassword.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePasswordValidation = this.handlePasswordValidation.bind(this);
    this.handleSubmitPassword = this.handleSubmitPassword.bind(this);

    this.handleEditPasswordForm = this.handleEditPasswordForm.bind(this);
    this.handleCancelPasswordForm = this.handleCancelPasswordForm.bind(this);


    this.handleEditTwoFactorForm = this.handleEditTwoFactorForm.bind(this);
    this.handleCancelTwoFactorForm = this.handleCancelTwoFactorForm.bind(this);
    this.handleTwoFactorPhone = this.handleTwoFactorPhone.bind(this);

    this.handleStartValidatePhone = this.handleStartValidatePhone.bind(this);

    this.handleValidatePhone = this.handleValidatePhone.bind(this);
    this.handleGetCode = this.handleGetCode.bind(this);

    this.handleCloseValidatePhone = this.handleCloseValidatePhone.bind(this);
    

    this.handleTwoFactorSwitch = this.handleTwoFactorSwitch.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

  }

  handleLoad() {
    return (
      authenticationService.fetchApi({
        url: '/auth/security',
        method: 'GET',
      })
        .then(result => {
          this.setState(prevState => ({
            data_password: {
              ...prevState.data_password,
              password_update_date: result.password_update_date
            },
            data_two_factor: {
              ...prevState.data_two_factor,
              two_factor: result.two_factor,
              two_factor_phone: result.two_factor_phone,
              two_factor_validated_phone: result.two_factor_validated_phone
            },
            html: {
              ...prevState.html,
              password_view: true,
              password_form: false,
              two_factor_view: true,
              two_factor_form: false,
            }
          }));
          return (true);
        })
    );
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleEditPasswordForm(event) {
    event.preventDefault();
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        password_form: true,
        password_view: false,
      }
    }));
  }
  handleCancelPasswordForm(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        password_form: false,
        password_view: true,
      },
      data_password: {
        ...prevState.data_password,
        password: '',
        password2: '',
        old_password: '',
      },
    }));
  }
  handleOldPassword(event){
    const { data_password } = { ...this.state };
    const currentState = data_password;
    const { name, value } = event.target;
    currentState[name] = value;
    this.setState({ data_password: currentState });
  }

  handlePassword(value){
    this.setState(prevState => ({data_password: {...prevState.data_password,
      password: value
    }}),
    this.handlePasswordValidation(null,value)
    );
    if(value === ''){
      // console.log(1);
      this.setState(prevState => ({feedback: {...prevState.feedback,
        password: 'Debes ingresar una nueva contraseña'
      }}));
      this.setState(prevState => ({status: {...prevState.status,
        password: false
      }}));
    }
    else{

      let score = zxcvbn(value).score;
      // console.log(score)
      if (score<3){
        this.setState(prevState => ({feedback: {...prevState.feedback,
          password: "Password muy débil. \n Trata con más caractéres, números y/o símbolos"
        }}));
        this.setState(prevState => ({status: {...prevState.status,
          password: false
        }}));
      }
      else{
        this.setState(prevState => ({feedback: {...prevState.feedback,
          password: ''
        }}));
        this.setState(prevState => ({status: {...prevState.status,
          password: true
        }}));
      }
    }
  }

  handlePasswordValidation(value ,p_input=false){
    if(!p_input){
      this.setState(prevState => ({data_password: {...prevState.data_password,
        password2: value
      }}));
      p_input = this.state.data_password.password;
    }
    else{
      value = this.state.data_password.password2
    }

    if(value === p_input && value!==''){
      this.setState(prevState => ({feedback: {...prevState.feedback,
        password2: ''
      }}));
      this.setState(prevState => ({status: {...prevState.status,
        password2: true
      }}));
    }
    else{
      // console.log(1);
      this.setState(prevState => ({feedback: {...prevState.feedback,
        password2: 'Las dos contraseñas deben ser iguales'
      }}));
      this.setState(prevState => ({status: {...prevState.status,
        password2: false
      }}));
    }
  }

  handleSubmitPassword(event) {
    event.preventDefault();

    authenticationService.fetchApi({
      url: '/auth/security/',
      method: 'POST',
      body: JSON.stringify(this.state.data_password),
      no_error_popup:true,
    })
    .then((result) => {

      this.handleLoad();
      
      this.setState(prevState => ({
        data_password: {
          ...prevState.data_password,
          password: '',
          password2: '',
          old_password: '',
        },
        html: {
          ...prevState.html,
          fade_password_success: true,
          
        },
      }),()=>{
        setTimeout(function() {
          this.setState(prevState => ({html: {...prevState.html,
            fade_password_success: false
          }}));
        }.bind(this), 2000);
      });

      

    })
    .catch(result=>{
      if(result['error'] === "WRONG_OLD_PASSWORD"){
        this.setState(prevState => ({feedback: {...prevState.feedback,
          old_password: 'Contraseña actual incorrecta. Si no la recuerdas, trata de recuperar contraseña con tu mail en el siguiente desde https://site.axonio.dev/password_recovery/'
        }}));
        this.setState(prevState => ({status: {...prevState.status,
          old_password: false
        }}));
      }
    });
  }

  //////////////////////
  // Two Factor

  handleEditTwoFactorForm(event) {
    event.preventDefault();
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        two_factor_form: true,
        two_factor_view: false,
      },
      data_two_factor: {
        ...prevState.data_two_factor,
        two_factor_phone: '',
      }
    }));
  }

  handleCancelTwoFactorForm(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        two_factor_form: false,
        two_factor_view: true,
      }
    }), ()=>this.handleLoad());
  }

  handleStartValidatePhone(event) {
    event.preventDefault();

    authenticationService.fetchApi({
      url: '/auth/security/',
      method: 'POST',
      body: JSON.stringify({two_factor_phone: this.state.data_two_factor.two_factor_phone}),
    })
    .then((result) => {
      this.setState(prevState => ({
        dialog: {
          ...prevState.dialog,
          open: true,
          error_message: '',
          token_input: true,
        }
      }),()=>{
        this.handleGetCode();
      });
    })
    .catch(error=>{});
  }

  handleTwoFactorPhone(event) {
    const { data_two_factor } = { ...this.state };
    const currentState = data_two_factor;
    const { name, value } = event.target;
    currentState[name] = value;

    this.setState({ data_two_factor: currentState });
  }


  handleCloseValidatePhone() {
    this.setState(prevState => ({
      dialog: {
        ...prevState.dialog,
        open: false,
        error_message: '',
        token_input: false,
      }
    }));
  }

  handleGetCode() {
    authenticationService.fetchApi({
      url: '/auth/validate_two_factor/',
      method: 'GET',
    })
    .then((result) => {
      
    })
    .catch((result)=>{
      console.log(result);
    });
  }
  handleValidatePhone(event) {
    event.preventDefault();

    if (this.state.sandbox_token !== '') {
      event.preventDefault();
      authenticationService.fetchApi({
        url: '/auth/validate_two_factor/',
        method: 'POST',
        body: JSON.stringify({ two_factor_token: this.state.two_factor_token }),
      })
      .then((result) => {

          this.setState(prevState => ({
            dialog: {
              ...prevState.dialog,
              open: false,
              token_input: false,
              error_message: "",
              success_message: "Número de teléfono validado"
            }
          })
            , () => this.handleLoad()
          );
        
      })
      .catch((result)=>{
        this.setState(prevState => ({
          dialog: {
            ...prevState.dialog,
            token_input: true,
            error_message: "Código inválido",
          }
        }));
      });
    }
  }

  handleTwoFactorSwitch(event) {
    const { data_two_factor } = { ...this.state };
    const currentState = data_two_factor;
    const { name, checked } = event.target;

    currentState[name] = checked;
    this.setState({ data_two_factor: currentState });

    authenticationService.fetchApi({
      url: '/auth/security/',
      method: 'POST',
      body: JSON.stringify({two_factor: checked}),
    })
    .then((result) => {
      if('error' in result){
        return null;
      }
      else{
      }

    })
    .catch(error=>{});

  }
  handleInputChange(event) {
    const { data_two_factor } = { ...this.state };
    const currentState = data_two_factor;
    const { name, value } = event.target;

    currentState[name] = value;
    this.setState({ data_two_factor: currentState });
   
  }

  render() {
    return (
      <Layout title='Mi configuración de seguridad' leftIcon={<SecurityIcon/>}>
        <Paper>
          <Box p={1} m={1}>

            <Typography variant="h6">
              Contraseña
            </Typography>
            {this.state.html.password_form &&
            <form >
              <Box py={1}>
                <TextField label="Actual contraseña"
                  type="password"
                  name="old_password"
                  error={this.state.status.old_password === false ? !this.state.status.old_password : null}
                  value={this.state.data_password.old_password ? this.state.data_password.old_password : ''}
                  helperText={this.state.feedback.old_password}
                  fullWidth
                  onChange={this.handleOldPassword}
                />
              </Box>
              <Box py={1}>
                <TextField label="Contraseña"
                  type="password"
                  name="password"
                  error={this.state.status.password === false ? !this.state.status.password : null}
                  value={this.state.data_password.password}
                  helperText={this.state.feedback.password}
                  fullWidth
                  onChange={(event) => this.handlePassword(event.target.value)}
                />
              </Box>
              <Box py={1}>
                <TextField label="Validar contraseña"
                  type="password"
                  name="password2"
                  value={this.state.data_password.password2}
                  error={this.state.status.password2 === false ? !this.state.status.password2 : null}
                  helperText={this.state.feedback.password2}
                  fullWidth
                  onChange={(event) => this.handlePasswordValidation(event.target.value)}
                />
              </Box>

              <Box p={1}>
                <Button onClick={this.handleSubmitPassword} variant="outlined" color='primary' startIcon={<SaveIcon />}>Guardar Cambios</Button>
                <Button onClick={this.handleCancelPasswordForm}>Cancelar</Button>
              </Box>

            </form>
            }
            {this.state.html.password_view &&
            <Box>
              
              <Box p={1}>
                <Typography color="textSecondary" variant="overline">
                  Password
                </Typography>
                <Typography variant="body1">
                  *********
                </Typography>
                <Fade in={this.state.html.fade_password_success}>
                  <Alert severity='success'>Contraseña modificada correctamente</Alert>
                </Fade>
              </Box>
              <Box p={1}>
                <Button onClick={this.handleEditPasswordForm} variant="outlined" color='primary' startIcon={<EditIcon />}>Modificar</Button>
              </Box>
            </Box>
            }
          </Box>
        </Paper>
        <Paper>
          <Box p={1} m={1}>
            <Typography variant="h6">
              Autenticación de dos factores
            </Typography>

            <Box p={1}>
              <FormControlLabel
                control={
                  <Switch checked={this.state.data_two_factor.two_factor}
                          onChange={(event) => this.handleTwoFactorSwitch(event)}
                          color="primary"
                          name="two_factor"
                          id="two_factor"
                          inputProps={{ 'aria-label': 'primary checkbox' }} />
                }
                label='Autenticación de dos factores (envío de segunda clave por sms)'
              />
            </Box>
            { this.state.data_two_factor.two_factor && this.state.html.two_factor_view &&
            <Box p={1}>
              { (
                this.state.data_two_factor.two_factor_validated_phone &&
                this.state.data_two_factor.two_factor_phone &&
                this.state.data_two_factor.two_factor_phone !=='' && this.state.data_two_factor.two_factor_phone !== null &&
                this.state.data_two_factor.two_factor_validated_phone !=='' && this.state.data_two_factor.two_factor_validated_phone !== null &&
                this.state.data_two_factor.two_factor_validated_phone === this.state.data_two_factor.two_factor_phone
                ) &&
                <Alert severity='success'>{this.state.data_two_factor.two_factor_validated_phone}</Alert>
              }
              { ! (
                this.state.data_two_factor.two_factor_validated_phone &&
                this.state.data_two_factor.two_factor_phone &&
                this.state.data_two_factor.two_factor_phone !=='' && this.state.data_two_factor.two_factor_phone !== null &&
                this.state.data_two_factor.two_factor_validated_phone !=='' && this.state.data_two_factor.two_factor_validated_phone !== null &&
                this.state.data_two_factor.two_factor_validated_phone === this.state.data_two_factor.two_factor_phone
                ) &&
                <Alert severity='warning'>
                  Debes validar un teléfono para poder ocupar los sms de prueba
                </Alert>
              }

              <Box p={1}>
                <Button onClick={this.handleEditTwoFactorForm} variant="outlined" color='primary' startIcon={<EditIcon />}>Ingresar un nuevo teléfono para validación de dos factores</Button>
              </Box>

            </Box>
            }

            { this.state.data_two_factor.two_factor && this.state.html.two_factor_form &&
            <Box p={1}>
              <TextField  label="Ingresa un nuevo número de teléfono"
                          type="two_factor_phone"
                          name="two_factor_phone"
                          value={ this.state.data_two_factor.two_factor_phone ? this.state.data_two_factor.two_factor_phone:''}
                          fullWidth
                          onChange={(event) => this.handleInputChange(event)}
                        />
              <Box p={1}>
                <Button onClick={this.handleStartValidatePhone} variant="outlined" color='primary' startIcon={<EditIcon />}>Validar Teléfono</Button>
                <Button onClick={this.handleCancelTwoFactorForm} variant="outlined" color='secondary'>Cancelar</Button>
                
              </Box>
            </Box>
            }

          </Box>

          <Dialog open={this.state.dialog.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              {this.state.dialog.token_input &&
                <Box>
                  <Typography variant="h6">
                    Validación de teléfono
                  </Typography>
                  <Box p={1}>
                    <Typography variant="body2">
                      Ingresa el código de validación que envíamos a tu teléfono
                    </Typography>
                  </Box>
                  <Box p={1}>
                    <TextField name='sandbox_token'
                      id='sandbox_token'
                      className='form-control'
                      fullWidth={true}
                      label='Código de validación'
                      value={this.state.sandbox_token}
                      onChange={(event) => this.setState({ two_factor_token: event.target.value })} />
                  </Box>
                  <Box p={1}>
                    <Button onClick={(event) => this.handleValidatePhone(event)} color="primary" variant='contained'>
                      Validar
                    </Button>
                  </Box>
                </Box>
              }
              {this.state.dialog.error_message !== '' &&
                <Box>
                  <Alert severity='error'>{this.state.dialog.error_message}</Alert>
                </Box>
              }
              {this.state.dialog.success_message !== '' &&
                <Box>
                  <Alert severity='success'>{this.state.dialog.success_message}</Alert>

                  <Button onClick={() => this.handleCloseValidatePhone()} color="secondary">
                    Salir
                  </Button>
                </Box>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleCloseValidatePhone()} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Layout>
    );
  }
}

export default withRouter(Security);
