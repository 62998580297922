import React from 'react';
import LoginLayout from 'layout/LoginLayout'
import {Link, withRouter} from 'react-router-dom';
import {authenticationService} from 'auth/authenticationService'
import {
  Button, Box, Typography,
} from '@mui/material';
import TagManager from 'react-gtm-module';


class ValidateUser extends React.Component {
  // This syntax ensures `this` is bound within handleClick.
  // Warning: this is *experimental* syntax.

  constructor(props) {
    super(props);

    this.state = {
      html: {
        success: false,
        fail: false,
      },
    };

    this.handleLoad = this.handleLoad.bind(this);
  }

  handleLoad(token) {
    ;
    authenticationService.validateUser(token)
        .then( (response) => {
          if (response) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'validate-user',
                pagePath: '/validate_user/token',
                pageTitle: 'Usuario Creado',
              },
            });

            this.setState({html:
          {
            success: true,
            fail: false,
          },
            });
          } else {
            this.setState({html:
          {
            success: false,
            fail: true,
          },
            });
          }
        });
  }

  componentDidMount() {
    this.handleLoad(this.props.match.params.token);
  }

  render() {
    return (
      <LoginLayout title='Validar usuario'>
        { this.state.html.success &&
      <Box px={1} m={1}>
        <Typography variant='h5'>
          Usuario Validado
        </Typography>
        <Box p={1}>
          <Typography variant='body2'>
            Haz click en el siguiente botón para iniciar sesión.
          </Typography>
        </Box>
        <Box>
          <Button component={Link} color='primary' variant='contained' to='/login'>Iniciar Sesión</Button>
        </Box>
      </Box>
        }
        {this.state.html.fail &&
      <Box p={1} m={1}>
        <Typography variant='h5'>
          No se pudo validar el usuario
        </Typography>
        <Box p={2}>
          <Typography variant='body2'>
            Escríbenos en el chat para que te podamos orientar
          </Typography>
        </Box>
      </Box>
        }
      </LoginLayout>
    );
  }
}

export default withRouter(ValidateUser);
