import React from "react";

import { authenticationService } from 'auth/authenticationService'
import Ivr from "./Ivr.js";

import {
  Box, Alert,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Typography, FormControlLabel,
  TextField, Button, Switch,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";


class Ivrs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        tab_index: 0,
        sound_dialog: false,
        sound_dialog_entity: '',
        sound_dialog_field: '',
      },
      data: {
        sound: {
          sound_uuid: '',
          name: '',
          file: '',
          encodedFile: '',
          text: '',
          use_tts: false,
        }
      },
      agents: {},
      ivrs: {},
      numbers: {},
      sounds: {},
      teams: {},
      audio: '',
      current_audio_text: '',
    };

    this.list = this.list.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
    this.openSoundDialog = this.openSoundDialog.bind(this);
    this.closeSoundDialog = this.closeSoundDialog.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTextSpeak = this.handleTextSpeak.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.toBase64 = this.toBase64.bind(this);
    this.handleSoundSubmit = this.handleSoundSubmit.bind(this);
  }

  componentDidMount() {
    this.handleLoad();
  }

  handleLoad(editing = false, value = null) {
    return (
      authenticationService.fetchApi({
        url: '/voice_config',
        method: 'GET',
      })
        .then(result => {
          const { data } = { ...this.state };

          if (editing) {
            data.sound = {
              sound_uuid: '',
              name: '',
              file: '',
              encodedFile: '',
              text: '',
              use_tts: false,
            };
          }

          this.setState(prevState => ({
            agents: result.agents,
            numbers: result.numbers,
            sounds: result.sounds,
            teams: result.teams,
            data: data,
            html: {
              ...prevState.html,
              sound_dialog: false,
            }
          }), () => {
            // Seleccionar el audio recién creado en el formulario del IVR
            if (editing) {
              // Seleccionar el audio recién creado en el formulario del IVR
              this.state.html.sound_dialog_entity.setState(prevState => ({
                data: {
                  ...prevState.data,
                  [this.state.html.sound_dialog_field]: value
                }
              }), () => {
                this.setState(prevState => ({
                  html: {
                    ...prevState.html,
                    sound_dialog_entity: '',
                    sound_dialog_field: '',
                  }
                }));
              });
            }
            else {
              this.list();
            }
          });

          return (true);
        })
    );
  }

  list() {
    authenticationService.fetchApi({
      url: '/voice_config/ivr',
      method: 'GET',
    })
      .then(result => {
        this.setState({ ivrs: {} }, () => {this.setState({ ivrs: result.data})});
      })
  }

  handleAddNew() {
    this.setState(prevState => ({
      ivrs: [
        ...prevState.ivrs,
        {
          ivr_uuid: '',
          name: '',
          elastic_number: '',
          menu_sound: '',
          invalid_sound: '',
          choices: [],
        },
      ]
    }));
  }

  openSoundDialog(event, entity, field) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        sound_dialog: true,
        sound_dialog_entity: entity,
        sound_dialog_field: field,
      }
    }));
  }

  closeSoundDialog(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        sound_dialog: false,
        sound_dialog_entity: '',
        sound_dialog_field: '',
      }
    }));
  }

  handleInputChange(event, entity) {
    const { data } = { ...this.state };
    const currentState = data[entity];
    const { name, value, checked } = event.target;
    currentState[name] = event.target.type === 'checkbox' ? checked : value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [entity]: currentState
      }
    }));
  }

  handleTextSpeak(event) {
    if (this.state.data.sound.text === this.state.current_audio_text) {
      this.state.audio.play();
    }
    else {
      authenticationService.fetchApi({
        url: '/voice_config/tts',
        method: 'POST',
        body: JSON.stringify({ text: this.state.data.sound.text }),
        ContentType: 'mp3',
      })
        .then(result => {
          if (result.ok) {
            var reader = result.body.getReader();

            return new ReadableStream({
              start(controller) {
                return pump();

                function pump() {
                  return reader.read().then(({ done, value }) => {
                    // When no more data needs to be consumed, close the stream
                    if (done) {
                      controller.close();
                      return;
                    }

                    // Enqueue the next data chunk into our target stream
                    controller.enqueue(value);
                    return pump();
                  });
                }
              }
            });
          }
        })
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
          // Necesito esto para convertir en base64
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            const { data } = { ...this.state };
            const currentState = data['sound'];
            currentState['encodedFile'] = base64data;

            this.setState(prevState => ({
              data: {
                ...prevState.data,
                sound: currentState
              }
            }));

            return;
          }
            .bind(this);

          return URL.createObjectURL(blob);
        })
        .then(url => {
          let audio = new Audio();
          audio.src = url;
          this.setState({ audio: audio, current_audio_text: this.state.data.sound.text }, () => {
            this.state.audio.play();
          });


        })
        .catch(err => console.error(err));
    }
  }

  toBase64(file) {
    return (
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    )
  }

  handleFile(event, entity) {
    const file = event.target.files[0];

    this.toBase64(file).then(encodedFile => {
      const { data } = { ...this.state };
      const currentState = data[entity];
      currentState['file'] = file.filename;
      currentState['encodedFile'] = encodedFile;

      this.setState(prevState => ({
        data: {
          ...prevState.data,
          [entity]: currentState,
        }
      }), () => console.log(this.state.data));
    });
  }

  handleSoundSubmit(event) {
    event.preventDefault();

    authenticationService.fetchApi({
      url: '/voice_config/sound',
      method: 'POST',
      body: JSON.stringify(this.state.data.sound),
    })
      .then(result => {
        // Seleccionar el audio recién creado en el formulario del IVR
        this.handleLoad(true, result.sound_uuid);
      });
  }

  render() {
    return (
      <Box m={2}>
        <Box textAlign="right">
          <Button onClick={this.handleAddNew} variant="contained" color="primary" startIcon={<AddCircleIcon />}>Crear IVR</Button>
        </Box>
        <Box my={2} pb={2}>
          {(this.state.ivrs && this.state.ivrs.length > 0) ?
            this.state.ivrs.map((ivr, index) => {
              return <Ivr key={ivr.ivr_uuid}
                data={ivr}
                parent={this} />
            })
            :
            <Alert severity="info">Aún no existe IVR's creados</Alert>
          }
        </Box>
        <Dialog open={this.state.html.sound_dialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}>
          <DialogTitle id="form-dialog-title">Subir o crear audio</DialogTitle>
          <DialogContent dividers={true}>
            <Box p={1}>
              <TextField name='name'
                id='sound_name'
                label='Nombre del audio'
                fullWidth={true}
                variant="outlined"
                value={this.state.data.sound.name}
                onChange={(event) => this.handleInputChange(event, 'sound')}
                required />
            </Box>
            <Box p={1}>
              <Typography variant="overline">Cargar archivo mp3</Typography>
              <TextField type="file"
                id="file"
                name="file"
                accept=".mp3,audio/*"
                fullWidth={true}
                variant="outlined"
                onChange={(event) => this.handleFile(event, 'sound')} />
              <Button startIcon={<PlayArrowIcon />} disabled={this.state.data.sound.file === ''}>Reproducir</Button>
            </Box>
            <Box p={1}>
              <Typography variant="overline">Ó Utilizar función texto a audio</Typography>
              <TextField multiline
                id="sound_text"
                name="text"
                label="Introduzca el texto a reproducir"
                fullWidth={true}
                variant="outlined"
                rows={3}
                value={this.state.data.sound.text}
                inputProps={{ maxLength: 255 }}
                onChange={(event) => this.handleInputChange(event, 'sound')}
              />
              <Button onClick={this.handleTextSpeak} startIcon={<PlayArrowIcon />} disabled={this.state.data.sound.text === ''}>Reproducir</Button>
              <FormControlLabel label="Utilizar audio generado"
                control={<Switch checked={this.state.data.sound.use_tts} name="use_tts" onChange={(event) => this.handleInputChange(event, 'sound')} />}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="outlined" onClick={this.handleSoundSubmit}>
              Guardar
            </Button>
            <Button color="secondary" onClick={this.closeSoundDialog}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default Ivrs;
