import React from "react";
import Layout from 'layout/index.js'
import { withRouter } from 'react-router-dom';
import {PhoneNumber} from 'adds/PhoneNumber.js'
import { authenticationService } from 'auth/authenticationService'
import {
  Paper, Box, Typography, TextField,
  TableSortLabel,TablePagination,TableContainer,Table,TableRow,TableHead,TableBody,TableCell,
  Card, CardActionArea, CardContent, CardActions,
  Divider, Grid, IconButton, Alert, Chip,
  Autocomplete, ToggleButtonGroup, ToggleButton, Button,
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';

import ClearIcon from "@mui/icons-material/Clear";
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import SearchIcon from '@mui/icons-material/Search';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const headCells = [
  { id: "created_at",numeric: false,disablePadding: false,label: "Fecha de creación", sorting: true},
  { id: "src_phone", numeric: false, disablePadding: false, label: "Número de origen", sorting: true},
  { id: "dst_phone", numeric: false, disablePadding: false, label: "Número de destino", sorting: true},
  { id: "state", numeric: false, disablePadding: false, label: "Estado SMS", sorting: false},
  { id: "view", numeric: false, disablePadding: false, label: "Detalles SMS", sorting: false}
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          !headCell.sorting ? <TableCell key={headCell.id}>{headCell.label}</TableCell> : (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className='hideSort'>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

//
// function encodeQueryData(data) {
//   const ret = [];
//   for (let d in data)
//     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
//   return ret.join('&');
// }


class IncomingSms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      objects: [],
      statistics: [],
      elastic_numbers:[],

      html: {
        page: 1,
        list: false,
        credential_error: false,
        view_type:'cards',
      },
      filters: {
        start_date:null,
        end_date:null,
      },
      pagination:{
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by:'created_at',
        order:'desc',
      },
      dialog: {
        open: false,
        content: null,
      },
      company: null,
      companies:[]
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleViewType = this.handleViewType.bind(this)
    this.handleDateFilter = this.handleDateFilter.bind(this)
    this.resetDate = this.resetDate.bind(this)


    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  handleChangePage(event, page){
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
          page: page,
      }}), ()=> this.handleLoad());

  }
  handleChangeRowsPerPage(event){
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
          rows_per_page: event.target.value,
      }}), ()=> this.handleLoad());
  }

  handleSort(event, property){
    const isAsc = this.state.sorting.order_by === property && this.state.sorting.order === "asc";
    this.setState(prevState => ({
      sorting: {
          ...prevState.sorting,
          order: isAsc ? "desc" : "asc",
          order_by: property,
    }}),()=>this.handleLoad()
    );

  }


  handleLoad(page=null){

    var params = {
        page: this.state.pagination.page+1,
        order_by: this.state.sorting.order_by,
        order: this.state.sorting.order,
        rows_per_page: this.state.pagination.rows_per_page,
        statistics: true,
        type:'MO'
    };

    if(this.state.company){
      params['company_uuid'] = this.state.company.company_uuid;
    }

    if(Object.keys(this.state.filters).length !== 0){
      params['filters'] = this.state.filters;
    }

    // var query = encodeQueryData(params)

    authenticationService.fetchApi({
      url: '/sms/' ,
      method: 'POST',
      api:true,
      body: JSON.stringify(params),
      no_error_popup: true
    })
    .then(result=>{
        this.setState(prevState => ({
          html: {
            ...prevState.html,
            credential_error: false,
          },
          pagination: {
            ...prevState.pagination,
            rows_num: result.rows_num
          },
          objects:result.data,
          elastic_numbers:result.elastic_numbers
        }));
    })
    .catch(response=>{
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          credential_error: true,
        },
      }));
    });
  }

  /////////////////////
  // FILTERS
  getObjects(){return(
    authenticationService.fetchApi({
      url:'/message_filters/' + this.state.company.company_uuid,
      method: 'GET',
    })
    .then(result=>{
      this.setState({credentials:result.credentials});
      this.setState({campaigns:result.campaigns});
    })
  )}


  handleDateFilter(name, date=null) {
    const { filters } = { ...this.state };
    const currentState = filters
    // console.log(date)
    // console.log(name)
    currentState[name] = date;
    this.setState({ filters: currentState },()=> this.handleLoad());

  }
  resetDate(name) {
    const { filters } = { ...this.state };
    const currentState = filters
    currentState[name] = null;
    this.setState({ filters: currentState },()=> this.handleLoad());

  }
  // END FILTERS
  /////////////////////////////////

  componentDidMount() {
    let get_credential = authenticationService.getValidCredential()
    
    if(!get_credential.error){
      this.setState(prevState=>({
        html: { ...prevState.html,
          credential_error: false,
        },
      }),()=>{
        this.setState({ company: authenticationService.currentUser.person_object.company_object },
          () =>{
            this.getObjects().then(this.handleLoad())
            }
        )
      });
      
    }
    else{
      this.setState(prevState=>({
        html: { ...prevState.html,
          credential_error: true,
        },
      }));
    }
    

  }

  handleViewType(event, newAlignment){
      this.setState(prevState => ({html: {...prevState.html,
        view_type: newAlignment
      }}));
  }

  /////////////////////////////
  // DIALOG
  /////////////////////////////
  smsDetails(index) {
    this.setState(prevState => ({
      dialog: {
        ...prevState.dialog,
        open: true,
        content: index
      }
    }));

  }

  handleCloseDialog() {
    this.setState(prevState => ({
      dialog: {
        ...prevState.dialog,
        open: false,
        content: null
      }
    }));
  }



  render() {
    // console.log(this.props);
    // const { params } = this.props.match
    return (
      <Layout title='SMS entrantes'>

        { !this.state.html.credential_error &&
        <Paper>
          <Box p={2} m={2}>
            <Box>
              <Typography variant="h6">
                Filtros
              </Typography>
            </Box>

            { ['admin'].filter(x => authenticationService.currentUser.groups.includes(x)).length >0 &&
            <Box>
                <Autocomplete id="company"
                              value = {this.state.company?this.state.company:null}
                              options={this.state.companies}
                              label='Filtar por empresa'
                              getOptionLabel={(option) => option.name}
                              isOptionEqualToValue={(option, value) => option.name === value.name}
                              onInputChange={this.handleLoadCompanies}
                              onChange={(event, value, reason)=>this.handleCompanyChange(event, value, reason)}
                              renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filtar por empresa"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>

                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                              )}
                />
            </Box>
            }

            { this.state.company &&
            <Box>
              <Grid container spacing={1}>
                <Grid item md={6} sm={12}>
                  <DateTimePicker name='start_date'
                                  id='start_date'
                                  label='Filtra por fecha desde'
                                  fullWidth
                                  ampm={false}
                                  onChange={(date)=>this.handleDateFilter('start_date',date)}
                                  value={this.state.filters.start_date}
                                  variant='inline'
                                  format="DD-MM-YYYY, HH:mm:ss"
                                  renderInput={(props) => <TextField {...props} />}
                                  InputProps={{
                                    endAdornment: (
                                      <IconButton
                                        // style={{ padding: 0 }}
                                        edge="end"
                                        size="small"
                                        disabled={!this.state.filters.start_date}
                                        onClick={() => this.resetDate('start_date')}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    ),
                                   }}
                                  />

                </Grid>
                <Grid item md={6} sm={12}>
                  <DateTimePicker name='end_date'
                                  id='end_date'
                                  label='Filtra por fecha hasta'
                                  fullWidth
                                  ampm={false}
                                  onChange={(date)=>this.handleDateFilter('end_date',date)}
                                  value={this.state.filters.end_date}
                                  variant='inline'
                                  format="DD-MM-YYYY, HH:mm:ss"
                                  renderInput={(props) => <TextField {...props} />}
                                  InputProps={{
                                    endAdornment: (
                                      <IconButton
                                        // style={{ padding: 0 }}
                                        edge="end"
                                        size="small"
                                        disabled={!this.state.filters.end_date}
                                        onClick={() => this.resetDate('end_date')}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    ),
                                   }}
                                  />
                </Grid>
              </Grid>

            </Box>
            }

            <br/>

            {this.state.objects.length>0 &&
            <Box>
              <Box p={2} align='right'>
                <Grid container>
                  <Grid item md={6} align='left'>
                    <ToggleButtonGroup
                      value={this.state.html.view_type}
                      exclusive
                      onChange={this.handleViewType}
                      size='small'
                    >
                      <ToggleButton value="cards" aria-label="Cards">
                        <DashboardIcon fontSize='small'/>
                      </ToggleButton>
                      <ToggleButton value="list" aria-label="List">
                        <FormatAlignJustifyIcon fontSize='small'/>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item md={6}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10]}
                      component="div"
                      labelRowsPerPage='Resultados por página'
                      count={this.state.pagination.rows_num}
                      rowsPerPage={this.state.pagination.rows_per_page}
                      page={this.state.pagination.page}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                  </Grid>
                </Grid>
              </Box>
              { this.state.html.view_type === 'list' &&
              <Box p={1}>
                <TableContainer>
                  <Table size="small">
                    <EnhancedTableHead
                      order={this.state.sorting.order}
                      orderBy={this.state.sorting.order_by}
                      onRequestSort={this.handleSort}
                    />
                    <TableBody>
                    {this.state.objects.map((object) => {
                      return(
                        <TableRow key={object.message_uuid}>
                          <TableCell>
                            {(new Date(object.created_at)).toLocaleString('es-CL')}
                          </TableCell>
                          <TableCell>
                          <PhoneNumber value={object.src_phone} />
                          </TableCell>
                          <TableCell>
                            <PhoneNumber value={object.dst_phone} />
                          </TableCell>
                          <TableCell>
                            {object.status==='RECEIVED'?
                              <Chip label={'Recibido'} style={{ backgroundColor: '#DDFFDD', color: '#448844' }} />:
                              <Chip label={'Mensaje recibido pero con errores'} style={{ backgroundColor: '#FFFFDD', color: '#884444' }} />
                            }
                          </TableCell>
                          <TableCell>
                            {object.body}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              }
              { this.state.html.view_type === 'cards' &&
              <Box sx={{margin: 10}}>
                <Grid container spacing={2}>
                {this.state.objects.length>0 && this.state.objects.map((object,index) => {
                return(
                  <Grid item lg={4} md={12} key={object.message_uuid}>
                    <Card variant="outlined">
                      <CardActionArea>
                        <CardContent>
                          <Box>
                            <Typography gutterBottom variant="caption" color="textSecondary">
                              Teléfono Orígen
                            </Typography>
                            <Box>
                              <Typography variant="subtitle1">
                                <PhoneNumber value={object.src_phone} />
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography gutterBottom variant="caption" color="textSecondary">
                              Teléfono destino
                            </Typography>
                            <Box>
                              <Typography variant="subtitle1">
                                <PhoneNumber value={object.dst_phone} />
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography gutterBottom variant="caption" color="textSecondary">
                              Estado: &nbsp;
                            </Typography>

                            <Box>
                              {object.status==='RECEIVED'?
                              <Chip label={'Recibido'} style={{ backgroundColor: '#DDFFDD', color: '#448844' }} />:
                              <Chip label={'Mensaje recibido pero con errores'} style={{ backgroundColor: '#FFFFDD', color: '#884444' }} />
                              }
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="caption" color="textSecondary" gutterBottom>
                              Fecha creación:
                            </Typography>
                            <Box>
                              <Typography variant="body2" gutterBottom>
                                {(new Date(object.created_at)).toLocaleString('es-CL')}
                              </Typography>
                            </Box>
                          </Box>
                          <br/>

                          <Typography gutterBottom variant="caption" color="textSecondary">
                            Contenido del SMS
                          </Typography>
                          <Typography variant="body2">
                            {object.body}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <Divider />
                      <CardActions>
                        <Button startIcon={<SearchIcon />} onClick={(event) => this.smsDetails(index)} color='primary'>Ver Detalles</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                )})}
                </Grid>
              </Box>
              }

            </Box>
            }
            {this.state.objects.length===0 &&
            <Box p={1}>
              <Alert severity='warning'>
                Búsqueda sin resultados
              </Alert>
            </Box>
            }
            {this.state.elastic_numbers.length===0 &&
            <Box p={1}>
              <Alert severity='warning'>
                Para tener mensajes entrantes, necesitas tener números propios. Contáctanos en el chat y te podremos ayudar.
              </Alert>
            </Box>
            }
          </Box>
        </Paper>

        }

        { this.state.html.credential_error &&
          <Alert severity='error'>Para desplegar esta vista debes seleccionar una credencial por defecto que esté activa y no-expirada, en la sección de Mi Empresa.</Alert>
        }
        <Dialog open={this.state.dialog.open}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Ver Detalles</DialogTitle>
          <DialogContent>
            <p><pre id="json">{JSON.stringify(this.state.objects[this.state.dialog.content], undefined, 2)}</pre></p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Layout>
    );
  }
}

export default withRouter(IncomingSms);
