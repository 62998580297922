import React from 'react';
import Layout from 'layout';
import { withRouter } from 'react-router-dom';
import { authenticationService } from 'auth/authenticationService'
import {  
  Box, Button
} from '@mui/material';

class TestErrors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_admin:false,
      is_company:false,
      is_agent: false,
    };

    this.test_not_fount = this.test_not_fount.bind(this);
    this.test_error = this.test_error.bind(this);
    this.test_empty_error = this.test_empty_error.bind(this);

  }

  componentDidMount(){
    let groups = authenticationService.currentUser.groups

    this.setState({is_admin: ['admin'].filter(x => groups.includes(x)).length >0})
    this.setState({is_company: ['company'].filter(x => groups.includes(x)).length >0})
    this.setState({is_agent: ['agent'].filter(x => groups.includes(x)).length >0})
    // console.log(authenticationService.currentUser)
  }
  test_not_fount(event){
    authenticationService.fetchApi({
      url: '/test/',
      method: 'GET',
    })
    .then(result => {
      console.log("Entra al then: ",result)
    })
    .catch(err => {console.log("Entra al catch: ",err)});

  }
  test_error(event){
    authenticationService.fetchApi({
      url: '/test_error/',
      method: 'GET'
    })
    .then(result => {
      console.log("Entra al then: ",result)
    })
    .catch(err => {
      console.log("Entra al catch: ",err)
    });
  }
  test_empty_error(event){
    authenticationService.fetchApi({
      url: '/test_empty_error/',
      method: 'GET'
    })
    .then(result => {
      console.log("Entra al then: ",result)
    })
    .catch(err => {
      console.log("Entra al catch: ",err)
    });
  }
  render() {
    return (
      <Layout title='Dasboard'>
        <Box p={1} align='left'>
          <Button onClick={this.test_not_fount} color='secondary'>Test Not Found</Button>
        </Box>
        <Box p={1} align='left'>
          <Button onClick={this.test_error} color='secondary'>Test ERROR</Button>
        </Box>
        <Box p={1} align='left'>
          <Button onClick={this.test_empty_error} color='secondary'>Test empty ERROR</Button>
        </Box>
      </Layout>
    );
  }
}

export default withRouter(TestErrors);
