import React from "react";
import { Link } from 'react-router-dom';
import {
  Grid, Box,
  Typography, Chip, Tooltip, IconButton,
  Button, ListItem, TextField, Switch
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from "@mui/icons-material/Warning";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import HelpIcon from '@mui/icons-material/Help';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CodeIcon from '@mui/icons-material/Code';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import { authenticationService } from 'auth/authenticationService'
import moment from "moment";


class Credential extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        credential_uuid: this.props.data.credential_uuid,
        token: this.props.data.token,
        title: this.props.data.title ? this.props.data.title : '',
        expiration_date: this.props.data.expiration_date ? this.props.data.expiration_date : '',
        status: this.props.data.status ? this.props.data.status : false,
        type: this.props.data.type ? this.props.data.type : '',
        site_default: this.props.data.site_default ? this.props.data.site_default : false,
        auto_block_illegal_sending_hours: this.props.data.auto_block_illegal_sending_hours ? this.props.data.auto_block_illegal_sending_hours : false,
      },
      error: {
        title: false,
        expiration_date: false,
      },
      feedback: {
        title: '',
        expiration_date: '',
      },
      html: {
        form_display: 'display-none',
        list_display: 'display-inline',
      }
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEditForm = this.handleEditForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    if (this.props.data.credential_uuid === '') {
      let body = {
        do: 'create'
      }
      authenticationService.fetchApi({
        url: '/company/credential/',
        method: 'POST',
        body: JSON.stringify(body),
      })
        .then(result => {
          this.setState({ data: result });
          this.setState({
            html: {
              form_display: 'display-inline',
              list_display: 'display-none',
            }
          });
        });
    }
  }

  handleInputChange(event) {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value, checked } = event.target;

    if (name === 'status') {
      currentState[name] = checked;
    }
    else if (name === 'site_default') {
      currentState[name] = checked;
    }
    else {
      currentState[name] = value;
    }

    this.setState({ data: currentState });
  }

  handleDateChange(date = null) {
    const { data } = { ...this.state };
    const currentState = data
    currentState['expiration_date'] = date;
    this.setState({ data: currentState });
  }

  handleDelete(event) {
    event.preventDefault();

    authenticationService.fetchApi({
      url: '/company/credential/',
      method: 'DELETE',
      body: JSON.stringify(this.state.data),
    })
      .then(result => {
        authenticationService.refresh().then( () => {
          this.props.removeItem()
          }
        )
      });
  }

  handleSubmit(event) {
    event.preventDefault();

    let ok = true;

    // title
    if (!this.state.data.title || this.state.data.title === '') {
      ok = false;
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          title: true
        },
        feedback: {
          ...prevState.feedback,
          title: 'Debes ingresar un título'
        }
      }));
    }
    else {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          title: false
        },
        feedback: {
          ...prevState.feedback,
          title: ''
        }
      }));
    }

    // expiration_date
    if (!this.state.data.expiration_date || this.state.data.expiration_date === '') {
      ok = false;
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          expiration_date: true
        },
        feedback: {
          ...prevState.feedback,
          expiration_date: 'Debes ingresar una fecha de expiración'
        }
      }));
    }
    else {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          expiration_date: false
        },
        feedback: {
          ...prevState.feedback,
          expiration_date: ''
        }
      }));
    }

    if (!ok) {
      return false;
    }

    authenticationService.fetchApi({
      url: '/company/credential/',
      method: 'POST',
      body: JSON.stringify(this.state.data),
    })
      .then(result => {
        this.setState({
          html: {
            form_display: 'display-none',
            list_display: 'display-inline',
          }
        });

        authenticationService.refresh().then(
          this.props.list()
        )
      });
  }

  handleEditForm(event, data = null) {
    event.preventDefault();

    this.setState({
      html: {
        form_display: 'display-inline',
        list_display: 'display-none',
      }
    });
  }

  handleCancel(event) {
    event.preventDefault();

    this.setState({
      html: {
        form_display: 'display-none',
        list_display: 'display-inline',
      }
    }, () => this.props.list());
  }

  render() {
    return (
      <ListItem disableGutters={true}>
        <Box className={this.state.html.list_display} border={1} borderColor="grey.400" borderRadius="5px">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box p={1}>
                <Typography variant='overline' color="textSecondary">Nombre</Typography>
                <Typography variant="body1">{this.state.data.title || '-'}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box p={1}>
                <Typography variant="overline" color="textSecondary">Fecha de Expiración</Typography>
                <Typography variant="body1">
                  {this.state.data.expiration_date ? (new Date(this.state.data.expiration_date)).toLocaleString('es-CL') : '-'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box p={1}>
                <Typography variant='overline' color="textSecondary">Estado de la credencial</Typography>
                <Box>
                  {this.state.data.status && this.state.data.expiration_date && moment().isBefore(this.state.data.expiration_date) ?
                    <Chip size="small" icon={<DoneIcon />} label="Activa" style={{ backgroundColor: 'lightgreen' }} />
                    :
                    <Chip size="small" icon={<WarningIcon />} label={(moment().isAfter(this.state.data.expiration_date) ? "Expirada" : "Inactiva")} />
                  }
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box p={1}>
                <Typography variant='overline' color="textSecondary">Credencial por defecto</Typography>
                <Tooltip title="Credencial por defecto para usar las funciones API dentro del portal">
                  <IconButton size="small" aria-label="delete">
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
                <Box>
                  <Chip size="small"
                    icon={(this.state.data.site_default) ? <DoneIcon /> : <WarningIcon />}
                    label={(this.state.data.site_default) ? "Sí" : "No"}
                    style={(this.state.data.site_default) ? { backgroundColor: 'lightgreen' } : {}}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box p={1}>
                <Typography variant='overline' color="textSecondary">Bloquear en uso de horario no hábil</Typography>
                <Tooltip title="Si es activado no se enviarán mensajes fuera de horario según la regulación local">
                  <IconButton size="small" aria-label="delete">
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
                <Box>
                  <Chip size="small"
                    icon={(this.state.data.auto_block_illegal_sending_hours) ? <DoneIcon /> : <WarningIcon />}
                    label={(this.state.data.auto_block_illegal_sending_hours) ? "Sí" : "No"}
                    style={(this.state.data.auto_block_illegal_sending_hours) ? { backgroundColor: 'lightgreen' } : {}}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box p={1}>
                <Typography variant='overline' color="textSecondary">Token</Typography>
                <Typography variant="body1" style={{ wordWrap: "break-word" }}>{this.state.data.token}</Typography>
                <Tooltip title="Copiar token">
                  <IconButton
                    onClick={() => { navigator.clipboard.writeText("axoniotoken " + this.state.data.token) }}
                    aria-label="copiar">
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Probar en documentación de API">
                  <IconButton
                    component={Link}
                    target="_blank"
                    to='/api_v1'
                    aria-label="api">
                    <CodeIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Ver video tutorial">
                  <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank"  rel="noreferrer" >
                    <IconButton aria-label="api">
                      <OndemandVideoIcon fontSize="small" />
                    </IconButton>
                  </a>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>

          {this.state.data.type !== 'SANDBOX' &&
            <Box p={1}>
              <Button onClick={this.handleEditForm} color='primary' variant="outlined" startIcon={<EditIcon />}>Modificar</Button>
              <Button onClick={this.handleDelete} color='secondary' variant="outlined" startIcon={<DeleteIcon />} style={{ marginLeft: "8px" }}>Eliminar</Button>
            </Box>
          }
        </Box>


        <Box className={this.state.html.form_display} border={1} borderColor="grey.500" borderRadius="5px">
          <form>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <TextField name='title'
                    id='title'
                    error={this.state.error.title}
                    helperText={this.state.feedback.title}
                    className='form-control'
                    fullWidth={true}
                    label='Nombre'
                    value={this.state.data.title ? this.state.data.title : ''}
                    onChange={this.handleInputChange} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <DateTimePicker name='expiration_date'
                    id='expiration_date'
                    error={this.state.error.expiration_date}
                    helperText={this.state.feedback.expiration_date}
                    label='Fecha de Expiración'
                    fullWidth
                    ampm={false}
                    onChange={this.handleDateChange}
                    value={this.state.data.expiration_date}
                    variant='inline'
                    disablePast
                    format="DD-MM-YYYY, HH:mm:ss"
                    renderInput={(props) => <TextField {...props} />}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <Typography variant='overline'>Estado de la credencial</Typography>
                  <Box>
                    <Switch checked={this.state.data.status}
                      onChange={this.handleInputChange}
                      color="primary"
                      name="status"
                      id="status"
                      label='Estado de la credencial Activada/Desactivada'
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                {this.state.data.type !== 'SANDBOX' &&
                  <Box p={1}>
                    <Typography variant='overline'>Credencial por defecto</Typography>
                    <Tooltip title="Para uso de este portal y solo puede ser una">
                      <IconButton size="small" aria-label="delete">
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                    <Box>
                      <Switch checked={this.state.data.site_default}
                        onChange={this.handleInputChange}
                        color="primary"
                        name="site_default"
                        id="site_default"
                        label='Credential por defecto'
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Box>
                  </Box>
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <Typography variant='overline' color="textSecondary">Token</Typography>
                  <Typography variant="body1">{this.state.data.token}</Typography>
                </Box>
              </Grid>
            </Grid>

            <Box p={1}>
              <Button onClick={this.handleSubmit} className='btn btn-submit' variant="outlined" color="primary" startIcon={<SaveIcon />}>Guardar Cambios</Button>
              <Button onClick={this.handleCancel} style={{ marginLeft: "8px" }}>Cancelar</Button>
            </Box>

          </form>


        </Box>

      </ListItem>
    );
  }
}

export default Credential;
