import React from "react";
import Layout from 'layout/index.js'
import NumberFormat from "react-number-format";
import { Link } from 'react-router-dom';
import {
  Box, Paper, Button, Typography, TextField, Alert,
  TableSortLabel, TablePagination, TableContainer,
  Table, TableRow, TableHead, TableBody, TableCell, MenuItem
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { authenticationService } from 'auth/authenticationService'
import { withRouter } from 'react-router-dom';

import {PhoneNumber} from 'adds/PhoneNumber.js'

function encodeQueryData(data) {
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
}


const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      isNumericString
    />
  );
});


const headCells = [
  { id: "created_at", numeric: false, disablePadding: false, label: "Fecha creación", sorting: true},
  { id: "verify_transaction_uuid", numeric: false,disablePadding: false,label: "verify_transaction_uuid", sorting: true},
  { id: "phone", numeric: false,disablePadding: false,label: "Teléfono", sorting: true},
  { id: "status", numeric: false, disablePadding: false, label: "Estado", sorting: true},
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          !headCell.sorting ? <TableCell key={headCell.id}>{headCell.label}</TableCell> : (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className='hideSort'>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

class VerifyService extends React.Component {
  // This syntax ensures `this` is bound within handleClick.
  // Warning: this is *experimental* syntax.

  constructor(props) {
    super(props);

    // console.log(props);
    this.state = {
      data:{
        verify_service_uuid:null,
        title:'',
        body:'',
        expiration_time:''
      },
      html:{
        form: false,
        view: true,
      },
      filters: {
      },
      pagination:{
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by:'created_at',
        order:'desc',
      },
      transactions:[],
      error: {
        title: false,
        body: false,
        expiration_time: false,
      },
      feedback: {
        title: '',
        body:'',
        expiration_time: '',
      },
      elastic_numbers:[]


    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleLoadTransactions = this.handleLoadTransactions.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditForm = this.handleEditForm.bind(this);
    this.handleCancelEdit = this.handleCancelEdit.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.getNumbers = this.getNumbers.bind(this);
  }


  handleChangePage(event, page){
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
          page: page,
      }}), ()=> this.handleLoad(this.state.data.verify_service_uuid));

  }
  handleChangeRowsPerPage(event){
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
          rows_per_page: event.target.value,
      }}), ()=> this.handleLoad(this.state.data.verify_service_uuid));
  }

  handleSort(event, property){
    const isAsc = this.state.sorting.order_by === property && this.state.sorting.order === "asc";
    this.setState(prevState => ({
      sorting: {
          ...prevState.sorting,
          order: isAsc ? "desc" : "asc",
          order_by: property,
    }}),()=>this.handleLoad(this.state.data.verify_service_uuid)
    );

  }

  handleLoad(verify_service_uuid){
    console.log('CC')
    // console.log(verify_service_uuid)
    if(verify_service_uuid==='new'){
      this.setState({
        html:{
          form: true,
          view: false,
          }
        }
      );
    }
    else{
      authenticationService.fetchApi({
        url:'/verify/service/'+verify_service_uuid,
        method: 'GET',
        api:true
      })
      .then(result=>{
        this.setState({data:result},()=>{
          
        });
        this.handleLoadTransactions(verify_service_uuid);

        this.setState({
          html:{
            form: false,
            view: true,
            }
          }
        );

      })
      .catch(err=>{});
    }

  }
  handleLoadTransactions(verify_service_uuid){
    // console.log('this')
    let params = {
        page: this.state.pagination.page+1,
        order_by: this.state.sorting.order_by,
        order: this.state.sorting.order,
        rows_per_page: this.state.pagination.rows_per_page,
        verify_service_uuid: verify_service_uuid,
    };

    // console.log('this_0')
    var query = encodeQueryData(params)

    // console.log('this_1')
    authenticationService.fetchApi({
      url:'/verify/transaction/?'+query,
      method: 'GET',
      api:true
    })
    .then(result=>{
      this.setState(prevState => ({
        pagination: {
            ...prevState.pagination,
            rows_num: result.rows_num
        },
        transactions: result.data
      }));
    })
    .catch(err=>{});

  }

  componentDidMount(){
    console.log('AA')
    this.getNumbers().then(()=>{
      this.handleLoad(this.props.match.params.verify_service_uuid);
    })
    
  }

  handleInputChange(event) {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value } = event.target;
    currentState[name] = value;

    // console.log(value);

    this.setState({ data: currentState });
  }


  handleCancelEdit(event) {
    if(this.state.data.verify_service_uuid===null){
      this.props.history.push('/verify/services/');
    }

    event.preventDefault();
    this.setState({
      html:{
        form: false,
        view: true,
        }
      },
      () => this.handleLoad(this.state.data.verify_service_uuid)
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    let ok = true;

    if (!this.state.data.title || this.state.data.title === '') {
      ok = false;
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          title: true
        },
        feedback: {
          ...prevState.feedback,
          title: 'Debes ingresar un título'
        }
      }));
    }
    else {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          title: false
        },
        feedback: {
          ...prevState.feedback,
          title: ''
        }
      }));
    }

    if (!this.state.data.body || this.state.data.body === '') {
      ok = false;
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          body: true
        },
        feedback: {
          ...prevState.feedback,
          body: 'Debes ingresar cuerpo del mensaje'
        }
      }));
    }
    else {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          body: false
        },
        feedback: {
          ...prevState.feedback,
          body: ''
        }
      }));
    }

    if (!this.state.data.expiration_time || this.state.data.expiration_time === ''
        || this.state.data.expiration_time > 600 || this.state.data.expiration_time < 30) {
      ok = false;
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          expiration_time: true
        },
        feedback: {
          ...prevState.feedback,
          expiration_time: 'Debes ingresar un tiempo de expiración entre 30 y 600 segundos'
        }
      }));
    }
    else {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          expiration_time: false
        },
        feedback: {
          ...prevState.feedback,
          expiration_time: ''
        }
      }));
    }

    if (!ok) {
      return false;
    }


    authenticationService.fetchApi({
        url: '/verify/service/',
        method: 'POST',
        body: JSON.stringify(this.state.data),
        api:true,
    })
    .then((result)=>{

      this.props.history.push('/verify/services/'+result['verify_service_uuid']);
      this.setState({
        html:{
          form: false,
          view: true,
        }
      });
      this.handleLoad(result.verify_service_uuid);


    })
    .catch(err=>{});

  }

  handleEditForm(event,data=null){
    this.setState({
      html:{
        form: true,
        view: false,
      }
    });
    event.preventDefault();

  }
  getNumbers() {
    return new Promise ( (resolve, reject) => authenticationService.fetchApi({
      url: '/number/',
      method: 'GET',
      api: true,
    })
    .then((result) => {
      this.setState({ elastic_numbers: result.data },()=>{
        console.log("BB2")
        resolve(true);
      });
    }).catch(err => {
      reject(false);
    })
    )
  }


  render() {
    return (
      <Layout title='Verify - Servicios'>
        <Box p={1} m={1}>
          <Button startIcon={<ArrowBackIcon/>} component={Link} to='/verify/services/' color='primary'> Volver a la lista de servicios</Button>
        </Box>
        <Paper>
          <Box p={1} m={1}>

            {this.state.html.form &&
            <form>

              <Typography variant='h6'>
                Editar Servicio
              </Typography>
              <Box p={1}>
                <TextField  name='title'
                            id='title'
                            label='Nombre del servicio'
                            error={this.state.error.title}
                            helperText={this.state.feedback.title}
                            fullWidth={true}
                            value={this.state.data.title}
                            inputProps={{ maxLength: 300 }}
                            onChange={this.handleInputChange} />
              </Box>
              <Box p={1}>
                <TextField  name='body'
                            id='body'
                            label='Contenido del mensaje de verify'
                            error={this.state.error.body}
                            helperText={this.state.feedback.body}
                            fullWidth={true}
                            value={this.state.data.body}
                            inputProps={{ maxLength: 155 }}
                            onChange={this.handleInputChange} />
              </Box>
              { this.state.elastic_numbers.length > 0 && 
                this.state.elastic_numbers && 
                <Box p={1}>
                  <TextField
                    name="src_phone"
                    id="src_phone"
                    select
                    label="Número de salida"
                    fullWidth={true}
                    value={ this.state.data.src_phone&&this.state.data.src_phone!==''?
                            this.state.data.src_phone:
                            "none"}
                    onChange={this.handleInputChange}
                    error={this.state.error.src_phone}
                    helperText="Selecciona tu número de salida"
                  >
                    <MenuItem key="none" value="none">
                        Sin Número
                    </MenuItem>
                    {this.state.elastic_numbers.map((option) => (
                      <MenuItem key={option.number} value={option.number}>
                        <PhoneNumber value={option.number} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              }
              <Box p={1}>
                <TextField  name='expiration_time'
                            id='expiration_time'
                            label='Tiempo de expiración de las claves (en segundos, mínimo 30, máximo 600, defecto 50)'
                            error={this.state.error.expiration_time}
                            helperText={this.state.feedback.expiration_time}
                            fullWidth={true}
                            value={this.state.data.expiration_time}
                            onChange={this.handleInputChange}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                            />
              </Box>
              <Box p={1}>
                <Button onClick={this.handleSubmit} color='primary' variant='contained'>Guardar cambios</Button>
                <Button onClick={this.handleCancelEdit} color='primary'>Descartar cambios</Button>
              </Box>
            </form>
            }

            {this.state.html.view &&
            <Box>
              <Box p={1}>
                <Typography variant='overline'>verify_service_uuid</Typography>
                <Typography color="textSecondary" variant="body2">{this.state.data.verify_service_uuid}</Typography>
              </Box>
              <Box p={1}>
                <Typography variant='overline'>Nombre del servicio</Typography>
                <Typography color="textSecondary" variant='body2'>
                  {this.state.data.title}
                </Typography>
              </Box>
              <Box p={1}>
                <Typography variant='overline'>Contenido del mensaje</Typography>
                <Typography color="textSecondary" variant='body2'>
                  {this.state.data.body}
                </Typography>
              </Box>
              <Box p={1}>
                <Typography variant='overline'>Número de origen</Typography>
                <Typography color="textSecondary" variant='body2'>
                  {this.state.data.src_phone && this.state.data.src_phone!=='' ?<PhoneNumber value={this.state.data.src_phone}/>:"Sin número de origen"}
                </Typography>
              </Box>
              <Box p={1}>
                <Typography variant="overline">Tiempo de expiración de las claves (en segundos)</Typography>
                <Typography color="textSecondary" variant="body2">
                  {this.state.data.expiration_time}
                </Typography>
              </Box>
              <Box p={1}>
                <Button onClick={this.handleEditForm} color='primary'>Editar</Button>
              </Box>


              {this.state.transactions.length>0 &&
              <Box p={1}>
                <Typography variant="h5">
                  Transacciones
                </Typography>
                <TablePagination
                  rowsPerPageOptions={[5, 10]}
                  component="div"
                  labelRowsPerPage='Resultados por página'
                  count={this.state.pagination.rows_num}
                  rowsPerPage={this.state.pagination.rows_per_page}
                  page={this.state.pagination.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
                <TableContainer>
                  <Table size="small" >
                    <EnhancedTableHead
                      order={this.state.sorting.order}
                      orderBy={this.state.sorting.order_by}
                      onRequestSort={this.handleSort}
                    />
                    <TableBody>
                    {this.state.transactions.map((object) => {
                      return(
                        <TableRow key={object.verify_transaction_uuid}>
                          <TableCell>
                            {(new Date(object.created_at)).toLocaleString('es-CL')}
                          </TableCell>
                          <TableCell>
                            {object.verify_transaction_uuid}
                          </TableCell>
                          <TableCell>
                            {object.phone}
                          </TableCell>
                          <TableCell>
                            {object.status?'VALIDADO':'SIN VALIDAR'}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              }
              {this.state.transactions.length===0 &&
              <Alert severity='warning'>
                Aún no tienes transacciones en este servicio
              </Alert>
              }


            </Box>
            }

          </Box>
        </Paper>
      </Layout>
    );
  }
}


export default withRouter(VerifyService);
