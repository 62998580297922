import React from 'react';
import Layout from '../../layout/index.js';
import TopUpUser from './TopUpUser.js';

class TopUps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        tab_index: 0,
      },
    };

    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  componentDidMount() {

  }

  handleChangeTab(event, newValue) {
    event.preventDefault();

    this.setState((prevState) => ({
      html: {
        ...prevState.html,
        tab_index: newValue,
      },
    }));
  }

  render() {
    return (
      <Layout title='Historial de cargas de crédito'>
        <TopUpUser />
      </Layout>
    );
  }
}

export default TopUps;
