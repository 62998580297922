import React from "react";
import Logo from './logo_horizontal.png';
import './style.css'
import { withRouter} from 'react-router-dom'
import {
  CssBaseline, Paper, Box, Grid
} from '@mui/material';


class LoginLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    
    // if('title' in this.props){
    //   document.title = "Axonio - " + this.props.title
    // }
  }

  render() {
    return (
      <Box style={{
        heigth: "130vh",
        background: 'linear-gradient(to bottom, #0066FF, #0096D6)'
      }}>
        <CssBaseline />
        <Grid container
              spacing={0}
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: '100vh' }}
            >
          <Grid item xs={11} sm={5} lg={4} xl={2}>
            <Paper elevation={3}>
              <Box py={2} textAlign="center">
                <a href="https://www.axonio.dev">
                  <img src={Logo} alt='AxonIO' style={{m: 2, maxWidth: '90%'}}/>
                </a>
              </Box>
              <Box p={3}>
                {this.props.children}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withRouter(LoginLayout);
