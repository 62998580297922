import React from "react";

import { authenticationService } from 'auth/authenticationService'
import {
  Box, Button, TextField, Alert,
  TableSortLabel, TablePagination, TableContainer,
  Table, TableRow, TableHead, TableBody, TableCell,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';

const headCells = [
  { id: "name", numeric: false, disablePadding: false, label: "Nombre", sorting: true },
  { id: "actions", numeric: true, disablePadding: false, label: "Acciones", sorting: false }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          !headCell.sorting ?
            <TableCell key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}>
              {headCell.label}
            </TableCell>
            : (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className='hideSort'>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

class Tags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      objects: [],
      html: {
        tag_dialog: false,
      },
      data: {
        tag_uuid: '',
        name: '',
        type: 'CONTACT',
      },
      filters: {
      },
      pagination: {
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by: 'name',
        order: 'asc',
      }
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.openTagDialog = this.openTagDialog.bind(this);
    this.closeTagDialog = this.closeTagDialog.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTagSubmit = this.handleTagSubmit.bind(this);
  }

  handleChangePage(event, page) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
      }
    }), () => this.handleLoad());
  }

  handleChangeRowsPerPage(event) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        rows_per_page: event.target.value,
      }
    }), () => this.handleLoad());
  }

  handleSort(event, property) {
    const isAsc = this.state.sorting.order_by === property && this.state.sorting.order === "asc";
    this.setState(prevState => ({
      sorting: {
        ...prevState.sorting,
        order: isAsc ? "desc" : "asc",
        order_by: property,
      }
    }), () => this.handleLoad());
  }

  handleLoad(page = null) {
    var params = {
      page: this.state.pagination.page + 1,
      order_by: this.state.sorting.order_by,
      order: this.state.sorting.order,
      rows_per_page: this.state.pagination.rows_per_page
    };

    var query = encodeQueryData(params);

    authenticationService.fetchApi({
      url: '/tag/?' + query,
      method: 'GET',
      api: true
    })
      .then(result => {
        this.setState(prevState => ({
          html: {
            ...prevState.html,
            tag_dialog: false,
          },
          data: {
            tag_uuid: '',
            name: '',
            type: 'CONTACT'
          },
          pagination: {
            ...prevState.pagination,
            rows_num: result.rows_num
          },
          objects: result.data
        }));
      })
      .catch(err => {

      });
  }

  componentDidMount() {
    this.handleLoad();
  }

  openTagDialog(event, entity) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        tag_dialog: true,
        tag_dialog_entity: entity,
      },
      data: {
        tag_uuid: entity.tag_uuid || 'new',
        name: entity.name || '',
        type: entity.type || 'CONTACT',
      }
    }));
  }

  closeTagDialog(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        tag_dialog: false,
      },
      data: {
        tag_uuid: '',
        name: '',
        type: 'CONTACT'
      }
    }));
  }

  handleInputChange(event) {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value } = event.target;
    currentState[name] = value;

    this.setState({ data: currentState });
  }

  handleTagSubmit(event) {
    event.preventDefault();

    authenticationService.fetchApi({
      url: '/tag/',
      method: 'POST',
      body: JSON.stringify(this.state.data),
      api: true,
    })
      .then((result) => {
        this.handleLoad();
        this.props.parentReload();
      });
  }

  render() {
    return (
      <Box m={2}>
        <Box align="right">
          <Button onClick={(event) => this.openTagDialog(event, 'new')} startIcon={<AddCircleIcon />} color="primary" variant="contained">Crear Etiqueta</Button>
        </Box>
        {this.state.objects.length > 0 &&
          <Box my={2} pb={2}>
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component="div"
              labelRowsPerPage='Resultados por página'
              count={this.state.pagination.rows_num}
              rowsPerPage={this.state.pagination.rows_per_page}
              page={this.state.pagination.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  order={this.state.sorting.order}
                  orderBy={this.state.sorting.order_by}
                  onRequestSort={this.handleSort}
                />
                <TableBody>
                  {this.state.objects.map((object) => {
                    return (
                      <TableRow key={object.tag_uuid}>
                        <TableCell>
                          {object.name}
                        </TableCell>
                        <TableCell align="right">
                          <Button onClick={(event) => this.openTagDialog(event, object)} size="small" variant="outlined" color="primary" startIcon={<EditIcon />}>Editar</Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        }
        {this.state.objects.length === 0 &&
          <Box py={2}>
            <Alert severity='warning'>
              Aún no hay etiquetas creadas
            </Alert>
          </Box>
        }
        <Dialog open={this.state.html.tag_dialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}>
          <DialogTitle id="form-dialog-title">Detalles de la Etiqueta</DialogTitle>
          <DialogContent dividers={true}>
            {this.state.html.tag_dialog_entity !== '' &&
              <form noValidate autoComplete="off" onSubmit={this.handleTagSubmit}>
                <TextField name='name'
                  id='name'
                  label='Nombre de la etiqueta'
                  fullWidth={true}
                  value={this.state.data.name}
                  onChange={this.handleInputChange} />
              </form>
            }
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="outlined" onClick={this.handleTagSubmit}>
              Guardar
            </Button>
            <Button color="secondary" onClick={this.closeTagDialog}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default Tags;
