import React from 'react';
import ReactDOM from 'react-dom';


import App from './App';
import * as serviceWorker from './serviceWorker';

import TagManager from 'react-gtm-module'

if (document.location.hostname.indexOf('site.axonio.dev') !== -1) {
  const tagManagerArgs = {
    gtmId: 'GTM-P3BP82Q',
  }
  TagManager.initialize(tagManagerArgs)
}
// else{
//   ReactGA.initialize('UA-194489203-1') // Add your ID
// }

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
