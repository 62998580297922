import React from "react";

import { authenticationService } from 'auth/authenticationService'
import {
  Box, Grid,
  Card, CardContent, CardActions, Divider,
  Typography, FormControl, InputLabel,
  Select, MenuItem, TextField, Button, IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";


class Ivr extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        view: this.props.data.ivr_uuid !== '',
        form: this.props.data.ivr_uuid === '',
      },
      data: {
        ivr_uuid: this.props.data.ivr_uuid,
        name: this.props.data.name,
        elastic_number: this.props.data.elastic_number,
        menu_sound: this.props.data.menu_sound,
        invalid_sound: this.props.data.invalid_sound,
        choices: this.props.data.choices,
      },
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleEditForm = this.handleEditForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddChoice = this.handleAddChoice.bind(this);
    this.handleRemoveChoice = this.handleRemoveChoice.bind(this);
    this.handleInputChoiceChange = this.handleInputChoiceChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {

  }

  handleLoad() {

  }

  handleEditForm(event) {
    event.preventDefault();
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        form: true,
        view: false,
      }
    }));
  }

  handleInputChange(event) {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value, checked } = event.target;
    currentState[name] = event.target.type === 'checkbox' ? checked : value;

    this.setState({data: currentState});
  }

  handleAddChoice(event) {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        choices: [
          ...prevState.data.choices,
          {
            exten: this.state.data.choices.length + 1,
            type: 'group',
            destination: ''
          }
        ]
      }
    }));
  }

  handleRemoveChoice(event, index) {
    const { data } = { ...this.state };
    const choices = data.choices;
    choices.splice(index, 1);

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        choices: choices
      }
    }));
  }

  handleInputChoiceChange(event, index) {
    const { data } = { ...this.state };
    const choices = data.choices;
    const { name, value } = event.target;
    choices[index][name] = value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        choices: choices
      }
    }));
  }

  handleCancel(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        form: false,
        view: true,
      }
    }));
  }

  handleSubmit(event) {
    event.preventDefault();

    authenticationService.fetchApi({
      url: '/voice_config/ivr',
      method: 'POST',
      body: JSON.stringify(this.state.data),
    })
    .then(result => {
      this.props.parent.list();
    });
  }

  render() {
    return (
      <Card variant="outlined">
        <CardContent>
          <Box>
            {this.state.html.form &&
              <form noValidate autoComplete="off">
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Box p={1}>
                      <TextField name='name'
                        id='ivr_name'
                        label='Nombre'
                        fullWidth={true}
                        value={this.state.data.name}
                        onChange={this.handleInputChange}
                        required />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box p={1}>
                      <Grid container={true}>
                        <FormControl fullWidth={true}>
                          <InputLabel id="elastic_number_label">Número asociado</InputLabel>
                          <Select name="elastic_number"
                            labelId="elastic_number_label"
                            value={this.state.data.elastic_number}
                            onChange={this.handleInputChange}
                            required
                          >
                            <MenuItem key="" value=""><em>Ninguno</em></MenuItem>
                            {this.props.parent.state.numbers && this.props.parent.state.numbers.map(number => {
                              return <MenuItem key={number.number} value={number.number}>{number.number}</MenuItem>
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box p={1}>
                      <Grid container={true}>
                        <Grid item xs={8} sm={9}>
                          <FormControl fullWidth={true}>
                            <InputLabel id="menu_sound_label">Audio del menú de alternativas</InputLabel>
                            <Select name="menu_sound"
                              labelId="menu_sound_label"
                              value={this.state.data.menu_sound}
                              onChange={this.handleInputChange}
                              required
                            >
                              <MenuItem key="" value=""><em>Ninguno</em></MenuItem>
                              {this.props.parent.state.sounds && this.props.parent.state.sounds.map(sound => {
                                return <MenuItem key={sound.sound_uuid} value={sound.sound_uuid}>{sound.name}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={3}>
                          <IconButton>
                            <PlayArrowIcon />
                          </IconButton>
                          <IconButton onClick={(event) => this.props.parent.openSoundDialog(event, this, 'menu_sound')}>
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box p={1} mb={1}>
                      <Grid container={true}>
                        <Grid item xs={8} sm={9}>
                          <FormControl fullWidth={true}>
                            <InputLabel id="invalid_sound_label">Audio si la opción es inválida</InputLabel>
                            <Select name="invalid_sound"
                              labelId="invalid_sound_label"
                              value={this.state.data.invalid_sound}
                              onChange={this.handleInputChange}
                            >
                              <MenuItem key="" value=""><em>Ninguno</em></MenuItem>
                              {this.props.parent.state.sounds && this.props.parent.state.sounds.map(sound => {
                                return <MenuItem key={sound.sound_uuid} value={sound.sound_uuid}>{sound.name}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={3}>
                          <IconButton>
                            <PlayArrowIcon />
                          </IconButton>
                          <IconButton onClick={this.openSoundDialog}>
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Divider />
                <Box mt={1}>
                  <Typography variant="overline" color="textSecondary">Alternativas</Typography>
                </Box>
                {(this.state.data.choices && this.state.data.choices.length > 0) ?
                  this.state.data.choices.map((choice, index) => {
                    return (
                      <Grid container key={index}>
                        <Grid item xs={12}>
                          <Box p={1}>
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={3}>
                                <TextField id="ivr_choice_exten"
                                  name="exten"
                                  label="Dígito (1-9)"
                                  inputProps={{ maxLength: 1 }}
                                  value={choice.exten}
                                  onChange={(event) => this.handleInputChoiceChange(event, index)}
                                  fullWidth={true}
                                    />
                              </Grid>
                              <Grid item xs={6} sm={3}>
                                <FormControl fullWidth={true}>
                                  <InputLabel id="ivr_choice_type_label">Tipo de destino</InputLabel>
                                  <Select name="type"
                                    labelId="ivr_choice_type_label"
                                    value={choice.type}
                                    onChange={(event) => this.handleInputChoiceChange(event, index)}
                                  >
                                    <MenuItem key="group" value="group">Equipo</MenuItem>
                                    <MenuItem key="user" value="user">Agente</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} sm={3}>
                                <FormControl fullWidth={true}>
                                  <InputLabel id="ivr_choice_destination_label">Destino</InputLabel>
                                  <Select name="destination"
                                    labelId="ivr_choice_destination_label"
                                    value={choice.destination}
                                    onChange={(event) => this.handleInputChoiceChange(event, index)}
                                  >
                                    {this.props.parent.state.teams && this.props.parent.state.teams.map(agent => {
                                      return <MenuItem key={agent.person_uuid} value={agent.person_uuid}>{agent.name}</MenuItem>
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} sm={3}>
                                <IconButton onClick={(event) => this.handleRemoveChoice(event, index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })
                  : ''
                }
                <Box mt={1}>
                  <Button variant="text"
                    color="primary"
                    size="small"
                    onClick={this.handleAddChoice}
                    startIcon={<AddIcon />}>
                      Agregar alternativa
                  </Button>
                </Box>
                <Divider />
              </form>
            }
            {this.state.html.view &&
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Box p={1}>
                    <Typography color="textSecondary" variant="overline">
                      Nombre del IVR
                    </Typography>
                    <Typography variant="body1">
                      {this.state.data.name || '-'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box p={1}>
                    <Typography color="textSecondary" variant="overline">
                      Número asociado
                    </Typography>
                    <Typography variant="body1">
                      {this.state.data.elastic_number || '-'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            }
          </Box>
        </CardContent>
        <CardActions>
            {this.state.html.view &&
              <Box p={1}>
                <Button onClick={this.handleEditForm} variant="outlined" color='primary' startIcon={<EditIcon />}>Modificar</Button>
              </Box>
            }
            {this.state.html.form &&
              <Box p={1}>
                <Button onClick={this.handleSubmit} variant="outlined" color='primary' startIcon={<SaveIcon />}>Guardar Cambios</Button>
                <Button onClick={this.handleCancel}>Cancelar</Button>
              </Box>
            }
        </CardActions>
      </Card>
    );
  }
}

export default Ivr;
