import React from 'react';

import Api from './pages/api/api_v1';
import Dashboard from './pages/dashboard';
import TestErrors from './pages/dashboard/TestErrors.js';

import Login from './auth/Login';
import Signup from './auth/Signup';
import ValidateUser from './auth/ValidateUser';
import PasswordRecovery from './auth/PasswordRecovery';
import Security from 'auth/Security.js';

import PrivateRoute from './auth/PrivateRoute';

import { Switch, Route, Router } from 'react-router-dom';

import TestSMS from './pages/sms/TestSMS.js';
import Company from './pages/company';
import Credentials from './pages/company/Credentials';
import ElasticNumbers from './pages/company/ElasticNumbers.js';

import TopUps from './pages/topup/index.js';
import TopUp from './pages/topup/TopUp.js';
import Invoice from './pages/topup/Invoice.js';
import WebpayReturn from './pages/topup/WebpayReturn.js';
import WebpayFinal from './pages/topup/WebpayFinal.js';

import IncomingSms from './pages/sms/IncomingSms.js';
import OutgoingSms from './pages/sms/OutgoingSms.js';
import Campaigns from './pages/campaign/Campaigns.js';
import Campaign from './pages/campaign/Campaign.js';
import SmsConfig from './pages/sms/SmsConfig.js';
import MessageRoutes from './pages/sms/MessageRoutes.js';

import Calls from './pages/voip/Calls.js';
import VoiceConfig from './pages/voip/VoiceConfig.js';

import CompaniesAdmin from './pages/administration/index.js';
import CompanyAdmin from './pages/administration/CompanyAdmin.js';
import TopUpsAdmin from './pages/administration/TopUpsAdmin.js';
import Messages from './pages/administration/Messages.js';

import VerifyServices from './pages/verify/VerifyServices.js';
import VerifyService from './pages/verify/VerifyService.js';

import ContactsList from './pages/contact/index.js';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import ErrorHandler from './layout/ErrorHandler.js';


// //////////////////////////////////////////////////////////////
// ANALYTICS
// import createHistory from 'history/createBrowserHistory'
import { createBrowserHistory } from 'history';
// import ReactGA from 'react-ga'

// //////////////////////////////////////////////////////////////
// DATETIME
import moment from 'moment';
import 'moment/locale/es';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
moment.locale('es'); // it is required to select default locale manually
// /////////////////////////////////////////////////////////////

// //////////////////////////////////////////////////////////////
// ANALYTICS
const history = createBrowserHistory()
// history.listen(location => {
//   if (document.location.hostname.indexOf('site.axonrt.io') !== -1) {
//     ReactGA.set({ page: location.pathname })
//     ReactGA.pageview(location.pathname)
//   }
// })
// //////////////////////////////////////////////////////////////

const theme = createTheme({
  palette: {
    primary: {
      main: '#0066FF',
    },
    secondary: {
      main: '#565656',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
  overrides: {
    MuiPaper: {
      styleOverrides: {
        root: {

        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#565656',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 500,
          color: '#FFFFFF',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        active: {
          color: '#FFFFFF',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
    };
  }

  componentDidMount() {
    // if (document.location.hostname.indexOf('site.axonio.dev') !== -1) {
    //   ReactGA.pageview(window.location.pathname)
    // }
  }

  render() {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <ErrorHandler>
              <Switch>
                {
                  // API Documentation route
                }
                <Route path='/api_v1'>
                  <Api />
                </Route>
                {
                  // LOGIN'S routes
                }
                <Route path='/login'>
                  <Login />
                </Route>
                <Route path='/signup'>
                  <Signup />
                </Route>
                <Route path='/validate_user/:token'>
                  <ValidateUser />
                </Route>
                <Route exact path='/password_recovery/'>
                  <PasswordRecovery />
                </Route>
                <Route path='/password_recovery/:token'>
                  <PasswordRecovery />
                </Route>


                <PrivateRoute path='/security/'>
                  <Security />
                </PrivateRoute>
                {
                  // COMPANY'S routes
                }
                <PrivateRoute exact path='/company/'>
                  <Company />
                </PrivateRoute>
                <PrivateRoute exact path='/company/credentials'>
                  <Credentials />
                </PrivateRoute>
                <PrivateRoute exact path='/company/elastic_numbers'>
                  <ElasticNumbers />
                </PrivateRoute>
                <PrivateRoute exact path='/company/:query_params'>
                  <Company />
                </PrivateRoute>
                <PrivateRoute exact path='/invoice/:top_up_uuid'>
                  <Invoice />
                </PrivateRoute>
                <PrivateRoute exact path='/topups'>
                  <TopUps />
                </PrivateRoute>
                <PrivateRoute exact path='/topup'>
                  <TopUp />
                </PrivateRoute>
                <PrivateRoute path='/topup/:url_params'>
                  <TopUp />
                </PrivateRoute>
                <PrivateRoute exact path='/webpay_return/:token'>
                  <WebpayReturn />
                </PrivateRoute>
                <PrivateRoute exact path='/webpay_final/:token'>
                  <WebpayFinal />
                </PrivateRoute>
                <PrivateRoute exact path='/sms/test'>
                  <TestSMS />
                </PrivateRoute>
                <PrivateRoute exact path='/sms/incoming'>
                  <IncomingSms />
                </PrivateRoute>
                <PrivateRoute exact path='/sms/incoming/:company_uuid'>
                  <IncomingSms />
                </PrivateRoute>
                <PrivateRoute exact path='/sms/outgoing'>
                  <OutgoingSms />
                </PrivateRoute>
                <PrivateRoute exact path='/sms/routes'>
                  <MessageRoutes />
                </PrivateRoute>
                <PrivateRoute exact path='/sms/outgoing/:query_params'>
                  <OutgoingSms />
                </PrivateRoute>
                <PrivateRoute exact path='/sms/campaigns'>
                  <Campaigns />
                </PrivateRoute>
                <PrivateRoute exact path='/sms/campaigns/:campaign_uuid'>
                  <Campaign />
                </PrivateRoute>
                <PrivateRoute exact path='/verify/services'>
                  <VerifyServices />
                </PrivateRoute>
                <PrivateRoute exact path='/verify/services/:verify_service_uuid'>
                  <VerifyService />
                </PrivateRoute>
                <PrivateRoute exact path='/sms/config'>
                  <SmsConfig />
                </PrivateRoute>
                <PrivateRoute exact path='/voip/calls'>
                  <Calls />
                </PrivateRoute>
                <PrivateRoute exact path='/voip/config'>
                  <VoiceConfig />
                </PrivateRoute>
                <PrivateRoute exact path='/contacts'>
                  <ContactsList />
                </PrivateRoute>
                {
                  // ADMIN routes
                }
                <PrivateRoute exact path='/admin/messages'>
                  <Messages />
                </PrivateRoute>
                <PrivateRoute exact path='/admin/companies'>
                  <CompaniesAdmin />
                </PrivateRoute>
                <PrivateRoute exact path='/admin/topups'>
                  <TopUpsAdmin />
                </PrivateRoute>
                <PrivateRoute path='/admin/companies/:company_uuid'>
                  <CompanyAdmin />
                </PrivateRoute>
                {
                  // DEFAULT routes
                }
                <PrivateRoute exact path='/test_errors'>
                  <TestErrors />
                </PrivateRoute>
                <PrivateRoute exact path='/dashboard'>
                  <Dashboard />
                </PrivateRoute>
                <PrivateRoute exact path='/'>
                  <Dashboard />
                </PrivateRoute>
              </Switch>
            </ErrorHandler>
          </Router>
        </ThemeProvider>
      </LocalizationProvider>
    );
  }
}

export default App;
