import React from 'react';
import Layout from '../../layout/index.js'
import { authenticationService } from 'auth/authenticationService'


import {
  Paper, Box,
  Typography, 
  Grid, 
  Accordion, AccordionDetails,
  AccordionSummary,  List, ListItem, ListItemText, Divider, Alert
} from '@mui/material';


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// const countries = [
//   { code: 'AR', label: 'Argentina', phone: '54' },
//   { code: 'BO', label: 'Bolivia', phone: '591' },
//   { code: 'BR', label: 'Brasil', phone: '55' },
//   { code: 'CL', label: 'Chile', phone: '56' },
//   { code: 'CO', label: 'Colombia', phone: '57' },
//   { code: 'CR', label: 'Costa Rica', phone: '506' },
//   { code: 'EC', label: 'Ecuador', phone: '593' },
//   { code: 'ES', label: 'España', phone: '34' },
//   { code: 'GT', label: 'Guatemala', phone: '502' },
//   { code: 'HN', label: 'Honduras', phone: '504' },
//   { code: 'MX', label: 'Mexico', phone: '52' },
//   { code: 'NI', label: 'Nicaragua', phone: '505' },
//   { code: 'PA', label: 'Panama', phone: '507' },
//   { code: 'PY', label: 'Paraguay', phone: '595' },
//   { code: 'PE', label: 'Peru', phone: '51' },
//   { code: 'SV', label: 'El Salvador', phone: '503' },
//   {
//     code: 'US',
//     label: 'United States',
//     phone: '1',
//     suggested: true,
//   },
//   { code: 'UY', label: 'Uruguay', phone: '598' },
//   { code: 'VE', label: 'Venezuela', phone: '58' },
// ];


// For query delay on autocomplete
// const wait_interval = 500 // in ms
// let timer_id


class MessageRoutes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countries_with_routes: [],
      html:{
        credential_error: false
      }
    };
    this.handleLoad = this.handleLoad.bind(this);
    this.loadRoutes = this.loadRoutes.bind(this);

  }

  loadRoutes() {
    authenticationService.fetchApi({
      url: '/sms/routes/',
      method: 'GET',
      api: true,
    })
      .then((result) => {
        this.setState({ countries_with_routes: result });
      });
  }

  handleLoad() {
    this.loadRoutes();
  }

  componentDidMount() {
    let get_credential = authenticationService.getValidCredential()
    console.log(get_credential)
    if(!get_credential.error){
      this.setState(prevState=>({
        html: { ...prevState.html,
          credential_error: false,
        },
      }),()=>{
        this.handleLoad();
      });
      
    }
    else{
      this.setState(prevState=>({
        html: { ...prevState.html,
          credential_error: true,
        },
      }));
    }
    
  }


  render() {
    return (
      <Layout title='Rutas y precios por País'>
        <Paper>          
          {this.state.html.credential_error &&
            <Alert severity='error'>Para desplegar esta vista debes seleccionar una credencial por defecto que esté activa y no-expirada, en la sección de Mi Empresa.</Alert>
          }
          {!this.state.html.credential_error &&
          <Grid container spacing={2} >
            <Grid item lg={12}>
              <Box p={2} m={2}>
                <Typography variant="h6">
                  Rutas
                </Typography>

                {this.state.countries_with_routes && this.state.countries_with_routes.length > 0 &&
                  <div>
                    {this.state.countries_with_routes.map((country_with_routes, index) => {
                      return (
                        <Box key={'route_'+index} pt={2}>
                          <Grid container spacing={2}>
                            <Grid item md={10} key={'countrycode_'+country_with_routes.code}>
                              {country_with_routes.routes_in_company.length > 0 &&
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography sx={{ width: '100%', flexShrink: 0 }}>{country_with_routes.flag_emoji} {country_with_routes.title} </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div>
                                      {country_with_routes.routes_in_company.length > 1 &&
                                        country_with_routes.routes_in_company.map((route, index2) => {

                                          return (
                                            <List component="nav"  key={'route_in_company_'+index2}>
                                              <Divider />
                                              <ListItem key={'net_'+route.network.title}>
                                                <ListItemText sx={{ width: '50%', flexShrink: 0 }} primary={route.network.title} secondary="Red de destino" />
                                                <ListItemText sx={{ width: '50%', flexShrink: 0 }} primary={"CLP " + route.price} secondary={route.discount_percentage + "% de descuento"} />
                                              </ListItem>
                                            </List>
                                          )
                                        })
                                      }
                                      {country_with_routes.routes_in_company.length === 1 &&
                                        <List component="nav" aria-label="mailbox folders">
                                          <ListItem key={country_with_routes.routes_in_company[0].network.title}>
                                            <ListItemText primary={country_with_routes.routes_in_company[0].network.title} secondary="País de destino" />
                                            <ListItemText primary={"CLP " + country_with_routes.routes_in_company[0].price} secondary={country_with_routes.routes_in_company[0].discount_percentage + "% de descuento"} />
                                          </ListItem>
                                        </List>
                                      }
                                    </div>

                                  </AccordionDetails>
                                </Accordion>
                              }
                              {country_with_routes.routes_in_company.length === 0 &&
                                <Accordion disabled>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>{country_with_routes.flag_emoji} {country_with_routes.title} </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>Aún no poses rutas a este país, por favor contáctese con nosotros.</Typography>
                                  </AccordionSummary>
                                </Accordion>



                              }


                            </Grid>
                          </Grid>
                        </Box>
                      )
                    })
                    }
                  </div>
                }
              </Box>
            </Grid>
          </Grid>
          }
        </Paper>
      </Layout>
    );
  }
}
export default MessageRoutes;


