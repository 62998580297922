import React from "react";
import NumberFormat from 'react-number-format';
import { authenticationService } from 'auth/authenticationService'
import {
  Button, Box,
  TableSortLabel, TablePagination, TableContainer,
  Table, TableRow, TableHead, TableBody, TableCell,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Tooltip
} from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import SearchIcon from '@mui/icons-material/Search';
import TextsmsIcon from '@mui/icons-material/Textsms';
import PaidIcon from '@mui/icons-material/Paid';
import Dialpad from '@mui/icons-material/Dialpad';

import MoneyTransactionDetail from "./MoneyTransactionDetail";

const headCells = [
  { id: "created_at", numeric: false, disablePadding: false, label: "Fecha", sorting: false },
  { id: "movement", numeric: false, disablePadding: false, label: "Movimientos netos", sorting: false },
  { id: "type", numeric: false, disablePadding: false, label: "Tipo", sorting: false },
  { id: "details", numeric: false, disablePadding: false, label: "Detalles", sorting: false }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          !headCell.sorting ? <TableCell key={headCell.id}>{headCell.label}</TableCell> : (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className='hideSort'>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

class MoneyTransaction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        form: 'display-none',
        view: 'display-none',
        btn_edit: 'btn btn-edit',
        btn_submit: 'display-none',
        dialog: false,
      },
      data: {
        money_transaction_uuid: '',
        name: '',
        description: '',
      },
      totals: {},
      filters: {
        transactions_day: null,
      },
      pagination: {
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by: 'created_at',
        order: 'desc',
      },
      objects: [],
      is_admin: false,

    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleDetails = this.handleDetails.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  handleLoad() {
    let groups = authenticationService.currentUser.groups

    this.setState({ is_admin: ['admin'].filter(x => groups.includes(x)).length > 0 })

    var params = {
      company_uuid: this.props.company.company_uuid,
      page: this.state.pagination.page + 1,
      order_by: this.state.sorting.order_by,
      order: this.state.sorting.order,
      rows_per_page: this.state.pagination.rows_per_page,
      grouped: true
    };

    var query = encodeQueryData(params);
    return (
      authenticationService.fetchApi({
        url: '/money_transaction/?' + query,
        method: 'GET',
      })
        .then(result => {
          this.setState(prevState => ({
            pagination: {
              ...prevState.pagination,
              rows_num: result.rows_num
            },
            totals: result.totals,
            objects: result.data,
          }));
        })
    );
  }

  handleChangePage(event, page) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
      }
    }), () => this.handleLoad());

  }

  handleChangeRowsPerPage(event) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        rows_per_page: event.target.value,
      }
    }), () => this.handleLoad());
  }

  handleSort(event, property) {
    const isAsc = this.state.sorting.order_by === property && this.state.sorting.order === "asc";
    this.setState(prevState => ({
      sorting: {
        ...prevState.sorting,
        order: isAsc ? "desc" : "asc",
        order_by: property,
      }
    }), () => this.handleLoad()
    );
  }

  componentDidMount() {
    this.handleLoad();
  }
  handleDetails(day,type) {
    this.setState(prevState => ({
      filters: {
        ...prevState.html,
        transactions_day: day,
        type: type,
      }
    }), () => {
      this.setState(prevState => ({
        html: {
          ...prevState.html,
          dialog: true
        }
      }));
    });

  }

  handleCloseDialog() {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        dialog: false
      }
    }));
  }

  render() {
    return (
      <Box>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          labelRowsPerPage='Resultados por página'
          count={this.state.pagination.rows_num}
          rowsPerPage={this.state.pagination.rows_per_page}
          page={this.state.pagination.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
        <TableContainer>
          <Table size="small" >
            <EnhancedTableHead
              order={this.state.sorting.order}
              orderBy={this.state.sorting.order_by}
              onRequestSort={this.handleSort}
            />
            <TableBody>
              {this.state.objects.length > 0 && this.state.objects.map((object) => {
                return (
                  <TableRow key={object.trans_day + '-' + object.trans_type}>
                    <TableCell>
                      {(new Date(object.trans_day)).toLocaleDateString('es-CL')}
                    </TableCell>
                    <TableCell>
                      <NumberFormat displayType={'text'}
                        value={object.debit__sum > 0 ? object.debit__sum : object.credit__sum}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={object.debit__sum ? '+ $' : '- $'} />
                    </TableCell>
                    <TableCell>
                      {object.trans_type === 'topup' &&
                        <Tooltip title="Carga de crédito">
                          <PaidIcon fontSize="small" />
                        </Tooltip>
                      }
                      {object.trans_type === 'sms' &&
                        <Tooltip title="SMS">
                          <TextsmsIcon fontSize="small" />
                        </Tooltip>
                      }
                      {object.trans_type === 'call' &&
                        <Tooltip title="Llamada">
                          <CallIcon fontSize="small" />
                        </Tooltip>
                      }
                      {object.trans_type === 'elastic_number' &&
                        <Tooltip title="Número de salida">
                          <Dialpad fontSize="small" />
                        </Tooltip>
                      }
                      {/* {object.trans_type ? (object.trans_type === 'Llamada' ? <CallIcon /> : (object.trans_type === 'SMS' ? <MailIcon /> : '')) : ''} */}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={event => this.handleDetails(object.trans_day, object.trans_type )}>
                        <SearchIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}

            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={this.state.html.dialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Detalles de transacciones</DialogTitle>
          <DialogContent>
            {this.state.html.dialog &&
              <MoneyTransactionDetail company={this.props.company} 
                                      day={this.state.filters.transactions_day} 
                                      type={this.state.filters.type}/>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

      </Box>
    );
  }
}

export default MoneyTransaction;
