import React from 'react';
import Layout from 'layout';

import CompanyDashboard from './CompanyDashboard.js';
import AdminDashboard from './AdminDashboard.js';
import AgentDashboard from './AgentDashboard.js';
import { withRouter } from 'react-router-dom';

import { authenticationService } from 'auth/authenticationService'


class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_admin: false,
      is_company: false,
      is_agent: false,
    };
  }

  componentDidMount() {
    let groups = authenticationService.currentUser.groups

    this.setState({
      is_admin: ['admin'].filter(x => groups.includes(x)).length > 0,
      is_company: ['company'].filter(x => groups.includes(x)).length > 0,
      is_agent: ['agent'].filter(x => groups.includes(x)).length > 0,
    })
  }

  render() {
    return (
      <Layout title="Dashboard">
        {this.state.is_company &&
          <CompanyDashboard />
        }
        {this.state.is_admin && 
          <AdminDashboard />
        }
        {this.state.is_agent &&
          <AgentDashboard />
        }
      </Layout>
    );
  }
}

export default withRouter(Dashboard);
