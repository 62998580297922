import React from "react";
import Layout from 'layout/index.js'
import { withRouter } from 'react-router-dom';
import { authenticationService } from 'auth/authenticationService'
import {
  Paper, Box, Grid,
  Typography,
  TableSortLabel, TablePagination, TableContainer, Table, TableRow, TableHead, TableBody, TableCell,
  TextField, IconButton, Alert, Autocomplete
} from '@mui/material';
import ClearIcon from "@mui/icons-material/Clear";
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const headCells = [
  { id: "start",numeric: false, disablePadding: false, label: "Fecha / Hora", sorting: true},
  { id: "src_phone", numeric: false, disablePadding: false, label: "Número de origen", sorting: true},
  { id: "direction",numeric: false, disablePadding: false, label: "", sorting: false},
  { id: "dst_phone", numeric: false, disablePadding: false, label: "Número de destino", sorting: true},
  { id: "duration", numeric: false, disablePadding: false, label: "Duración", sorting: true},
  { id: "price", numeric: false, disablePadding: false, label: "Costo", sorting: true},
  { id: "status", numeric: false, disablePadding: false, label: "Estado", sorting: true}
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          !headCell.sorting ? <TableCell key={headCell.id}>{headCell.label}</TableCell> : (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className='hideSort'>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

function encodeQueryData(data, prepend = null) {
  const ret = [];

  for (let d in data) {
    if (typeof(data[d]) === 'object') {
      ret.push(encodeQueryData(data[d], d));
    }
    else {
      ret.push((prepend ? encodeURIComponent(prepend) + '[' + encodeURIComponent(d) + ']' : encodeURIComponent(d)) + '=' + encodeURIComponent(data[d]));
    }
  }

  return ret.join('&');
}

class Calls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      objects: [],
      statistics: [],
      html: {
        page: 1
      },
      filters: {
        src_phone: '',
        dst_phone: '',
        start_date: null,
        end_date: null,
      },
      pagination: {
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by: 'created_at',
        order: 'desc',
      },
      company: null,
      companies: []
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleLoadCompanies = this.handleLoadCompanies.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleDateFilter = this.handleDateFilter.bind(this);
    this.resetDate = this.resetDate.bind(this);
  }

  handleChangePage(event, page) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
      }}), () => this.handleLoad());
  }

  handleChangeRowsPerPage(event) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        rows_per_page: event.target.value,
      }}), () => this.handleLoad());
  }

  handleSort(event, property) {
    const isAsc = this.state.sorting.order_by === property && this.state.sorting.order === "asc";
    this.setState(prevState => ({
      sorting: {
        ...prevState.sorting,
        order: isAsc ? "desc" : "asc",
        order_by: property,
    }}), () => this.handleLoad()
    );
  }

  handleLoad(page = null) {
    var params = {
      page: this.state.pagination.page + 1,
      order_by: this.state.sorting.order_by,
      order: this.state.sorting.order,
      rows_per_page: this.state.pagination.rows_per_page,
      statistics: true,
    };

    if (this.state.company) {
      params['company_uuid'] = this.state.company.company_uuid;
    }

    if (Object.keys(this.state.filters).length !== 0) {
      params['filters'] = {};

      for (var key in this.state.filters) {
        if (this.state.filters[key]) {
          params['filters'][key] = (key === 'start_date' || key === 'end_date') ? this.state.filters[key].toISOString() : this.state.filters[key];
        }
      }
    }

    var query = encodeQueryData(params)

    authenticationService.fetchApi({
      url: '/call/?' + query,
      method: 'GET'
    })
    .then(result => {
      this.setState(prevState => ({
        pagination: {
            ...prevState.pagination,
            rows_num: result.rows_num
        }
      }));

      this.setState(prevState => ({html: {...prevState.html, list: 'display-inline'}}));
      this.setState({objects: result.data});
      this.setState({statistics: result.statistics});
    });
  }

  /////////////////////
  // FILTERS

  handleChangeFilter(event) {
    const { filters } = { ...this.state };
    const currentState = filters;
    currentState[event.target.name] = event.target.value;
    this.setState({ filters: currentState }, ()=> this.handleLoad());
  }

  handleDateFilter(name, date = null) {
    const { filters } = { ...this.state };
    const currentState = filters;
    currentState[name] = date;
    this.setState({ filters: currentState }, ()=> this.handleLoad());
  }

  resetDate(name) {
    const { filters } = { ...this.state };
    const currentState = filters
    currentState[name] = null;
    this.setState({ filters: currentState }, ()=> this.handleLoad());
  }
  // END FILTERS
  /////////////////////////////////

  componentDidMount() {
    let groups = authenticationService.currentUser.groups;
    let is_company = ['company'].filter(x => groups.includes(x)).length >0;

    if (is_company) {
      this.setState({company: authenticationService.currentUser.person_object.company_object.company_uuid})
    }

    this.handleLoad();
  }

  handleLoadCompanies(event, value=null) {
    event.preventDefault();
    let params = {
      page: 1,
      rows_per_page: 10,
    }

    if (value) {
      params['name']=value
    }

    var query = encodeQueryData(params)

    authenticationService.fetchApi({
      url: '/company/?' + query,
      method: 'GET',
    })
    .then(result=>{
      this.setState({companies: result.data});
    });
  }

  handleCompanyChange(event, value, reason) {
    if (value) {
      this.setState({company: value}, () => {this.handleLoad()});
    }
    else {
      this.setState({company:null}, () => this.setState({filters: {}}));
    }
  }

  render() {
    return (
      <Layout title='API VOZ'>
        <Paper>
          <Box p={1} m={1}>
            <Box>
              <Typography variant="h5">
                Historial de llamadas
              </Typography>
            </Box>
            <Paper background='secondary'>
              <Box p={2} m={2}>
                { ['admin'].filter(x => authenticationService.currentUser.groups.includes(x)).length >0 &&
                <Box>
                    <Autocomplete id="company"
                                  value = {this.state.company?this.state.company:null}
                                  options={this.state.companies}
                                  label='Filtar por empresa'
                                  getOptionLabel={(option) => option.name}
                                  isOptionEqualToValue={(option, value) => option.name === value.name}
                                  onInputChange={this.handleLoadCompanies}
                                  onChange={(event, value, reason)=>this.handleCompanyChange(event, value, reason)}
                                  renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Filtar por empresa"
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <React.Fragment>

                                              {params.InputProps.endAdornment}
                                            </React.Fragment>
                                          ),
                                        }}
                                      />
                                  )}
                    />
                </Box>
                }
                <Box>
                  <Grid container spacing={1}>
                    <Grid item md={6} sm={12}>
                      <TextField name='src_phone'
                                id='src_number'
                                label='Teléfono de origen'
                                fullWidth={true}
                                value={this.state.filters.src_number}
                                onChange={this.handleChangeFilter}/>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <TextField name='dst_phone'
                                id='dst_number'
                                label='Teléfono de destino'
                                fullWidth={true}
                                value={this.state.filters.dst_number}
                                onChange={this.handleChangeFilter}/>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={1}>
                    <Grid item md={6} sm={12}>
                      <DateTimePicker name='start_date'
                                      id='start_date'
                                      label='Filtra por fecha desde'
                                      fullWidth
                                      ampm={false}
                                      onChange={(date) => this.handleDateFilter('start_date', date)}
                                      value={this.state.filters.start_date}
                                      variant='inline'
                                      format="DD-MM-YYYY, HH:mm:ss"
                                      renderInput={(props) => <TextField {...props} />}
                                      InputProps={{
                                        endAdornment: (
                                          <IconButton
                                            // style={{ padding: 0 }}
                                            edge="end"
                                            size="small"
                                            disabled={!this.state.filters.start_date}
                                            onClick={() => this.resetDate('start_date')}
                                          >
                                            <ClearIcon />
                                          </IconButton>
                                        ),
                                       }}
                                      />
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <DateTimePicker name='end_date'
                                      id='end_date'
                                      label='Filtra por fecha hasta'
                                      fullWidth
                                      ampm={false}
                                      onChange={(date) => this.handleDateFilter('end_date', date)}
                                      value={this.state.filters.end_date}
                                      variant='inline'
                                      format="DD-MM-YYYY, HH:mm:ss"
                                      renderInput={(props) => <TextField {...props} />}
                                      InputProps={{
                                        endAdornment: (
                                          <IconButton
                                            // style={{ padding: 0 }}
                                            edge="end"
                                            size="small"
                                            disabled={!this.state.filters.end_date}
                                            onClick={() => this.resetDate('end_date')}
                                          >
                                            <ClearIcon />
                                          </IconButton>
                                        ),
                                       }}
                                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
            <br/>
            {/* <Paper>
              <Box p={2} m={2}>
                <Typography variant="overline" component="h6">
                  Estadísticas
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Red
                      </TableCell>
                      <TableCell>
                        En cola
                      </TableCell>
                      <TableCell>
                        Envíados al proveedor
                      </TableCell>
                      <TableCell>
                        Envíados
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {Object.keys(this.state.statistics).map((keyName, i) => {
                    return(
                      <TableRow key={i}>
                        <TableCell>
                          {keyName!=='null'?keyName:'Sin red identificada aún'}
                        </TableCell>
                        <TableCell>
                          {'SCHEDULED' in this.state.statistics[keyName]?this.state.statistics[keyName].SCHEDULED:0}
                        </TableCell>
                        <TableCell>
                          {'ESME_ROK' in this.state.statistics[keyName]?this.state.statistics[keyName].ESME_ROK:0}
                        </TableCell>
                        <TableCell>
                          {'DELIVRD' in this.state.statistics[keyName]?this.state.statistics[keyName].DELIVRD:0}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  </TableBody>
                </Table>
              </Box>
            </Paper> */}

            {this.state.objects.length > 0 &&
            <Box>
              <Box p={2} align='right'>
                <Grid container>
                  <Grid item md={6} align='left'>

                  </Grid>
                  <Grid item md={6}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10]}
                      component="div"
                      labelRowsPerPage='Resultados por página'
                      count={this.state.pagination.rows_num}
                      rowsPerPage={this.state.pagination.rows_per_page}
                      page={this.state.pagination.page}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box p={1}>
                <TableContainer>
                  <Table size="small">
                    <EnhancedTableHead
                      order={this.state.sorting.order}
                      orderBy={this.state.sorting.order_by}
                      onRequestSort={this.handleSort}
                    />
                    <TableBody>
                    {this.state.objects.map((object) => {
                      return(
                        <TableRow key={object.call_uuid}>
                          <TableCell>
                            {(new Date(object.created_at)).toLocaleString('es-CL')}
                          </TableCell>
                          <TableCell>
                            {object.src_phone} {(object.person_fullname && object.direction === 'outbound') ? ' - ' + object.person_fullname : ''}
                          </TableCell>
                          <TableCell>
                            {(object.call_direction === 'inbound' && object.answered) &&
                              <CallReceivedIcon />
                            }
                            {(object.call_direction === 'outbound' && object.answered) &&
                              <CallMadeIcon />
                            }
                            {(!object.answer) &&
                              <CallMissedIcon />
                            }
                          </TableCell>
                          <TableCell>
                            {object.dst_phone} {(object.person && object.direction === 'inbound') ? ' - ' + object.person.first_name : ''}
                          </TableCell>
                          <TableCell>
                            {(object.duration && object.duration > 0) ? object.duration + 's' : '-'}
                          </TableCell>
                          <TableCell>
                            {(object.price && object.price !== '') ? object.price : '-'}
                          </TableCell>
                          <TableCell>
                            {(object.status && object.status !== '') ? object.status : '-'}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            }
            {this.state.objects.length === 0 &&
              <Alert severity='warning'>
                Aún no hay llamadas para esta organización
              </Alert>
            }
          </Box>
        </Paper>
      </Layout>
    );
  }
}

export default withRouter(Calls);
