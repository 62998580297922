// Auth main object
export const authenticationService = {
  isAuthenticated: false,
  login,
  logout,
  signup,
  refresh,
  validateUser,
  passwordRecovery,
  checkExistence,
  getCurrentUser,
  getBaseURL,
  currentUser: JSON.parse(localStorage.getItem('currentUser')),
  getSettings,
  setSettings,
  settings: {},
  getValidCredential,
  fetchApi,

};

function fetchApi(params) {
  let headers = {}

  if ('token' in params) {
    headers['Authorization'] = 'axoniotoken ' + params['token'];
    params.base_url = getBaseURL().api_url;
  }
  else if ('api' in params) {

    
    let get_credential = authenticationService.getValidCredential();
    
    if(get_credential['error']){
      if('no_error_popup' in params){
        // NO DIALOG FOR ERROR
      }
      else{
        localStorage.setItem('error',JSON.stringify(get_credential));
        window.dispatchEvent( new Event('storage') )
      }
      return Promise.reject(get_credential)
    }
    else{
      headers['Authorization'] = 'axoniotoken ' + get_credential.credential.token;
      params.base_url = getBaseURL().api_url;
    }
  }
  else {
    headers['Authorization'] = 'Token ' + authenticationService.currentUser.token;
    params.base_url = getBaseURL().site_url;
  }

  if ('ContentType' in params) {
    if (params['ContentType'] === 'multipart') {
      headers['Content-Type'] = 'multipart/form-data';
    }
    else if (params['ContentType'] === 'xlsx') {
      headers['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    // else if (params['ContentType'] === 'csv') {
    //   headers['Content-Type'] = 'text/csv';
    // }
    else {
      headers['Content-Type'] = 'application/json';
    }
  }
  else {
    headers['Content-Type'] = 'application/json';
  }

  const options = {
    method: params.method,
    headers: headers,
    ...params.body && { body: params.body },
  };

  return new Promise( (resolve, reject) =>
    fetch(params.base_url + params.url, options)
      .then(res => {
        var status = res.status;
        let statusText = res.statusText;
        // console.log(res);
        if([200,201,202,203,204,205,206,207,208,226].includes(status)){
          if ('ContentType' in params) {
            if (params['ContentType'] === 'csv') {
              // Tiene que pasarse el blob aqui porque es un stream, en la invocación es muy tarde.
              return resolve(res.blob());
            }
            else if (params['ContentType'] !== 'json') {
              return resolve(res);
            }
          }
          return resolve(res.json());
        }
        else if (res.status === 401) {
          window.location.replace("/login/");
        }

        else {
          // return resolve(res.json());
          let error = {
            error: true,
            status: status,
            statusText:  statusText,
            description: 'Something else'
          }

          // let temp = res.json

          if('no_error_popup' in params){
            // NO DIALOG FOR ERROR
          }
          else{
            localStorage.setItem('error',JSON.stringify(error));
            window.dispatchEvent( new Event('storage') )
          }

          // return reject(res.json());
          const contentType = res.headers.get("content-type");
          // console.log(contentType);
          if (contentType && contentType.indexOf("application/json") !== -1) {
            // console.log('Auth:Entra Json');
            res.json().then((json) => {
              error.data = json
              // console.log(json);
              return reject(error);
            })
          }
          else{
            // console.log('Auth: NO Entra Json');
            return reject(error);
          }
        }
      })

  );
}
function getValidCredential(){
  let return_object = {
    error: false,
    status: 200,
    statusText:  null,
    description: null,
    credential: null
  }
  if ('credential' in authenticationService.currentUser) {
    if (  authenticationService.currentUser.credential !== null && 
          typeof authenticationService.currentUser.credential === 'object'
        ) {
      if( 'status' in authenticationService.currentUser.credential &&
          'type' in authenticationService.currentUser.credential &&
          'expiration_date' in authenticationService.currentUser.credential
          ){
        if( authenticationService.currentUser.credential.status &&
            authenticationService.currentUser.credential.type === 'PRODUCTION' &&
            (new Date()).getTime() < Date.parse(authenticationService.currentUser.credential.expiration_date)
          ){
            return_object['error']=false;
            return_object["status"]=200;
            return_object["credential"]=authenticationService.currentUser.credential;
        }
        else{
          return_object['error']=true;
          return_object["status"]=403;
          return_object["description"]='No credential is either disabled or expired'
        }
      }
      else{
        return_object['error']=true;
        return_object["status"]=403;
        return_object["description"]='Credential has the wrong arguments'
      }
    }
    else {
      return_object['error']=true;
      return_object["status"]=403;
      return_object["description"]='No credential'
    }
  }
  else {
    return_object['error']=true;
    return_object["status"]=403;
    return_object["description"]='No credential'
  }

  return(return_object)
  
}

function getCurrentUser() {
  authenticationService.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  if (authenticationService.currentUser) { authenticationService.isAuthenticated = true; }
  return authenticationService.currentUser;
}

function getSettings() {
  authenticationService.settings = JSON.parse(localStorage.getItem('settings'));
  return authenticationService.settings;
}

function setSettings(key, value) {
  if (!authenticationService.settings) {
    authenticationService.settings = {};
  }

  authenticationService.settings[key] = value;

  localStorage.setItem('settings', JSON.stringify(authenticationService.settings));
}

function login(data) {
  return new Promise ( (resolve, reject) =>
    fetch(authenticationService.getBaseURL().site_url + '/auth/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if(response.status !== 200){
        return reject(null)
      }
      else{
        response.json().then(result =>{
          if(result.status === 'OK'){
            authenticationService.isAuthenticated = true;
            localStorage.setItem('currentUser', JSON.stringify(result.user));
            return resolve(result);
          }
          else{
            authenticationService.isAuthenticated = false;
            return resolve(result);
          }
        })
      }
    })
  );
}
function refresh(data) {
  let headers = {}
  let params = {}
  headers['Authorization'] = 'Token ' + authenticationService.currentUser.token;
  params.base_url = getBaseURL().site_url;
  params.url = '/auth/refresh/';
  const options = {
    method: params.method,
    headers: headers,
    ...params.body && { body: params.body },
  };

  return (
    fetch(params.base_url + params.url, options, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        if (res.status === 401) {
          window.location.replace("/login/");
        }
        else {
          return res.json();
        }

      })
      .then(json => {
        if (json) {
          authenticationService.isAuthenticated = true;
          localStorage.setItem('currentUser', JSON.stringify(
            json)
          );
          // console.log(authenticationService.getCurrentUser());
          return json;
        }
        else {
          authenticationService.isAuthenticated = false;
          window.location.replace("/login/");
          return false
        }
      })
  );
}
function signup(data) {
  return (
    fetch(authenticationService.getBaseURL().site_url + '/auth/signup/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        if (!("error" in json)) {
          return true;
        }
        else {
          return false;
        }
      })
  );
}

function validateUser(token) {
  return (
    fetch(authenticationService.getBaseURL().site_url + '/auth/validate_user/' + token, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(res => res.json())
      .then(json => {
        if (!("error" in json)) {
          return true;
        }
        else {
          return false;
        }
      })
  );
}

function checkExistence(data) {
  return (
    fetch(authenticationService.getBaseURL().site_url + '/auth/check_existence/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(json => {
        return json
      })
  );
}

function passwordRecovery(data) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (
    fetch(authenticationService.getBaseURL().site_url + '/auth/password_recovery/',
      options)
      .then(res => res.json())
      .then(json => {
        return json
      })
  );
}

function logout() {
  localStorage.removeItem('currentUser');
  localStorage.removeItem('wazo');
  authenticationService.isAuthenticated = false;
  authenticationService.currentUser = null;
  return true;
}

function getBaseURL() {
  const hostname = window.location.hostname;
  let site_url = null;
  let api_url = null;
  let client_url = null;

  if (['0.0.0.0', '127.0.0.1', '127.0.1.1', 'localhost', '127.0.0.0'].includes(hostname)) {
    site_url = "http://localhost:8000/site"
    api_url = "http://localhost:8000/v1"
    client_url = "http://localhost:3000"
  }
  else {
    site_url = "https://api.axonio.dev/site"
    api_url = "https://api.axonio.dev/v1"
    client_url = "https://site.axonio.dev"
  }
  const base_url = {
    site_url: site_url,
    api_url: api_url,
    client_url: client_url
  }
  return (base_url);
}
