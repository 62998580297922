import React from "react";

import { authenticationService } from 'auth/authenticationService'
// import NumberFormat from 'react-number-format';
import {
  Grid, Box, Typography, Alert,
  TextField, Button, Switch, FormControlLabel,
  Dialog, DialogContent, DialogActions,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import SandboxPhone from 'pages/sms/SandboxPhone';


class SmsAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: {
        form: false,
        view: true,
      },
      data:{
        company_uuid:'',
        push_status:false,
        push_url:'',
        forward_incoming_sms_status:false,
        forward_incoming_sms_url:'',
        sandbox_phone:'',
        sandbox_validated_phone:null,
        sandbox_balance:'',
      },
      dialog: {
        open: false,
        error_message: '',
        success_message: '',
        token_input: false,
      },
      sandbox_token: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditForm = this.handleEditForm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleOpenValidatePhone = this.handleOpenValidatePhone.bind(this);
    this.handleCloseValidatePhone = this.handleCloseValidatePhone.bind(this);
    this.handleValidatePhone = this.handleValidatePhone.bind(this);
  }


  handleLoad() {
    return (
      authenticationService.fetchApi({
        url: '/company/sms_admin/' + this.state.data.company_uuid,
        method: 'GET',
      })
      .then(result => {
        this.setState(prevState => ({
          data: result,
          html: {
            ...prevState.html,
            view: true,
            form: false,
          }
        }));
        return (true);
      })
    );
  }

  componentDidMount() {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        company_uuid: this.props.company_uuid
      }
    }), () => this.handleLoad());
  }

  handleInputChange(event) {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value, checked } = event.target;

    if (name==='push_status' || name==='forward_incoming_sms_status') {
      currentState[name] = checked;
    }
    else {
      currentState[name] = value;
    }

    this.setState({ data: currentState });
  }

  handleSubmit(event) {
    event.preventDefault();

    authenticationService.fetchApi({
      url: '/company/sms_admin/',
      method: 'POST',
      body: JSON.stringify(this.state.data),
    })
    .then(() => {
      authenticationService.refresh().then(
        this.handleLoad()
      )
    });
  }

  handleEditForm(event) {
    event.preventDefault();
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        form: true,
        view: false,
      }
    }));
  }

  handleCancel(event) {
    this.setState(prevState => ({
      html: {
        ...prevState.html,
        form: false,
        view: true,
      }
    }));
  }

  handleOpenValidatePhone() {
    authenticationService.fetchApi({
      url: '/company/validate_phone/',
      method: 'GET',
    })
    .then((result) => {
      if ('error' in result) {
        this.setState(prevState => ({
          dialog: {
            ...prevState.dialog,
            open: true,
            token_input: false,
            error_message: 'Debes tener antes un teléfono de pruebas guardado'
          }
        }));
      }
      else {
        this.setState(prevState => ({
          dialog: {
            ...prevState.dialog,
            open: true,
            error_message: '',
            token_input: true,
          }
        }));
      }
    });
  }

  handleCloseValidatePhone() {
    this.setState(prevState => ({
      dialog: {
        ...prevState.dialog,
        open: false,
        error_message: '',
        token_input: false,
      }
    }));
  }

  handleValidatePhone(event) {
    event.preventDefault();

    if (this.state.sandbox_token !== '') {
      event.preventDefault();
      authenticationService.fetchApi({
        url: '/company/validate_phone/',
        method: 'POST',
        body: JSON.stringify({ sandbox_token: this.state.sandbox_token }),
      })
      .then((result) => {
        if ('error' in result) {
          this.setState(prevState => ({
            dialog: {
              ...prevState.dialog,
              token_input: true,
              error_message: "Código inválido",
            }
          }));
        }
        else {
          this.setState(prevState => ({
            dialog: {
              ...prevState.dialog,
              open: false,
              token_input: false,
              error_message: "",
              success_message: "Número de teléfono validado"
            }
          })
            , () => {
              authenticationService.refresh().then(
                this.handleLoad()
              )
            }
          );
        }
      });
    }
  }

  render() {
    return (
      <Box p={1} m={1}>
        {this.state.data.sandbox_balance > 0 &&
        <Box>
          <Box p={1}>
            <Typography color='primary' variant='h6'>
              Configura tus SMS de prueba
            </Typography>
          </Box>

          <Box p={1}>
            <SandboxPhone />
          </Box>
        </Box>
        }
        {this.state.html.form &&
          <form noValidate autoComplete="off">
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Box p={1}>
                  <Typography color='primary' variant='h6'>
                    Configura tus notificaciones de mensajes entrantes o salientes
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <FormControlLabel
                    control={
                      <Switch checked={this.state.data.push_status}
                        onChange={this.handleInputChange}
                        color="primary"
                        name="push_status"
                        id="push_status"
                        label='Notificaciones de estado de los SMS'
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label="Notificaciones de estado de los SMS"
                  />
                </Box>
                <Typography color='textSecondary' variant='caption'>
                  De activarse, cada vez que un SMS sea enviado a un destinatinatario, el éxito o fracaso del envío será notificado a la URL indicada en el campo contiguo. 
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <TextField name='push_url'
                    id='push_url'
                    className='form-control'
                    fullWidth={true}
                    label='URL para envío de notificaciones de estado de los SMS'
                    value={this.state.data.push_url?this.state.data.push_url:''}
                    onChange={this.handleInputChange}
                    disabled={!this.state.data.push_status} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <FormControlLabel
                    control={
                      <Switch checked={this.state.data.forward_incoming_sms_status}
                        onChange={this.handleInputChange}
                        color="primary"
                        name="forward_incoming_sms_status"
                        id="forward_incoming_sms_status"
                        inputProps={{ 'aria-label': 'primary checkbox' }} />
                    }
                    label='Reenvío de SMS entrantes'
                  />
                </Box>
                <Typography color='textSecondary' variant='caption'>
                  De activarse, cada vez que un SMS sea recibido desde un destinatinatario, el SMS será redirigido a la URL indicada en el campo contiguo. 
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <TextField name='forward_incoming_sms_url'
                    id='forward_incoming_sms_url'
                    className='form-control'
                    fullWidth={true}
                    label='URL para reenvío de SMS entrantes'
                    value={this.state.data.forward_incoming_sms_url?this.state.data.forward_incoming_sms_url:''}
                    onChange={this.handleInputChange}
                    disabled={!this.state.data.forward_incoming_sms_status} />
                </Box>
              </Grid>
            </Grid>
            <Box p={1}>
              <Button onClick={this.handleSubmit} color="primary" variant="outlined" startIcon={<SaveIcon />}>Guardar Cambios</Button>
              <Button onClick={this.handleCancel}>Cancelar</Button>
            </Box>
          </form>
        }
        {this.state.html.view &&
          <Box>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Box p={1}>
                  <Typography color='primary' variant='h6'>
                    Configura tus notificaciones de mensajes entrantes o salientes
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <Typography color="textSecondary" variant="overline">
                    Notificaciones de estado de los SMS
                  </Typography>
                  <Typography variant="body1">
                    {this.state.data.push_status ? 'Activado' : 'Desactivado'}
                  </Typography>
                </Box>

                <Typography color='textSecondary' variant='caption'>
                  De activarse, cada vez que un SMS sea enviado a un destinatinatario, el éxito o fracaso del envío será notificado a la URL indicada en el campo contiguo. 
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <Typography color="textSecondary" variant="overline">
                    URL para envío de notificaciones de estado de los SMS
                  </Typography>
                  <Typography variant="body1">
                    {this.state.data.push_url || '-'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <Typography variant="overline" color="textSecondary">
                    Reenvío de SMS entrantes
                  </Typography>
                  <Typography variant="body1">
                    {this.state.data.forward_incoming_sms_status ? 'Activado' : 'Desactivado'}
                  </Typography>
                </Box>
                <Typography color='textSecondary' variant='caption'>
                  De activarse, cada vez que un SMS sea recibido desde un destinatinatario, el SMS será redirigido a la URL indicada en el campo contiguo. 
                </Typography>
              </Grid>
             
              <Grid item xs={12} sm={6}>
                <Box p={1}>
                  <Typography variant="overline" color="textSecondary">
                    URL para reenvío de SMS entrantes
                  </Typography>
                  <Typography variant="body1">
                    {this.state.data.forward_incoming_sms_url || '-'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box p={1}>
              <Button onClick={this.handleEditForm} color='primary' variant="outlined" startIcon={<EditIcon />}>Editar</Button>
            </Box>
          </Box>
        }
        <Dialog open={this.state.dialog.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            {this.state.dialog.token_input &&
              <Box>
                <Typography variant="h6">
                  Validación de teléfono
                </Typography>
                <Box p={1}>
                  <Typography variant="body2">
                    Ingresa el código de validación que envíamos a tu teléfono
                  </Typography>
                </Box>
                <Box p={1}>
                  <TextField name='sandbox_token'
                    id='sandbox_token'
                    className='form-control'
                    fullWidth={true}
                    label='Código de validación'
                    value={this.state.sandbox_token}
                    onChange={(event) => this.setState({ sandbox_token: event.target.value })} />
                </Box>
                <Box p={1}>
                  <Button onClick={(event) => this.handleValidatePhone(event)} color="primary" variant='contained'>
                    Continuar
                  </Button>
                </Box>
              </Box>
            }
            {this.state.dialog.error_message !== '' &&
              <Box>
                <Alert severity='error'>{this.state.dialog.error_message}</Alert>
              </Box>
            }
            {this.state.dialog.success_message !== '' &&
              <Box>
                <Alert severity='success'>{this.state.dialog.success_message}</Alert>

                <Button onClick={() => this.handleCloseValidatePhone()} color="secondary">
                  Salir
                </Button>
              </Box>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleCloseValidatePhone()} color="secondary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default SmsAdmin;
