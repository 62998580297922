import React from "react";
import { Link } from 'react-router-dom';
import Layout from 'layout/index.js';
import { authenticationService } from 'auth/authenticationService';
import {
  Paper, Box, Button, Alert, Chip,
  TableSortLabel, TablePagination, TableContainer,
  Table, TableRow, TableHead, TableBody, TableCell,IconButton, Typography,
  Grid
} from '@mui/material';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import HelpIcon from "@mui/icons-material/Help";
import AddIcon from '@mui/icons-material/Add';
import jsonData from './campaign_status.json';
const campaign_status = jsonData;

const headCells = [
  { id: "name", numeric: false, disablePadding: false, label: "Nombre", sorting: true },
  { id: "delivery_date", numeric: false, disablePadding: false, label: "Fecha de envío", sorting: true },
  { id: "contacts", numeric: false, disablePadding: false, label: "Contactos", sorting: true },
  { id: "state", numeric: false, disablePadding: false, label: "Estado", sorting: true },
  { id: "actions", numeric: true, disablePadding: false, label: "Acciones", sorting: false }
];


class Campaigns extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      objects: [],
      html: {
        list: true,
        show_view: false,
        show_credential_error: false,
      },
      filters: {

      },
      pagination: {
        page: 0,
        pages_num: 1,
        rows_num: -1,
        rows_per_page: 10,
      },
      sorting: {
        order_by: 'delivery_date',
        order: 'desc',
      },
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  handleChangePage(event, page) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page: page,
      }
    }), () => this.handleLoad());

  }

  handleChangeRowsPerPage(event) {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        rows_per_page: event.target.value,
      }
    }), () => this.handleLoad());
  }

  handleSort(event, property) {
    const isAsc = this.state.sorting.order_by === property && this.state.sorting.order === "asc";
    this.setState(prevState => ({
      sorting: {
        ...prevState.sorting,
        order: isAsc ? "desc" : "asc",
        order_by: property,
      }
    }), () => this.handleLoad());
  }

  handleLoad() {
    var params = {
      page: this.state.pagination.page + 1,
      order_by: this.state.sorting.order_by,
      order: this.state.sorting.order,
      rows_per_page: this.state.pagination.rows_per_page,
    };

    var query = encodeQueryData(params)

    authenticationService.fetchApi({
      url: '/campaign/?' + query,
      method: 'GET',
      api: true,
      no_error_popup: true,
    })
      .then(result => {
        let list = false;

        if (result.data.length > 0) {
          list = true;
        }

        this.setState(prevState => ({
          pagination: {
            ...prevState.pagination,
            rows_num: result.rows_num
          },
          html: {
            ...prevState.html,
            list: list,
            show_view: true,
            show_credential_error: false,
          },
          objects: result.data
        }));
      })
      .catch(error => {
        this.setState(prevState => ({
          html: {
            ...prevState.html,
            show_view: false,
            show_credential_error: true
          },
        }));
      });
  }

  componentDidMount() {
    this.handleLoad();
  }

  render() {
    return (
      <Layout title='Campañas'>
        {this.state.html.show_view &&
          <Paper>
            <Box p={2}>
              <Grid container>
                <Grid item xs={2} sm={1}>
                  <IconButton component={Link} 
                            aria-label="help" 
                            to={{ pathname: 'https://www.axonio.dev/administrar-campanas/'}}
                            target='_blank'>
                    <HelpIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={10} sm={11}>
                  <Typography variant="caption" sx={{color:'#AAAAAA'}}>
                    Administra campañas, las que te permiten programar envíos para una fecha y hora determinada, desde la plataforma y la API. 
                    Además, con las campañas es posible realizar envíos masivos a listas de contactos desde la plataforma.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
                
            
            <Box m={1} pl={1} align='right'>
              <Button component={Link} to="/sms/campaigns/new" color='primary' variant='contained' startIcon={<AddIcon />}>Crear nueva campaña</Button>
            </Box>
            <Box p={2}>
              {this.state.objects.length > 0 && this.state.html.list &&
                <Box pb={1}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    labelRowsPerPage='Resultados por página'
                    count={this.state.pagination.rows_num}
                    rowsPerPage={this.state.pagination.rows_per_page}
                    page={this.state.pagination.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                  <TableContainer>
                    <Table>
                      <EnhancedTableHead
                        order={this.state.sorting.order}
                        orderBy={this.state.sorting.order_by}
                        onRequestSort={this.handleSort}
                      />
                      <TableBody>
                        {this.state.objects.map((object) => {
                          return (
                            <TableRow key={object.campaign_uuid}>
                              <TableCell>
                                {object.name}
                              </TableCell>
                              <TableCell>
                                {object.delivery_date ? (new Date(object.delivery_date)).toLocaleString('es-CL') : '-'}
                              </TableCell>
                              <TableCell>
                                {object.contacts_count || 0}
                              </TableCell>
                              <TableCell>
                                {object.state ?
                                  <>
                                    {campaign_status[0].statuses[object.state].type === 0 &&
                                      <Chip label={(campaign_status[0].statuses[object.state].spanish)} size="small" style={{ backgroundColor: '#FFDDDD', color: '#884444' }} />
                                    }
                                    {campaign_status[0].statuses[object.state].type === 1 &&
                                      <Chip label={(campaign_status[0].statuses[object.state].spanish)} size="small" style={{ backgroundColor: '#FFFFDD', color: '#888844' }} />
                                    }
                                    {campaign_status[0].statuses[object.state].type === 2 &&
                                      <Chip label={(campaign_status[0].statuses[object.state].spanish)} size="small" style={{ backgroundColor: '#DDFFDD', color: '#448844' }} />
                                    }
                                  </>
                                  :
                                  <Chip label="Pendiente" size="small" />
                                }
                              </TableCell>
                              <TableCell align="right">
                                <Button component={Link} to={"/sms/campaigns/" + object.campaign_uuid} variant="outlined" color="primary" startIcon={<OpenInNewIcon />}>Ver</Button>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              }
              {this.state.objects.length === 0 && this.state.html.list &&
                <Alert severity='warning'>
                  Aún no tienes ninguna campaña creada
                </Alert>
              }
            </Box>
          </Paper>
        }
        {this.state.html.show_credential_error &&
          <Alert severity='error'>
            Para desplegar esta vista debes seleccionar una credencial por defecto que esté activa y no-expirada, en la sección de Mi Empresa.
          </Alert>
        }
      </Layout>
    );
  }
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          !headCell.sorting ?
            <TableCell key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}>
              {headCell.label}
            </TableCell>
            : (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className='hideSort'>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

function encodeQueryData(data) {
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
}

export default Campaigns;
