import React from 'react';
import Layout from '../../layout/index.js'
import { authenticationService } from 'auth/authenticationService'

import { PhoneNumberField, PhoneNumber, countriesService } from 'adds/PhoneNumber.js'
import {
  Paper, Box, Grid, Divider,
  Typography, TextField, Button,
  FormControlLabel, Switch,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import AddCardIcon from '@mui/icons-material/AddCard';


// import feedback from "zxcvbn/lib/feedback";


class Company extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subscriptions: [],
      html: {
        form: false,
        view: false,
      },
      data: {
        company_uuid: '',
        name: '',
        description: '',
        tax_id: '',
        address: '',
        business_type: '',
        invoice_contact_mail: '',
        invoice_contact_name: '',
        invoice_contact_phone: '',
        auto_block_blacklisted: true,

        notify_min_balance: false,
        auto_topup: false,
        auto_topup_amount: '',

        is_single_person: false,
        first_name: '',
        last_name: '',
      },
      status: {
        name: true,
        first_name: true,
        last_name: true,
        invoice_contact_phone: true,
      },
      feedback: {
        name: '',
        first_name: '',
        last_name: '',
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditForm = this.handleEditForm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.loadSubscriptions = this.loadSubscriptions.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  handleLoad() {
    return (
      authenticationService.fetchApi({
        url: '/company/' + authenticationService.currentUser.person_object.company_object.company_uuid,
        method: 'GET',
      })
        .then((result) => {
          this.setState((prevState) => ({
            data: result,
            html: {
              ...prevState.html,
              view: true,
              form: false,
            },
          }));
          return (true);
        }


        )
    );
  }

  loadSubscriptions() {
    authenticationService.fetchApi({
      url: '/subscription/',
      method: 'GET',
    })
      .then((result) => {
        this.setState({ subscriptions: result });
      });
  }


  handleValidation(field_name) {
    return new Promise((resolve) => {
      if (field_name === 'name') {
        if (this.state.data.name && this.state.data.name !== '') {
          authenticationService.checkExistence({
            company: this.state.data.name,
            company_uuid: this.state.data.company_uuid,
          }).then((response) => {
            if ('company' in response) {
              if (!('same_name' in response)) {
                this.setState((prevState) => ({
                  feedback: {
                    ...prevState.feedback,
                    name: 'Este nombre de empresa ya existe',
                  },
                  status: {
                    ...prevState.status,
                    name: false,
                  },
                }));
                return resolve(false);
              } else {
                this.setState((prevState) => ({
                  feedback: {
                    ...prevState.feedback,
                    name: 'Estás manteniendo el nombre de la empresa',
                  },
                  status: {
                    ...prevState.status,
                    name: true,
                  },
                }));
                return resolve(true);
              }
            } else {
              this.setState((prevState) => ({
                feedback: {
                  ...prevState.feedback,
                  name: '',
                },
                status: {
                  ...prevState.status,
                  name: true,
                },
              }));
              return resolve(true);
            }
          });
        } else {
          this.setState((prevState) => ({
            feedback: {
              ...prevState.feedback,
              name: 'Debes ingresar un nombre de empresa',
            },
            status: {
              ...prevState.status,
              name: false,
            },
          }));
          return resolve(false);
        }
      } else {
        if (this.state.data[field_name] && this.state.data[field_name] !== '') {
          this.setState((prevState) => ({
            feedback: {
              ...prevState.feedback,
              [field_name]: '',
            },
            status: {
              ...prevState.status,
              [field_name]: true,
            },
          }));
          return resolve(true)
        } else {
          this.setState((prevState) => ({
            feedback: {
              ...prevState.feedback,
              [field_name]: 'Este campo no puede quedar vacío',
            },
            status: {
              ...prevState.status,
              [field_name]: false,
            },
          }));
          return resolve(false)
        }
      }
    })
  }
  componentDidMount() {
    this.handleLoad();
    this.loadSubscriptions();
  }

  handleInputChange(event) {
    const { data } = { ...this.state };
    const currentState = data;
    const { name, value, checked } = event.target;

    if (['auto_block_blacklisted', 'is_single_person', 'notify_min_balance', 'auto_topup'].includes(name)) {
      currentState[name] = checked;
    } else {
      currentState[name] = value;
    }
    this.setState({ data: currentState }, () => {
      this.handleValidation(name)
    });
  }

  handlePhoneChange(event) {
    const { data } = { ...this.state };
    const currentState = data
    const { value } = event.target

    currentState['invoice_contact_phone'] = value;
    this.setState((prevState) => ({
      status: {
        ...prevState.status,
        invoice_contact_phone: countriesService.validatePhone(value),
      },
    }));

    this.setState({ data: currentState });

  }



  async handleSubmit(event) {
    event.preventDefault();

    const name_v = await this.handleValidation('name');
    const first_name_v = await this.handleValidation('first_name');
    const last_name_v = await this.handleValidation('last_name');

    if (!(
      (!this.state.data.is_single_person &&
        name_v) ||
      (this.state.data.is_single_person &&
        first_name_v &&
        last_name_v)
    )
    ) {
      return false
    }
 
    authenticationService.fetchApi({
      url: '/company/',
      method: 'POST',
      body: JSON.stringify(this.state.data),
    })
      .then(() => {
        this.handleLoad();
      });
  }

  handleEditForm(event) {
    event.preventDefault();
    this.setState((prevState) => ({
      html: {
        ...prevState.html,
        form: true,
        view: false,
      },
    }));
  }

  handleCancel(event) {
    this.setState((prevState) => ({
      html: {
        ...prevState.html,
        form: false,
        view: true,
      },
    }), () => this.handleLoad());
  }

  render() {
    return (
      <Layout title='Mi Empresa'>
        <Paper>
          <Box p={1} m={1}>
            {this.state.html.form &&
              <form noValidate autoComplete="off">

                <Box p={1}>
                  <Typography variant='h6'>Identificación de la cuenta</Typography>
                </Box>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Box pl={1}>
                      <FormControlLabel
                        control={
                          <Switch checked={this.state.data.is_single_person}
                            onChange={this.handleInputChange}
                            color="primary"
                            name="is_single_person"
                            id="is_single_person"
                            label='¿Es una persona natural?'
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        }
                        label="¿Es una persona natural?"
                      />
                    </Box>
                    {!this.state.data.is_single_person ?
                      <Box p={1}>
                        <TextField label='Nombre de la empresa'
                          type="text"
                          name="name"
                          error={!this.state.status.name}
                          value={this.state.data.name ? this.state.data.name : ''}
                          helperText={this.state.feedback.name}
                          fullWidth
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </Box> :
                      <Box>
                        <Box p={1}>
                          <TextField label='Nombres'
                            type="text"
                            name="first_name"
                            error={!this.state.status.first_name}
                            value={this.state.data.first_name ? this.state.data.first_name : ''}
                            helperText={this.state.feedback.first_name}
                            fullWidth
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </Box>

                        <Box p={1}>
                          <TextField label='Apellidos'
                            type="text"
                            name="last_name"
                            error={!this.state.status.last_name}
                            value={this.state.data.last_name ? this.state.data.last_name : ''}
                            helperText={this.state.feedback.last_name}
                            fullWidth
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </Box>
                      </Box>
                    }
                  </Grid>
                  <Grid item xs={12} sm={6} pl={1}>
                    <Box >
                      <TextField name='description'
                        id='description'
                        className='form-control'
                        fullWidth={true}
                        multiline={true}
                        rows={3}
                        label='Descripción'
                        value={this.state.data.description}
                        onChange={this.handleInputChange}
                        variant="standard"
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Divider />
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Box p={1}>
                      <Typography variant='h6'>Configuración de saldo</Typography>
                    </Box>
                    <Box px={1}>
                      <Box >
                        <FormControlLabel
                          control={
                            <Switch checked={this.state.data.notify_min_balance}
                              onChange={this.handleInputChange}
                              color="primary"
                              name="notify_min_balance"
                              id="notify_min_balance"
                              label='¿Desea ser notificado de bajo nivel de saldo?'
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          }
                          label="¿Deseas ser notificado de bajo nivel de saldo?"
                        />
                      </Box>
                      <Box py={1}>
                        <TextField name='min_balance'
                          id='min_balance'
                          className='form-control'
                          fullWidth={true}
                          maxLength='10'
                          label='Nivel mínimo de saldo (debajo del cual se le notificará)'
                          value={this.state.data.min_balance}
                          onChange={this.handleInputChange} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <Box px={1} pt={5}>
                      <Box >
                        {this.state.subscriptions && this.state.subscriptions.length > 0 &&
                          <FormControlLabel
                            control={
                              <Switch checked={this.state.data.auto_topup}
                                onChange={this.handleInputChange}
                                color="primary"
                                name="auto_topup"
                                id="auto_topup"
                                label='¿Deseas que tu saldo se recargue de forma automática (con suscripción webpay)?'
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            }
                            label="¿Deseas que tu saldo se recargue de forma automática cuando caiga debajo del nivel indicado (con suscripción webpay)?"
                          />
                        }
                      </Box>
                      <Box py={1}>
                        {this.state.subscriptions && this.state.subscriptions.length > 0 &&
                          <TextField name='auto_topup_amount'
                            id='auto_topup_amount'
                            className='form-control'
                            fullWidth={true}
                            maxLength='10'
                            label='Monto de la carga automática'
                            value={this.state.data.auto_topup_amount}
                            onChange={this.handleInputChange} />
                        }
                        {this.state.subscriptions && this.state.subscriptions.length === 0 &&
                          <Typography color="textSecondary" variant="caption">
                            Debes registrar un medio de pago para realizar recargas automáticas de saldo.<br></br>
                            Esto puedes hacerlo en nuestra sección de recargas:
                            <br></br>
                            <Button startIcon={<AddCardIcon />} component={Link} to='/topup' color='primary' variant='contained' >Agregar tarjeta</Button>
                          </Typography>
                        }
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {!this.state.data.is_single_person &&
                  <Box>
                    <Divider />
                    <Box p={1}>
                      <Typography variant='h6'>Datos de facturación</Typography>
                    </Box>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <Box p={1}>
                          <TextField name='tax_id'
                            id='tax_id'
                            label='RUT'
                            placeholder='RUT de la empresa'
                            fullWidth
                            maxLength='20'
                            value={this.state.data.tax_id}
                            onChange={this.handleInputChange} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box p={1}>
                          <TextField name='address'
                            id='address'
                            label='Dirección comercial'
                            placeholder='Dirección comercial'
                            fullWidth
                            maxLength='500'
                            value={this.state.data.address}
                            onChange={this.handleInputChange} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box p={1}>
                          <TextField name='business_type'
                            id='business_type'
                            label='Giro comercial'
                            placeholder='Giro comercial'
                            fullWidth
                            maxLength='500'
                            value={this.state.data.business_type}
                            onChange={this.handleInputChange} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box p={1}>
                          <TextField name='invoice_contact_name'
                            id='invoice_contact_name'
                            label='Nombre contacto facturación'
                            placeholder='Nombre contacto facturación'
                            fullWidth
                            maxLength='500'
                            value={this.state.data.invoice_contact_name}
                            onChange={this.handleInputChange} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box p={1}>

                          <PhoneNumberField name='dst_phone'
                              id='dst_phone'
                              fullWidth={true}
                              label='Teléfono contacto facturación'
                              placeholder='Teléfono contacto facturación'
                              value={this.state.data.invoice_contact_phone}
                              onChange={this.handlePhoneChange}
                              error={!this.state.status.invoice_contact_phone}
                            />
                          {/* <TextField name='invoice_contact_phone'
                            id='invoice_contact_phone'
                            label='Teléfono contacto facturación'
                            placeholder='Teléfono contacto facturación'
                            fullWidth
                            maxLength='30'
                            value={this.state.data.invoice_contact_phone}
                            onChange={this.handleInputChange} /> */}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box p={1}>
                          <TextField name='invoice_contact_mail'
                            id='invoice_contact_mail'
                            label='Email contacto facturación'
                            placeholder='Mail contacto facturación'
                            fullWidth
                            maxLength='100'
                            value={this.state.data.invoice_contact_mail}
                            onChange={this.handleInputChange} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                }
                <Box p={1}>
                  <Button onClick={this.handleSubmit} variant="outlined" color='primary' startIcon={<SaveIcon />}>Guardar Cambios</Button>
                  <Button onClick={this.handleCancel}>Cancelar</Button>
                </Box>
              </form>
            }
            {this.state.html.view &&
              <Box>
                <Box p={1}>
                  <Typography variant='h6'>Identificación de la cuenta</Typography>
                </Box>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    {!this.state.data.is_single_person ?
                      <Box p={1}>
                        <Typography color="textSecondary" variant="overline">
                          Nombre de la empresa
                        </Typography>
                        <Typography variant="body1">
                          {this.state.data.name || '-'}
                        </Typography>
                      </Box> :
                      <Box p={1}>
                        <Typography color="textSecondary" variant="overline">
                          Nombre del particular
                        </Typography>
                        <Typography variant="body1">
                          {this.state.data.first_name + ' ' + this.state.data.last_name || '-'}
                        </Typography>

                        <Typography variant="caption" color='textSecondary'>
                          Cuenta: {this.state.data.name}
                        </Typography>
                      </Box>
                    }
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box p={1}>
                      <Typography color="textSecondary" variant="overline">
                        Descripción
                      </Typography>
                      <Typography variant="body1">
                        {this.state.data.description || '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Divider />

                <Box p={1}>
                  <Typography variant='h6'>Configuración de saldo</Typography>
                </Box>
                <Box p={1}>
                  <Typography color="textSecondary" variant="overline">
                    Nivel mínimo de saldo
                  </Typography>
                  <Typography variant="body1">
                    <NumberFormat displayType={'text'}
                      value={this.state.data.min_balance || 0}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={'$'} />
                  </Typography>

                  <Typography color="textSecondary" variant="overline">
                    Monto de autorecarga de saldo
                  </Typography>
                  {this.state.subscriptions && this.state.subscriptions.length > 0 &&
                    <div>
                      <Typography variant="body1">
                        <NumberFormat displayType={'text'}
                          value={this.state.data.auto_topup_amount || 0}
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          prefix={'$'} />
                      </Typography>
                    </div>
                  }
                  {this.state.subscriptions && this.state.subscriptions.length === 0 &&
                    <div>
                      <Typography color="textSecondary" variant="caption">
                        Debes registrar un medio de pago para realizar recargas automáticas de saldo.<br></br>
                        Esto puedes hacerlo en nuestra sección de recargas:
                        <br></br>
                        <Button startIcon={<AddCardIcon />} component={Link} to='/topup' color='primary' variant='contained' >Agregar tarjeta</Button>
                      </Typography>
                    </div>
                  }
                </Box>


                <Divider />
                <br />
                {!this.state.data.is_single_person &&
                  <Box>
                    <Box p={1}>
                      <Typography variant='h6'>Datos de facturación</Typography>
                    </Box>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <Box p={1}>
                          <Typography variant='overline' color="textSecondary">RUT de la empresa</Typography>
                          <Typography variant="body1">
                            {this.state.data.tax_id || '-'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box p={1}>
                          <Typography variant='overline' color="textSecondary">Dirección comercial</Typography>
                          <Typography variant="body1">
                            {this.state.data.address || '-'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box p={1}>
                          <Typography variant='overline' color="textSecondary">Giro comercial</Typography>
                          <Typography variant="body1">
                            {this.state.data.business_type || '-'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box p={1}>
                          <Typography variant='overline' color="textSecondary">Nombre contacto facturación</Typography>
                          <Typography variant="body1">
                            {this.state.data.invoice_contact_name || '-'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box p={1}>
                          <Typography variant='overline' color="textSecondary">Teléfono contacto facturación</Typography>
                          <Typography variant="body1">
                            <PhoneNumber value={this.state.data.invoice_contact_phone}/>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box p={1}>
                          <Typography variant='overline' color="textSecondary">Email contacto facturación</Typography>
                          <Typography variant="body1">
                            {this.state.data.invoice_contact_mail || '-'}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                }
                <Box p={1}>
                  <Button onClick={this.handleEditForm} variant="outlined" color='primary' startIcon={<EditIcon />}>Modificar</Button>
                </Box>
              </Box>
            }
          </Box>
        </Paper>
      </Layout >
    );
  }
}


export default Company;
