import React from "react";
import Layout from '../../layout/index.js'


import { withRouter } from 'react-router-dom';

import { authenticationService } from 'auth/authenticationService'

class WebpayFinal extends React.Component {
  // This syntax ensures `this` is bound within handleClick.

  constructor(props) {
    super(props);

    this.state = {
      data: '',
      token: ''
    };

    this.handleLoad = this.handleLoad.bind(this);
  }

  handleLoad(){
    authenticationService.fetchApi({
        url: '/topup/webpay_payment/' ,
        method: 'POST',
        body:JSON.stringify({
          token:this.props.match.params.token,
          do: 'final'
        }),
    })
    .then(result=>{
      this.setState({data:result}, console.log(this.state));
    });

  }

  componentDidMount(){
    this.handleLoad();
  }
  render() {
    return (
      <Layout>
        <div className='container-head'>
          <h1>Subscripción a producto</h1>
        </div>
        <div className='container-body'>
          <div>
            <div className='content-box'>
              <div className='subcontent-box'>

                <h3>Éxito en la compra</h3>
                <p>
                  Contenido del mensaje con toda la data
                </p>
              </div>
            </div>

          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(WebpayFinal);
